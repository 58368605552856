// Interfaces
import { IStyles } from '../../../resource';

// Define styles

const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.gray.light,
    p: theme.spacing(37, 28, 32),
    borderRadius: theme.spacing(30, 0),
    width: '100%',
    maxWidth: 344,
  }),
  avatar: () => ({
    width: 228,
    height: 228,
  }),
};

// Export styles
export default styles;
