import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import Widget from '../components/Widget';
import apis from '../../../apis';

export const ManageWidgetDetailsPage = () => {
  const navigate = useNavigate();
  const { widgetId } = useParams<{ widgetId: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [widget, setWidget] = useState<any>(null);

  useEffect(() => {
    if (widgetId) {
      setLoading(true);
      apis.cdn.getCdnWidgetByWidgetId(widgetId)
        .then((res) => {
          if (res) {
            setLoading(false);
            setWidget(res);
          }
        })
        .catch((err) => {
          console.log('catch>>>', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={20}>
        <Typography variant="h5">Widget Details</Typography>
        <Button startIcon={<ArrowBack />} onClick={goBack}>Back</Button>
      </Box>

      {loading ? (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={56} />
        </Box>
      ) : (
        <Widget
          widget={widget}
          preview={true}
        />
      )}
    </Box>
  )
};
