// Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Styles
import styles from './styles';

// Interfaces
interface IEditableLabelProps {
  content?: string;
  onChange?: (value: string) => void;
}

// Export Editable Label
export const EditableLabel: FC<IEditableLabelProps> = ({ onChange }) => {
  // Return Editable Label
  return (
    <Typography
      variant="h6"
      sx={styles.label}
      contentEditable
      onInput={(e) => {
        onChange && onChange(e.currentTarget.innerText);
      }}
    >
      {'Label'}
    </Typography>
  );
};
