import { gql } from '@apollo/client';

export const GET_HISTORIES = gql`
  query getHistory($type: String!) {
    getHistory(type: $type) {
      histories
      count
    }
  }
`;

export const GET_QUERY = gql`
  query getQuery($param: queryFilterParam) {
    getQuery(param: $param) {
      histories
      count
    }
  }
`
