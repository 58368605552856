// Dependencies
import React, { FC, useEffect } from 'react';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Worker } from '@react-pdf-viewer/core';
import {ToastProvider} from './components/Toast/toastContext';

import AppProvider from './context';

const ReactDndProvider: any = DndProvider;

// Theme
import theme from './theme';

// Constants
import { API_SERVER, PageType } from './contants';
import AppRouter from './router';
import CustomizedSnackbars from './components/Toast';
import CustomizedAlerts from './components/Alert';
import {AlertProvider} from './components/Alert/alertContext';

// Prepare links
const errorLink = onError((errors: any) => {
  if (errors.graphqlErrors) {
    errors.graphqlErrors.map((data: any) => {
      alert(`Graphql error ${data.message}`);
    });
  }
});

const apiKey = localStorage.getItem('apiKey') || '';

// @ts-ignore
const link = from([errorLink, new HttpLink({ uri: `${API_SERVER}/graphql`, headers: { apiKey } })]);

// Create client
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

interface IAppProps {
  apiKey: string;
  page: PageType;
}

// Create app
const App: FC<IAppProps> = ({ apiKey }) => {
  // const Component: FC | undefined = MAIN_ROUTES.find((route) => route.page === page)?.component;

  useEffect(() => {
    localStorage.setItem('apiKey', apiKey);
  }, [apiKey]);

  return (
    <AppProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ReactDndProvider backend={HTML5Backend}>
            <AlertProvider>
              <ToastProvider>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                  {/*{*/}
                  {/*  Component && <Component />*/}
                  {/*}*/}
                  <CustomizedSnackbars/>
                  <CustomizedAlerts />
                  <AppRouter />
                </Worker>
              </ToastProvider>
            </AlertProvider>
          </ReactDndProvider>
        </ThemeProvider>
      </ApolloProvider>
    </AppProvider>
  );
};

// Export app
export default App;
