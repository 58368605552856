import http from './http';
import { ICustomerDocument, IFilter } from '../resource';

export const getDocuments = async (customerId: string | undefined, page?: number, limit?: number, showDeleted?: boolean) => {
  return await http.get(`/docs/${customerId}/documents`, { page, limit, archived: Number(showDeleted) });
};

export const getAllDocuments = async (id: string, page?: number, limit?: number, showDeleted?: boolean) => {
  return await http.get('/docs', { page, limit, archived: Number(showDeleted) });
};

export const getDocument = async (customerId: string | undefined, id: string) => {
  return await http.get(`/docs/${customerId}/documents/${id}`);
};

export const generateDocument = async (customerId: string | undefined, templateId?: string) => {
  return await http.post(`/docs/${customerId}/documents`, { templateId });
};

export const downloadPdf = async (url: string) => {
  return await http.publicGet(url, { responseType: 'blob' });
};

export const removeDocument = async (customerId: string | undefined, id: string) => {
  return await http.put(`/docs/${customerId}/documents/${id}/archive`);
};

export const removeForeverDocument = async (customerId: string | undefined, id: string) => {
  return await http.delete(`/docs/${customerId}/documents/${id}`);
};

export const restoreDocument = async (customerId: string | undefined, id: string) => {
  return await http.put(`/docs/${customerId}/documents/${id}/restore`);
};

export const duplicateDocument = async (customerId: string, id: string) => {
  return await http.post(`/docs/${customerId}/documents/${id}/duplicate`);
};

export const generateCustomDocument = async (layout: any) => {
  return await http.post(`/docs/customize`, layout);
};

export const getDocumentFields = async (id: string) => {
  return await http.get(`/docs/${id}/fields`);
};

export const customizeDocument = async (
  customerId: string | undefined,
  id: string,
  payload: { key: string; value: any }[]
) => {
  const contacts = payload.filter((item) => item.key === 'contact_fields');

  if (contacts.length) {
    for (const contact of contacts) {
      if (contact.value.image) {
        const result = await uploadContactImage(contact.value.image);
        payload.forEach((item) => {
          if (item.key === 'contact_fields' && item.value._id === contact.value._id) {
            item.value.image = result.data;
          }
        });
      }
    }
  }

  return await http.put(`/docs/${customerId}/documents/${id}`, payload);
};

export const previewDocument = async (
  customerId: string | undefined,
  id: string,
  payload: { key: string; value: any }[]
) => {
  const contacts = payload.filter((item) => item.key === 'contact_fields');

  if (contacts.length) {
    for (const contact of contacts) {
      if (contact.value.image) {
        const result = await uploadContactImage(contact.value.image);
        payload.forEach((item) => {
          if (item.key === 'contact_fields' && item.value._id === contact.value._id) {
            item.value.image = result.data;
          }
        });
      }
    }
  }

  return await http.put(`/docs/${customerId}/documents/${id}/preview`, payload);
};

export const customizeDocumentContacts = async (
  customerId: string | undefined,
  id: string,
  payload: any
) => {
  const form: any = new FormData();
  form.append('name', payload.name);
  form.append('image', payload.image);
  form.append('role', payload.role);
  form.append('second_role', payload.second_role);
  form.append('email', payload.email);
  form.append('tel', payload.tel);
  form.append('id', payload._id);
  form.append('link', payload.link);
  return await http.put(`/docs/${customerId}/documents/${id}/contact`, form);
};

export const createCustomerDocument = async (customerId: string, document: Partial<ICustomerDocument>) => {
  return await http.post(`/docs/${customerId}/customer-docs`, document);
};

export const getCustomerDocument = async (customerId: string, docId: string) => {
  return await http.get(`/docs/${customerId}/customer-docs/${docId}`);
};

export const getCustomerDocuments = async (customerId: string, filter?: IFilter) => {
  return await http.get(`/docs/${customerId}/customer-docs`, filter);
};

export const getAllCustomerDocuments = async (customerId: string, filter?: IFilter) => {
  return await http.get('/docs/customer-docs', filter);
};

export const publishCustomerDocuments = async (customerId: string, url: string, customerDocIds: string[]) => {
  return await http.post(`/docs/${customerId}/customer-docs/publish`, { url, customerDocIds });
};

export const updateCustomerDocument = async (
  customerId: string,
  docId: string,
  document: Partial<ICustomerDocument>
) => {
  return await http.put(`/docs/${customerId}/customer-docs/${docId}`, document);
};

export const removeCustomerDocuments = async (customerId: string, ids: string[]) => {
  return await http.delete(`/docs/${customerId}/customer-docs`, { ids });
};

export const uploadLogoImage = async (file) => {
  const form: any = new FormData();
  form.append('avatar', file);
  return await http.post('/docs/logo-upload', form);
};


export const uploadContactImage = async (image) => {
  const form: any = new FormData();
  form.append('image', image);
  return await http.post('/docs/contact-upload', form);
};
