// Dependencies
import { FC, ReactNode } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import Header from './Header';
import Sidebar from './Sidebar';

// Styles
import styles from './styles';

// Interfaces
interface IMainLayoutProps {
  children: ReactNode;
}

// Export main layout
export const MainLayout: FC<IMainLayoutProps> = ({ children }) => {
  // Theme
  const theme = useTheme();

  // Check window size
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  // Return main layout
  return (
    <Box sx={styles.layout}>
      {isTablet && <Sidebar />}
      <Box sx={styles.aside}>
        <Header />
        <Box sx={styles.content} component={PerfectScrollbar}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
