// Create styles
const styles = {
  image: {
    width: '100%',
    height: '100%',
  },
  input: {
    display: 'none',
  },
  uploadButton: () => ({
    width: '100%',
    height: '100%',
  }),
};

// Export styles
export default styles;
