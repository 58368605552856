// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: theme.spacing(60, 40, 20, 40),
    maxWidth: { xs: 560, md: 700 },
    width: 1,
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  configWrapper: (theme) => ({
    display: 'flex',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '32px',
    flexGrow: 1,
    padding: theme.spacing(8, 16),
    gap: theme.spacing(4),
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: 'calc(100% - 64px)',
      height: '0px',
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#e5e5e5',
      borderRadius: '6px',
    },

    '::-webkit-scrollbar-button': {
      display: 'none',
    },
  }),
  configField: (theme, { type, active }) => ({
    color: theme.palette.common.white,
    width: 'fit-content',
    backgroundColor: type === 'text' ? theme.palette.grey[active ? '700' : '200'] : theme.palette.success[active ? 'main' : 'light'],
    padding: theme.spacing(4, 8),
    borderRadius: theme.spacing(8),
    cursor: 'pointer',
    border: '1px solid transparent',
    '&:hover': {
      borderColor: type === 'text' ? theme.palette.grey['700'] : theme.palette.success.main,
    },
    h6: active && {
      color: 'white',
    }
  }),
  fieldOption: (theme) => ({
    mt: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8)
  }),
  fieldOptionForm: (theme) => ({
    ml: theme.spacing(16),
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '> *:first-child': {
      width: '100px'
    },
    '> *:last-child': {
      width: '300px'
    }
  }),
};

// Export styles
export default styles;
