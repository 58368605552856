// Dependencies
import { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Link,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Dialog } from '../../Dialog';

// Context
import { AppContext } from '../../../context';

// Icons
import { LinkIcon, PublishIcon } from '../../../assets/icons';

// Apis
import apis from '../../../apis';

// Styles
import styles from './styles';

// Interfaces
import { IDocument, ICustomerDocument, ROLE } from '../../../resource';

interface IPublishDialogProps {
  open: boolean;
  doc: IDocument;
  docId: string;
  onClose: () => void;
  onPublish: () => void;
}

// Export publish dialog
export const PublishDialog: FC<IPublishDialogProps> = ({ open, doc, onClose, onPublish }) => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerDocuments, setCustomerDocuments] = useState<ICustomerDocument[]>([]);
  const [selectedCustomerDocIds, setSelectedCustomerDocIds] = useState<string[]>([]);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const { account } = useContext(AppContext);

  // Publish handler
  const handlePublish = () => {
    setIsPublishing(true);
    if (account?.id) {
      apis.pdf
        .publishCustomerDocuments(account.id, doc.file_url, selectedCustomerDocIds)
        .then(() => {
          onPublish();
          onClose();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsPublishing(false);
        });
    }
  };

  // Discard
  const handleDiscard = () => {
    onClose();
  };

  // Handle selected customer documents
  const handleChangeSelectedDocs = (id) => {
    if (!selectedCustomerDocIds.includes(id)) {
      setSelectedCustomerDocIds([...selectedCustomerDocIds, id]);
    } else {
      setSelectedCustomerDocIds(selectedCustomerDocIds.filter((docId: string) => docId !== id));
    }
  };

  // Fetch document
  const fetchCustomerDocuments = (accountId: string) => {
    const fetch = account?.role === ROLE.ADMIN ? apis.pdf.getAllCustomerDocuments : apis.pdf.getCustomerDocuments;

    fetch(accountId)
      .then((res) => {
        setCustomerDocuments(res?.customerDocs);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // On mounted
  useEffect(() => {
    if (account?.id) {
      fetchCustomerDocuments(account.id);
    }
  }, [account]);

  // Return publish dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography mb={36} variant="headline">
            Publish
          </Typography>
          <Box sx={styles.preview}>
            <Box sx={styles.thumbnail}>
              <img src={doc?.thumbnail_url} alt={doc?.thumbnail_url} />
            </Box>
            <Divider orientation="vertical" sx={styles.divider} />
            <Stack sx={styles.description}>
              <Typography mb={13} variant="title">
                {doc?.name}
              </Typography>
              <Typography variant="body2" color="gray.dark">
                {moment(doc?.updatedAt).format('DD MMMM YYYY / h:mm:ss')}
              </Typography>
              <Box px={12} my={12} mb={16} component={PerfectScrollbar}>
                {customerDocuments
                  .filter((doc) => doc.is_editable)
                  .map((customerDoc, index) => (
                    <FormControlLabel
                      key={index}
                      disabled={!customerDoc.is_editable}
                      checked={selectedCustomerDocIds.includes(customerDoc._id)}
                      onChange={() => handleChangeSelectedDocs(customerDoc._id)}
                      control={<Radio />}
                      label={
                        <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
                          <Typography>{customerDoc.name}</Typography>
                          <Link sx={{ flexShrink: 0 }} href={customerDoc.url} target="_blank">
                            <LinkIcon />
                          </Link>
                        </Stack>
                      }
                      sx={styles.radio}
                    />
                  ))}
              </Box>
              <Stack spacing={8}>
                <Button
                  fullWidth
                  variant="varied-contained"
                  disabled={!selectedCustomerDocIds.length || isPublishing}
                  startIcon={isPublishing ? <CircularProgress size={28} color="inherit" /> : <PublishIcon />}
                  onClick={handlePublish}
                >
                  Publish
                </Button>
                <Button variant="text" onClick={handleDiscard}>Discard</Button>
              </Stack>
            </Stack>
          </Box>
        </>
      )}
    </Dialog>
  );
};
