// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    p: theme.spacing(98, 20, 40, 20),
    backgroundColor: '#FFF',
    width: 1,
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  cdnWrapper: (() => ({
    maxHeight: '700px',
    overflow: 'auto',
    width: '100%'
  }))
};

// Export styles
export default styles;
