import {Box, Card, Grid, Typography} from '@mui/material';
import styles from './styles';
import {Header} from './Header';
import React from 'react';
import {ITemplate} from '../../../../resource';
import {ImageEditor} from '../../../../components/ImageEditor';

type Props = {
  template?: ITemplate;
  onChange: (value: Partial<ITemplate>) => void;
}

export const FirstPage = ({
  template,
  onChange,
}: Props) => {
  return (
    <Card sx={styles.paper}>
      <Typography color="#949599" fontSize={24}>
        Werbematerial, kein investmentrechtliches Pfichtdokument
      </Typography>
      <Header template={template} onChange={onChange} />
      <Box sx={styles.intro}>
        <Grid container spacing={20} sx={{ pb: 20 }}>
          <Grid item xs={2}>
            <Box sx={styles.introImage}>
              <ImageEditor value={template?.manImageUrl || "/images/intro.png"} onChange={(value) => onChange({ manImageUrl: value })} />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Typography align="justify" color="#000000" fontSize={20} fontWeight={600}>
              Der Oktober brachte kein einheitliches Bild bei der Performance. Zuwächse gab es beim S&P 500 (+1,9 Prozent) oder auch beim MSCI World (+0,8 Prozent). Deutliche Abschläge gab es beim EuroStoxx50 (-3,3 Prozent) oder auch beim DAX (-1,3 Prozent). Bei vielen positiven Quartalszahlen vor allem aus den USA bleibt jedoch die Furcht vor einer wieder anziehenden Inflation hoch. Gerade zum Monatsende legte die Nervosität im Vorfeld der US-Wahl an den Märkten zu.
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography align="justify" color="#000000" fontSize={20} fontWeight={600}>
              Der Sieg von Donald Trump brachte den Märkten dann Anfang November neue Stärke. Der Frankfurter - Value Focus Fund hatte im Oktober eine Performance von 3,3%. Den Fondspreis belasteten Agfa-Gevaert, Modern Times Group und Gerresheimer. Die Titel Intershop, Software Circle und secunet Security Networks lieferten einen positiven Beitrag.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
};
