// Dependencies
import React, { FC, useState } from 'react';
import { Collapse, Divider, List, ListItem, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { RegionenChart } from './regionen-chart';
import { BranchenChart } from './branchen-chart';
import { MarktkapitalisierungChart } from './marktkapitalisierung-chart';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Menu list
const menuList = ['Regionen', 'Branchen', 'Marktkapitalisierung', 'Aktuelle Positionen'];

const current_positions = [
  {
    title: 'ADMIRAL GROUP PLC',
    isin: 'GB00B02J6398',
    portfolio: '3,97%',
    capitalization: '7.353 Mrd. EUR',
  },
  {
    title: 'ADOBE INC',
    isin: 'US00724F1012',
    portfolio: '4,02%',
    capitalization: '168.150 Mrd. EUR',
  },
  {
    title: 'ALIBABA GROUP HOLDING LTD',
    isin: 'KYG017191142',
    portfolio: '3,93%',
    capitalization: '273.771 Mrd. EUR',
  },
  {
    title: 'ALPHABET INC-CL A',
    isin: 'US02079K3059',
    portfolio: '4,04%',
    capitalization: '1.351.875 Mrd. EUR',
  },
  {
    title: 'AMAZON.COM INC',
    isin: 'US0231351067',
    portfolio: '3,98%',
    capitalization: '998.986 Mrd. EUR',
  },
  {
    title: 'ANHEUSER-BUSCH INBEV SA/NV',
    isin: 'BE0974293251',
    portfolio: '3,99%',
    capitalization: '98.579 Mrd. EUR',
  },
  {
    title: 'AUTODESK INC',
    isin: 'US0527691069',
    portfolio: '3,99%',
    capitalization: '35.779 Mrd. EUR',
  },
  {
    title: 'BECTON DICKINSON AND CO',
    isin: 'US0758871091',
    portfolio: '3,94%',
    capitalization: '64.651 Mrd. EUR',
  },
  {
    title: 'CHARTER COMMUNICATIONS INC-A',
    isin: 'US16119P1084',
    portfolio: '4,01%',
    capitalization: '80.395 Mrd. EUR',
  },
  {
    title: 'CIE FINANCIERE RICHEMO-A REG',
    isin: 'CH0210483332',
    portfolio: '3,92%',
    capitalization: '54.325 Mrd. EUR',
  },
  {
    title: 'DIASORIN SPA',
    isin: 'IT0003492391',
    portfolio: '3,91%',
    capitalization: '6.238 Mrd. EUR',
  },
  {
    title: 'ECOLAB INC',
    isin: 'US2788651006',
    portfolio: '3,96%',
    capitalization: '41.198 Mrd. EUR',
  },
  {
    title: 'ESSILORLUXOTTICA',
    isin: 'FR0000121667',
    portfolio: '3,96%',
    capitalization: '59.510 Mrd. EUR',
  },
  {
    title: "L'OREAL",
    isin: 'FR0000120321',
    portfolio: '3,94%',
    capitalization: '161.507 Mrd. EUR',
  },
  {
    title: 'LVMH MOET HENNESSY LOUIS VUI',
    isin: 'FR0000121014',
    portfolio: '3,98%',
    capitalization: '274.588 Mrd. EUR',
  },
  {
    title: 'META PLATFORMS INC-CLASS A',
    isin: 'US30303M1027',
    portfolio: '4,02%',
    capitalization: '425.246 Mrd. EUR',
  },
  {
    title: 'MICROSOFT CORP',
    isin: 'US5949181045',
    portfolio: '4,07%',
    capitalization: '1.754.845 Mrd. EUR',
  },
  {
    title: 'NETFLIX INC',
    isin: 'US64110L1061',
    portfolio: '3,98%',
    capitalization: '71.433 Mrd. EUR',
  },
  {
    title: 'ORACLE CORP',
    isin: 'US68389X1054',
    portfolio: '4,45%',
    capitalization: '181.086 Mrd. EUR',
  },
  {
    title: 'ROCHE HOLDING AG-GENUSSCHEIN',
    isin: 'CH0012032048',
    portfolio: '3,97%',
    capitalization: '241.359 Mrd. EUR',
  },
  {
    title: 'RYANAIR HOLDINGS PLC',
    isin: 'IE00BYTBXV33',
    portfolio: '3,93%',
    capitalization: '13.615 Mrd. EUR',
  },
  {
    title: 'SAP SE',
    isin: 'DE0007164600',
    portfolio: '4,03%',
    capitalization: '106.757 Mrd. EUR',
  },
  {
    title: 'UNILEVER PLC',
    isin: 'GB00B10RZP78',
    portfolio: '3,97%',
    capitalization: '105.904 Mrd. EUR',
  },
  {
    title: 'VERISIGN INC',
    isin: 'US92343E1029',
    portfolio: '3,99%',
    capitalization: '16.945 Mrd. EUR',
  },
  {
    title: 'VISA INC-CLASS A SHARES',
    isin: 'US92826C8394',
    portfolio: '4,06%',
    capitalization: '399.268 Mrd. EUR',
  },
];

// Export portfolio panel
export const PortfolioPanel: FC = () => {
  // States
  const [collapseStatus, setCollapseStatus] = useState([false, false, false, false]);

  // Toggle collapse handler
  const handleToggleCollapse = (index: number) => {
    collapseStatus[index] = !collapseStatus[index];
    setCollapseStatus([...collapseStatus]);
  };

  // Return portfolio panel
  return (
    <>
      {menuList.map((menu, index) => (
        <React.Fragment key={index}>
          <MenuItem onClick={() => handleToggleCollapse(index)}>
            <div className="MenuItem_inner">
              {menu}
              {collapseStatus[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
          </MenuItem>
          <Collapse in={collapseStatus[index]}>
            {index === 0 && (
              <>
                <RegionenChart />
                <Stack mt={5} spacing={5} divider={<Divider />}>
                  <Typography>Diese Werte können sich im Laufe der Zeit ändern.</Typography>
                  <Typography>
                    Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                  </Typography>
                </Stack>
              </>
            )}
            {index === 1 && (
              <>
                <BranchenChart />
                <Stack mt={5} spacing={5} divider={<Divider />}>
                  <Typography>Diese Werte können sich im Laufe der Zeit ändern.</Typography>
                  <Typography>
                    Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                  </Typography>
                </Stack>
              </>
            )}
            {index === 2 && (
              <>
                <MarktkapitalisierungChart />
                <Stack mt={5} spacing={5} divider={<Divider />}>
                  <Typography>Diese Werte können sich im Laufe der Zeit ändern.</Typography>
                  <Typography>
                    Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                  </Typography>
                </Stack>
              </>
            )}
            {index === 3 && (
              <>
                <List className="scroll-mobile current_positions_list">
                  <ListItem className="list_heading_style" key={index}>
                    <ListItemText className="title">{`Unternehmen`}</ListItemText>
                    <ListItemText className="isin">{`ISIN`}</ListItemText>
                    <ListItemText className="portfolio">{`Gewicht im Portfolio`}</ListItemText>
                    <ListItemText className="capitalization">{`Marktkapitalisierung`}</ListItemText>
                  </ListItem>
                  {current_positions.map(({ title, isin, portfolio, capitalization }, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemText className="title">{title}</ListItemText>
                        <ListItemText className="isin">{isin}</ListItemText>
                        <ListItemText className="portfolio">{portfolio}</ListItemText>
                        <ListItemText className="capitalization">{capitalization}</ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
                <Stack mt={5} spacing={5} divider={<Divider />}>
                  <Typography>Diese Werte können sich im Laufe der Zeit ändern.</Typography>
                  <Typography>
                    Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: 30.06.2022
                  </Typography>
                </Stack>
              </>
            )}
          </Collapse>
        </React.Fragment>
      ))}
    </>
  );
};
