import React, {useRef, useState} from 'react';
import apis from '../../apis';

type Props = {
  value: any;
  onChange: (value: any) => void;
}

export const ImageEditor = ({
  value,
  onChange,
}: Props) => {
  const fileSelector = useRef<any>();
  const [image, setImage] = useState(value);

  const handleOpenBrowser = () => {
    fileSelector.current.click();
  };

  const handleUploadLogoImage = async (e: any) => {
    setImage(e.target.files[0]);

    apis.pdf.uploadLogoImage(e.target.files[0])
      .then((res) => {
        setImage(res.data);
        onChange(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <div onClick={handleOpenBrowser}>
      <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleUploadLogoImage} />
      {image && (
        <img
          src={typeof image === 'string' ? image : URL.createObjectURL(image)}
          alt="editor"
        />
      )}
    </div>
  )
};
