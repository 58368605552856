// Dependencies
import { FC, ReactElement, ReactNode } from 'react';
import { Divider } from '@mui/material';
import { DraggableData, DraggableEvent } from 'react-draggable';

// Components
import { Draggable } from '../Draggable';
import { Resizable } from '../Resizable';

// Editable items
import { EditableImage, IEditableImageProps } from './Image';
import { EditableLabel } from './Label';
import { EditableTextarea } from './Textarea';
import { FactSheetPage, PerformancePage } from '../../pages';
import { BranchenChart } from '../../pages/FactSheet/panels/Portfolio/branchen-chart';
import { RegionenChart } from '../../pages/FactSheet/panels/Portfolio/regionen-chart';
import { MarktkapitalisierungChart } from '../../pages/FactSheet/panels/Portfolio/marktkapitalisierung-chart';

// Interfaces
export enum EDITABLE_ITEM {
  IMAGE = 'image',
  LABEL = 'label',
  DIVIDER = 'divider',
  TEXTAREA = 'textarea',
  PERFORMANCE = 'performance',
  FACTSHEET = 'factsheet',
  BRANCHEN = 'branchen',
  REGIONEN = 'regionen',
  ACTUELLE_POSITIONEN = 'aktuelle positionen',
  MARKTKAPITALISIERUNG = 'Marktkapitalisierung',
}

interface IDraggableProps {
  x?: number | string;
  y?: number;
}

interface IResizableProps {
  width?: number | string;
  height?: number;
}

export interface IEditableProps {
  type: EDITABLE_ITEM;
  draggableProps?: IDraggableProps;
  resizableProps?: IResizableProps;
  imageProps?: IEditableImageProps;
  children?: ReactNode;
  content?: string;
  onEditableChange?: (editable: IEditableProps) => void;
  onChangeContent?: (e: any) => void;
}

// Styles
// import styles from './styles';

// Export Editable
export const Editable: FC<IEditableProps> = ({
  type,
  draggableProps,
  resizableProps,
  imageProps,
  children,
  onEditableChange,
  content,
}) => {
  let editable: ReactElement = <></>;

  switch (type) {
    case EDITABLE_ITEM.IMAGE: {
      editable = <EditableImage {...imageProps} />;
      break;
    }

    case EDITABLE_ITEM.LABEL: {
      editable = (
        <EditableLabel
          content={content}
          onChange={(value) => {
            onEditableChange &&
              onEditableChange({
                type,
                imageProps,
                resizableProps,
                draggableProps,
                content: value || '',
              });
          }}
        />
      );
      break;
    }

    case EDITABLE_ITEM.TEXTAREA: {
      editable = <EditableTextarea children={children} />;
      break;
    }

    case EDITABLE_ITEM.DIVIDER: {
      editable = <Divider />;
      break;
    }

    case EDITABLE_ITEM.PERFORMANCE: {
      editable = <PerformancePage />;
      break;
    }

    case EDITABLE_ITEM.FACTSHEET: {
      editable = <FactSheetPage />;
      break;
    }

    case EDITABLE_ITEM.BRANCHEN: {
      editable = <BranchenChart />;
      break;
    }

    case EDITABLE_ITEM.REGIONEN: {
      editable = <RegionenChart />;
      break;
    }

    case EDITABLE_ITEM.MARKTKAPITALISIERUNG: {
      editable = <MarktkapitalisierungChart />;
    }
  }

  // Drag handler
  const handleDrag = (_: DraggableEvent, data: DraggableData) => {
    if (onEditableChange) {
      onEditableChange({
        type,
        imageProps,
        resizableProps,
        content,
        draggableProps: {
          ...draggableProps,
          x: `${(data.x / window.innerWidth) * 100}%`,
          y: data.y,
        },
      });
    }
  };

  // Resize handler
  const handleResize = (size: { width: number; height: number }) => {
    if (onEditableChange) {
      onEditableChange({
        type,
        imageProps,
        draggableProps,
        content,
        resizableProps: {
          ...resizableProps,
          width: `${(size.width / window.innerWidth) * 100}%`,
          height: size.height,
        },
      });
    }
  };

  // Return Editable
  return (
    <Draggable {...draggableProps} onDrag={handleDrag}>
      <Resizable {...resizableProps} onResize={handleResize}>
        {editable}
      </Resizable>
    </Draggable>
  );
};
