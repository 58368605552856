import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {useToastContext} from './toastContext';
import styles from './styles';
import {Link, SnackbarContent} from '@mui/material';
import {SuccessIcon, CloseCircleIcon, ErrorIcon, InfoIcon, WarningIcon} from '../../assets/icons';

const  AlertType= {
   SUCCESS : "success",
   ERROR : "error",
   INFO : "info",
   WARNING:'warning'
}

export default function CustomizedSnackbars() {
  const {isToastShow, closeToast, type, text, description, link} = useToastContext();

  const handleClose = () => {
    if (closeToast) {
      closeToast();
    }
  };

  const getIcon =()=>{
    if(type === AlertType.SUCCESS){
      return <SuccessIcon height={20} width={20}/>
    }else  if(type === AlertType.ERROR){
      return <ErrorIcon  height={20} width={20}/>
    }else if(type == AlertType.INFO){
      return <InfoIcon height={20} width={20}/>
    }else if(type === AlertType.WARNING){
      return <WarningIcon height={20} width={20}/>
    }
  }

  const getColor =()=>{
    if(type === AlertType.SUCCESS){
      return '#d2ffe7'
    }else  if(type === AlertType.ERROR){
      return '#e0b3b3'
    }else if(type == AlertType.INFO){
      return '#d1daf3'
    }else if(type === AlertType.WARNING){
      return '#f6f0c3'
    }
  }
  return (
      <Snackbar
        open={isToastShow}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        sx={styles}
        onClose={handleClose}>
          <SnackbarContent sx={styles}
            message={
            <div style={styles.messageContainer}>
              <div style={{...styles.iconContainer,backgroundColor:getColor()}}>
                 {getIcon()}
               </div>
              <div style={{
                display:'flex',
                flexDirection:'column'
              }}>
               <span style={styles.heading}>
                 {text}
               </span>
              <span style={styles.description}>
                 {description}{link}
               </span>
                <span style={styles.link}>
                  <Link>
                    {link}
                  </Link>
               </span>
              </div>
            </div>
            }
            action={<CloseCircleIcon onClick={ handleClose} style={styles.cancel}/>}
          />
      </Snackbar>
  );
}
