import MUIRichTextEditor from 'mui-rte'
import { Box } from '@mui/material';
import styles from './styles';

type Props = {
  content: any;
  onChange: (content: any) => void;
}

export const RichText = ({ content, onChange }: Props) => {
  return (
    <Box sx={styles.richTextBox}>
      <MUIRichTextEditor
        label="Start typing..."
        value={content || ""}
        onSave={onChange}
      />
    </Box>
  )
}
