// Interfaces
import { IStyles } from '../../../resource';

// Define styles

const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: { xs: theme.spacing(42, 20, 18), md: theme.spacing(51, 46, 24) },
    borderRadius: theme.spacing(30, 0),
    width: '100%',
    maxWidth: { xs: 380, sm: 480, md: 890 },
    minHeight: 'calc(100vh - 445px)',
  }),
  preview: () => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: { xs: 'column', md: 'row' },
  }),
  divider: () => ({
    mx: { xs: 0, md: 46 },
    my: { xs: 23, md: 0 },
    height: { xs: 1, md: 'calc(100vh - 533px)' },
  }),
  thumbnail: (theme) => ({
    width: { xs: 1, md: '50%' },
    height: { xs: 'calc(50vh - 200px)', md: 'calc(100vh - 579px)' },
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(20, 0),
    overflow: 'hidden',
    img: {
      width: '100%',
      height: '100%',
    },
  }),
  description: () => ({
    width: { xs: 1, md: '50%' },
    height: { xs: 'calc(50vh - 200px)', md: 'calc(100vh - 579px)' },
  }),
  radio: () => ({
    width: '100%',
    mr: 0,
    opacity: 0.5,
    ':hover': {
      opacity: 1,
    },
    '.MuiFormControlLabel-label': {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
};

// Export styles
export default styles;
