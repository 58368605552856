// Interfaces
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  circle: (theme, params) => ({
    cursor: 'pointer',
    background: `${params.color}`,
    width: '32px',
    height: '32px',
    borderRadius: '24px',
    '&:hover': {
      boxShadow: theme.shadows[1],
    }
  }),
  popover: (theme) => ({
    marginTop: '4px',
    '.MuiPaper-root': {
      overflow: 'visible',
      borderRadius: '8px',
    }
  }),
  colorPicker: (theme) => ({
    '.react-colorful': {
      height: '240px'
    },
    '.react-colorful__saturation': {
      borderRadius: '4px 4px 0 0',
    },
    '.react-colorful__hue': {
      height: '40px',
      borderRadius: '0 0 4px 4px',
    },
    '.react-colorful__hue-pointer': {
      width: '12px',
      height: 'inherit',
      borderRadius: 0,
    }
  }),
};

// Export styles
export default styles;
