// Interfaces
import { IStyles } from '../../../resource';
import { darken } from '@mui/material';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.gray.light,
    p: theme.spacing(98, 20, 40, 20),
    maxWidth: { xs: 560, md: 560 },
    width: 1,
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  chart: (theme) => ({
    width: 80,
    height: 80,
    borderRadius: theme.spacing(16),
    '& > .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '48px',
      height: '48px',
    },
    ':hover': {
      backgroundColor: darken(theme.palette.gray.light, 0.1),
    },
  }),
};

// Export styles
export default styles;
