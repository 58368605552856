// Dependencies
import { Theme } from '@mui/material';

// Create styles
const styles = {
  item: (theme: Theme) => ({
    cursor: 'pointer',
    p: theme.spacing(10, 20),
  }),
};

// Export styles
export default styles;
