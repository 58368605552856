import React, { useState } from 'react';
import { Box, Button, FormControlLabel, Radio, Switch, TextField, Typography } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import { ArrowBack, Save } from '@mui/icons-material';
import Widget from '../components/Widget';
import { UserSelector } from '../../../components/UserSelector';
import { IAccount } from '../../../resource';
import apis from '../../../apis';
import { useToastContext } from '../../../components/Toast/toastContext';
import { ROUTES} from '../../../contants';
import styles from '../styles';
import { colors } from '../index';

export const WidgetAddPage = () => {
  const navigate = useNavigate();
  const { activeToast } = useToastContext();

  const [widget, setWidget] = useState<any>(null);
  const [customer, setCustomer] = useState<IAccount | null>(null);
  const [preview, setPreview] = useState(false);
  const [themeColor, setThemeColor] = useState(colors[0]);
  const [isin, setIsin] = useState<string>('');
  const [name, setName] = useState<string>('');

  const goBack = () => {
    navigate(-1);
  };

  const onChangeWidget = (widget) => {
    setWidget({
      ...widget,
      color: themeColor,
      isin: isin,
      name: name,
    });
  };

  const addNewWidget = () => {
    if (customer) {
      apis.cdn.saveCdnWidget(customer.id, widget)
        .then((res: any) => {
          if (res.status) {
            activeToast({
              type: 'success',
              text: res.msg,
            });
            navigate(ROUTES.MANAGE_WIDGETS);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeColor = (color) => (e) => {
    if (e.target.checked) {
      setThemeColor(color);
    }
  };

  const handleChangeIsin = (e) => {
    setIsin(e.target.value);
  }

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={20}>
        <Typography variant="h5">Add New Widget</Typography>
        <Button variant="outlined" startIcon={<ArrowBack />} onClick={goBack}>Back</Button>
      </Box>
      <Box display="flex" alignItems="center" marginY={20}>
        <UserSelector value={customer} onChange={setCustomer} />
        <Box marginLeft={20} display="flex" alignItems="center" gap={30}>
          <TextField
            label="NAME"
            fullWidth
            value={name || ""}
            onChange={handleChangeName}
            sx={styles.isinInput}
          />
          <TextField
            label="ISIN"
            type="string"
            fullWidth
            value={isin || ""}
            onChange={handleChangeIsin}
            sx={styles.isinInput}
          />
        </Box>
        <FormControlLabel
          sx={{ marginLeft: 'auto' }}
          onChange={() => setPreview(!preview)}
          control={<Switch checked={preview} color="primary" />}
          label="Preview"
        />
      </Box>
      <Box display="flex" justifyContent="space-between" marginY={20}>
        <Box display="flex" alignItems="center" gap={16}>
          {
            colors.map((color, index) => (
              <Box>
                <Radio
                  color="default"
                  checked={themeColor === color}
                  size="medium"
                  sx={(theme) => styles.colorRadio(theme, color)}
                  onChange={handleChangeColor(color)}
                />
                <Typography variant="subtitle2" sx={{ color: color }}>Theme {index}</Typography>
              </Box>
            ))
          }
        </Box>
      </Box>
      <Widget
        isin={isin}
        widget={widget}
        onChangeWidget={onChangeWidget}
        preview={preview}
        themeColor={themeColor}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          startIcon={<Save />}
          onClick={addNewWidget}
          disabled={!customer || !widget}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
};
