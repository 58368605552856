// Dependencies
import { IStyles } from '../../../resource';
import { alpha, darken } from '@mui/material';

// Define styles
const styles: IStyles = {
  sidebar: (theme) => ({
    width: 96,
    position: 'relative',
    bgcolor: theme.palette.gray.light,
    p: theme.spacing(20, 0),
    zIndex: 2,
    height: 'calc(100vh - 40px)',
  }),
  logo: (theme) => ({
    height: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mb: theme.spacing(18),
    img: {
      width: 46,
      height: 46,
    },
  }),
  navlink: (theme) => ({
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    p: theme.spacing(4, 16),
    ':hover': {
      '> div': {
        backgroundColor: darken(theme.palette.gray.light, 0.1),
      },
    },
    '&.active': {
      '> div': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.3),
      },
    },
  }),
  icon: (theme) => ({
    height: 32,
    width: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    mb: theme.spacing(7),
  }),
  profile: (theme) => ({
    mx: 'auto',
    left: 16,
    width: 64,
    bottom: 20,
    height: 64,
    cursor: 'pointer',
    position: 'absolute',
    boxShadow: theme.shadows[1],
  }),
  backToAdmin: (theme) => ({
    mx: 'auto',
    left: 16,
    width: 64,
    bottom: 100,
    height: 64,
    cursor: 'pointer',
    position: 'absolute',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  }),
  popover: (theme) => ({
    background: { xs: 'rgba(0, 0, 0, 0.4)', sm: 'transparent' },
    '.MuiPaper-root': {
      width: '100%',
      maxWidth: { xs: 'calc(100vw - 40px)', sm: 360 },
      ml: { xs: theme.spacing(-5), sm: theme.spacing(36) },
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.gray.light,
      borderRadius: theme.spacing(30, 0),
    },
  }),
  esgPopover: (theme) => ({
    background: { xs: 'rgba(0, 0, 0, 0.4)', sm: 'transparent' },
    '.MuiPaper-root': {
      width: '100%',
      maxWidth: { xs: 'calc(100vw - 40px)', sm: 300 },
      ml: { xs: theme.spacing(-5), sm: theme.spacing(12) },
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.gray.light,
      borderRadius: theme.spacing(0, 30),
    },
  }),
  card: (theme) => ({
    p: theme.spacing(12),
  }),
  cardHeader: (theme) => ({
    p: theme.spacing(12, 16),
    '.MuiAvatar-root': {
      width: 76,
      height: 76,
      boxShadow: theme.shadows[1],
    },
    a: {
      textDecoration: 'none',
    },
  }),
  divider: (theme) => ({
    height: '1px',
    mx: theme.spacing(14),
    backgroundColor: alpha(theme.palette.gray.main, 0.2),
  }),
  menuitem: (theme) => ({
    ...theme.typography.label1,
    color: theme.palette.primary.main,
    p: theme.spacing(18, 20, 18, 56),
    borderRadius: 28,
    position: 'relative',
    justifyContent: 'flex-start',
    ':hover': {
      backgroundColor: darken(theme.palette.gray.light, 0.1),
    },
    '&.active': {
      backgroundColor: darken(theme.palette.gray.light, 0.2),
    },
    svg: {
      left: 20,
      position: 'absolute',
    },
  }),
};

// Export styles
export default styles;
