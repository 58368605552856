// Dependencies
import { FC, ReactElement, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

// Components
import { Dialog } from '../../Dialog';

// Icons
import { CheckIcon, EyeFilledIcon, EyeIcon, LockIcon } from '../../../assets/icons';

// Styles
import styles from './styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import apis from '../../../apis';

// Interfaces
interface IResetPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  id?: string;
}
interface IField {
  name: string;
  label: string;
  type?: string;
  icon: ReactElement;
  endIcon?: ReactElement;
}

const initialFields: IField[] = [
  {
    name: 'currentPassword',
    type: 'password',
    label: 'Current Password',
    icon: <LockIcon />,
    endIcon: <EyeFilledIcon />,
  },
  {
    name: 'newPassword',
    type: 'password',
    label: 'New Password',
    icon: <LockIcon />,
    endIcon: <EyeFilledIcon />,
  },

  {
    name: 'confirmNewPassword',
    type: 'password',
    label: 'Repeat New Password',
    icon: <LockIcon />,
    endIcon: <EyeFilledIcon />,
  },
];

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required Field!'),
  newPassword: Yup.string().required('Required Field!'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'New password does not matched!')
    .required('Required Field!'),
});

// Export upload avatar dialog
export const ResetPasswordDialog: FC<IResetPasswordDialogProps> = ({ open, onClose, id }) => {
  const [fields, setFields] = useState<IField[]>(initialFields);
  const [loader, setLoader] = useState<boolean>(false);

  // Toggle visible handler
  const handleToggleVisible = (name: string) => () => {
    setFields(
      fields.map((field) => {
        if (field.name === name) {
          if (field.type === 'password') {
            field.type = 'text';
            field.endIcon = <EyeIcon />;
          } else {
            field.type = 'password';
            field.endIcon = <EyeFilledIcon />;
          }
        }
        return field;
      })
    );
  };

  const handleSubmit = async (values: any) => {
    setLoader(true);
    try {
      if (id != null) {
        const res: any = await apis.auth.resetPassword(id, values.currentPassword, values.newPassword);
        console.log('res', res);
        if (res.success) {
          setLoader(false);
          onClose();
        }
      }
    } catch (e) {
      console.log('error', e);
      setLoader(false);
    }
  };
  // Return upload avatar dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Typography mb={24} variant="headline">
        CIXON Account
      </Typography>
      <Typography mb={13} variant="title">
        Password
      </Typography>
      <Typography mb={15} variant="body2">
        A strong password helps to your account's security. Use at least 8 characters. Don’t use a password from another
        site, or something too obvious like your pet’s name.
      </Typography>
      <Divider />
      <Stack mt={32} px={8} spacing={32} alignItems="center">
        {loader ? (
          <CircularProgress />
        ) : (
          <Formik
            initialValues={
              {
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
              } as any
            }
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <Stack alignItems="center" component={Form} onSubmit={handleSubmit}>
                <Grid container columns={1} rowSpacing={35} columnSpacing={86}>
                  {fields.map(({ name, label, type, icon, endIcon }, index) => (
                    <Grid item width={'100%'} key={index}>
                      <TextField
                        fullWidth
                        name={name}
                        label={label}
                        value={values[name]}
                        type={type || 'text'}
                        error={Boolean(errors[name] && touched[name])}
                        helperText={Boolean(errors[name] && touched[name]) && (errors[name] as string)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                          endAdornment: endIcon && (
                            <InputAdornment position="end">
                              {/* @ts-ignore */}
                              <IconButton variant="text" size="medium" onClick={handleToggleVisible(name)}>
                                {endIcon}
                              </IconButton>
                            </InputAdornment>
                          ),
                          sx: (theme) => ({
                            ...theme.typography.body2,
                          }),
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Stack mt={30} width={350} spacing={8}>
                  <Button fullWidth type="submit" variant="varied-contained" startIcon={<CheckIcon />}>
                    Apply
                  </Button>
                  <Button variant="text" onClick={onClose}>
                    Discard
                  </Button>
                </Stack>
              </Stack>
            )}
          </Formik>
        )}
      </Stack>
    </Dialog>
  );
};
