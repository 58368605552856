// Define typography
import breakpoints from './breakpoints';

const typography = {
  fontSize: 16,
  fontFamily: 'Changa',
  display: {
    fontSize: 57,
    lineHeight: '64px',
    fontWeight: 300,
    fontFamily: 'Changa',
    letterSpacing: -0.15,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 76,
      lineHeight: '84px',
    },
    [`@media (min-width: ${breakpoints.values.md}px)`]: {
      fontSize: 102,
      lineHeight: '121px',
    },
  },
  headline: {
    fontSize: 32,
    lineHeight: '40px',
    fontWeight: 500,
    fontFamily: 'Changa',
    letterSpacing: 0.25,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 36,
    },
  },
  title: {
    fontSize: 22,
    lineHeight: '28px',
    fontWeight: 500,
    fontFamily: 'Changa',
    letterSpacing: 0.15,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 24,
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '15px',
    fontWeight: 400,
    fontFamily: 'Changa',
    letterSpacing: 0.15,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 18,
    },
  },
  label1: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
    fontFamily: 'Changa',
    letterSpacing: 0.1,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  label2: {
    fontSize: 10,
    lineHeight: '14px',
    fontWeight: 500,
    fontFamily: 'Changa',
    letterSpacing: 0.15,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  body1: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'IBMPlexSans',
    letterSpacing: 0.5,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 20,
    },
  },
  body2: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'IBMPlexSans',
    letterSpacing: 0.25,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 16,
    },
  },
  button: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    fontFamily: 'Changa',
    letterSpacing: 0.125,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 16,
    },
  },
  caption: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: 'Changa',
    letterSpacing: 0.4,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 16,
    },
  },
  overline: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 400,
    fontFamily: 'Changa',
    letterSpacing: 1.5,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: 12,
    },
  },
};

// Export typography
export default typography;
