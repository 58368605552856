import http from './http';
import { IAccount, IFilter } from '../resource';

export const createUser = async (user: IAccount) => {
  return await http.post('/users', user);
};

export const getUsers = async (filter?: IFilter) => {
  return await http.get('/users', filter);
};

export const getUser = async (id: string) => {
  return await http.get(`/users/${id}`);
};

export const updateUser = async (id: string, user) => {
  return await http.put(`/users/${id}`, user);
};

export const removeUser = async (id: string) => {
  return await http.delete(`/users/${id}`);
};

export const removeUsers = async (ids: string[]) => {
  return await http.delete('/users', { ids });
};
