// Resources
import { IStyles } from '../../../resource';

// Define Styles
const styles: IStyles = {
  dialog: (theme) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(30, 0),
    p: {
      xs: theme.spacing(42, 20, 20),
      sm: theme.spacing(42, 60, 24),
      md: theme.spacing(49, 80, 26),
      lg: theme.spacing(49, 115, 26),
    },
    position: 'relative',
    width: { xs: 'calc(100vw - 40px)', md: 'calc(100vw - 120px)', lg: 'calc(100vw - 426px)' },
  }),
  loader: (theme) => ({
    top: '0 !important',
    left: '0 !important',
    zIndex: 1,
    borderRadius: theme.spacing(30, 0),
    opacity: 0.5,
    backgroundColor: '#00000030',
  }),
  scrollbar: (theme) => ({
    height: 'calc(100vh - 418px)',
    px: 16,
    m: theme.spacing(35, -16, 26),
  }),
  template: (theme, params) => ({
    cursor: 'pointer',
    ':hover': {
      '.MuiBox-root': {
        boxShadow: `0 0 0px 1px ${theme.palette.secondary.main}`,
      },
    },
    '.MuiTypography-root': {
      fontSize: 14,
    },
    '.MuiBox-root': {
      height: 324,
      width: 'calc(100% - 2px)',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.spacing(20, 0),
      boxShadow: params?.active ? `0 0 0px 1px ${theme.palette.secondary.main}` : 'none',
      img: {
        width: '100%',
        height: '100%',
        borderRadius: theme.spacing(20, 0),
      },
    },
  }),
};

// Export styles
export default styles;
