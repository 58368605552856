// Dependencies
import React, { FC } from 'react';
import {Box, Stack, TextField, Typography} from '@mui/material';
import moment from 'moment';

// Styles
import styles from './styles';
import { ITemplate } from '../../../../../resource';
import { EditType } from '../../../../PDFViewer';
import {ImageEditor} from '../../../../../components/ImageEditor';

interface IHeader {
  editableItemClick?: (key: string, type: EditType) => any;
  template?: ITemplate;
  onChange: (value: any) => void;
}

// Export Header component
export const Header: FC<IHeader> = ({
  editableItemClick,
  template,
  onChange,
}) => {
  const getGermanDate = (date: string) => {
    const momentDate = moment(date);
    momentDate.locale('de');
    return momentDate.format('MMMM YYYY');
  };

  // Return Header component
  return (
    <Box sx={styles.container}>
      <Stack direction="row" spacing={30} alignItems="center">
        <Box sx={styles.image1}>
          <Box sx={(theme) => styles.imageBar(theme, template?.color)} />
          <Box sx={(theme) => styles.imageWrapper(theme, template?.color)}>
            <ImageEditor
              value={template?.icon_url || "/images/header-1.png"}
              onChange={(value) => onChange({ icon_url: value })}
            />
          </Box>
        </Box>
        <Box>
          <Typography color="#6d6e70" variant="h5" component="span" mr={5}>
            Facts & Figures
          </Typography>
          <Typography sx={{ cursor: 'pointer' }} color="#949599" variant="h5" component="span" onClick={() => {
            editableItemClick && editableItemClick('published_at', EditType.Date)();
          }}>
            | {template?.updatedAt && getGermanDate(template.updatedAt)}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <TextField
              multiline
              sx={(theme) => ({
                ...styles.fondInput(theme, template?.color),
              })}
              value={template?.fond}
              onChange={(e) => onChange({ fond: e.target.value })}
            />
          </Box>
        </Box>
      </Stack>
      <Stack direction="row" spacing={30} alignItems="center">
        <Box>
          <Typography fontSize={20} textAlign="right" fontWeight={600} p={0}>
            Shareholder Value <br />
            Management AG
          </Typography>
          <Typography fontSize={20} textAlign="right" p={0}>
            Neue Mainzer Straße 1 <br />
            D - 60311 Frankfurt am Main <br />
            Tel. +49 (0)69 66 98 30 0 <br />
            info@shareholdervalue.de <br />
          </Typography>
          <Typography fontSize={20} textAlign="right" fontWeight={600} p={0}>
            www.shareholdervalue.de
          </Typography>
        </Box>
        <Box sx={styles.image2}>
          <img src="/images/header-2.png" alt="header-2" />
        </Box>
      </Stack>
    </Box>
  );
};
