import { gql } from '@apollo/client';

export const GET_PORTRAIT = gql`
  query getPortrait {
    getPortrait {
      datum
      waehrung
      preis
      fondsvermoegen
      isin
    }
  }
`;
