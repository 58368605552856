import { gql } from '@apollo/client';

export const GET_CDN_WIDGETS = gql`
  query getCdnWidgets($widgetId: String!) {
    getCdnWidgets(widgetId: $widgetId) {
      data
    }
  }
`;

export const GET_TABLE = gql`
  query getTable($param: tableParam) {
    getTable(param: $param) {
      data
    }
  }
`;

export const GET_CELL = gql`
  query getCell($param: tableParam) {
    getCell(param: $param) {
      data
    }
  }
`;

export const GET_AUTOMATED_COLUMNS = gql`
  query getAutomatedColumns($param: automatedColumConfigs) {
    getAutomatedColumns(param: $param)
  }
`;
