// Dependencies
import { FC } from 'react';

// Pages
import {
  AccessPage,
  ChangePasswordPage,
  ConfirmationPage,
  CustomerDocumentPage,
  EsgAreas,
  EsgDocuments,
  EsgPractices,
  FactSheetPage,
  ForgotPasswordPage,
  GeneratorPage,
  HomePage,
  LoginPage,
  ManageWidgetsPage,
  ManageWidgetDetailsPage,
  WidgetAddPage,
  WidgetEditPage,
  NewDocPage,
  PDFViewer,
  PerformancePage,
  Portfolio,
  ProfilePage,
  SignUpPage,
  TemplateDetailPage,
  TemplateListPage,
  UserListPage,
  ValuePage
} from '../pages';

// Resource
import { ROLE } from '../resource';
import { CreateNewTemplate } from '../pages/Template/Detail/create';

// Interfaces
interface IRoute {
  path: string;
  role?: ROLE[];
  component: FC;
}

// Page
export type PageType = 'performance' | 'value' | 'factsheet';

// Routes
export const ROUTES = {
  AUTH: {
    LOGIN: '/',
    SIGN_UP: '/sign-up',
    CONFIRM_REGISTER: '/confirm/register/:token',
    CONFIRM_CHANGE_PASSWORD: '/confirm/change-password/:token',
    FORGOT_PASSWORD: '/forgot-password',
  },
  DASHBOARD: '/dashboard',
  OLD_DASHBOARD: '/old-dashboard',
  CUSTOMER_DOCUMENT: '/customer-document',
  ACCESS: '/access',
  PROFILE: '/profile',
  NEW_DOC: '/new',
  SETTINGS: '/settings',
  CREATE: '/create',
  EDIT: '/edit/:id',
  TEMPLATE: {
    LIST: '/templates',
    DETAIL: '/templates/:id',
  },
  ESG: {
    AREAS: '/esg/areas',
    PRACTICES: '/esg/practices',
    DOCUMENTS: '/esg/documents',
  },
  PORTFOLIO: {
    INDEX: '/portfolio/:type',
    REGION: '/portfolio/region',
    BRANCHEN: '/portfolio/branchen',
    MARKET: '/portfolio/market',
  },
  USER: {
    LIST: '/users',
  },
  PDF_VIEWER: '/docs/:id',
  MANAGE_WIDGETS: '/manage-widgets',
  WIDGET_DETAILS: '/manage-widgets/:widgetId',
  WIDGET_ADD: '/manage-widgets/add',
  WIDGET_EDIT: '/manage-widgets/:widgetId/edit',
};

// Page name
export const PAGE_NAME = {
  [ROUTES.DASHBOARD]: 'Dashboard',
  [ROUTES.TEMPLATE.LIST]: 'Templates',
  [ROUTES.PROFILE]: 'Edit Profile',
  [ROUTES.ACCESS]: 'Access',
  [ROUTES.USER.LIST]: 'Users',
  [ROUTES.ESG.AREAS]: 'ESG Geschäftsfelder',
  [ROUTES.ESG.PRACTICES]: 'ESG Geschäftspraktiken',
  [ROUTES.ESG.DOCUMENTS]: 'ESG Documents',
  [ROUTES.PORTFOLIO.REGION]: 'Portfolio Regionen',
  [ROUTES.PORTFOLIO.BRANCHEN]: 'Portfolio Branchen',
  [ROUTES.PORTFOLIO.MARKET]: 'Portfolio Marktkapitalisierung',
  [ROUTES.CUSTOMER_DOCUMENT]: 'Customer Document',
  [ROUTES.MANAGE_WIDGETS]: 'Manage Widgets',
  [ROUTES.WIDGET_DETAILS]: 'Widget Details',
  [ROUTES.WIDGET_ADD]: 'Add Widget',
  [ROUTES.WIDGET_EDIT]: 'Widget Edit',
};

export const getPageName = (pathname) => {
  let route = pathname;
  if (pathname.includes('/manage-widgets')) {
    route = ROUTES.MANAGE_WIDGETS;
  }
  return PAGE_NAME[route];
}

// Export auth routes
export const AUTH_ROUTES: IRoute[] = [
  {
    path: ROUTES.AUTH.LOGIN,
    component: LoginPage,
  },
  {
    path: ROUTES.AUTH.SIGN_UP,
    component: SignUpPage,
  },
  {
    path: ROUTES.AUTH.CONFIRM_REGISTER,
    component: ConfirmationPage,
  },
  {
    path: ROUTES.AUTH.CONFIRM_CHANGE_PASSWORD,
    component: ChangePasswordPage,
  },
  {
    path: ROUTES.AUTH.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
  },
];

// Export main routes
export const MAIN_ROUTES: IRoute[] = [
  {
    path: ROUTES.DASHBOARD,
    component: HomePage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.TEMPLATE.LIST,
    component: TemplateListPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.TEMPLATE.DETAIL,
    component: TemplateDetailPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.CUSTOMER_DOCUMENT,
    component: CustomerDocumentPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.ACCESS,
    component: AccessPage,
    role: [ROLE.CUSTOMER],
  },
  {
    path: ROUTES.PROFILE,
    component: ProfilePage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.CREATE,
    component: GeneratorPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.EDIT,
    component: GeneratorPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.PDF_VIEWER,
    component: PDFViewer,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.USER.LIST,
    component: UserListPage,
    role: [ROLE.ADMIN],
  },
  {
    path: '/performance',
    component: PerformancePage,
  },
  {
    path: '/value',
    component: ValuePage,
  },
  {
    path: '/factsheet',
    component: FactSheetPage,
  },
  {
    path: ROUTES.ESG.AREAS,
    component: EsgAreas,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.ESG.PRACTICES,
    component: EsgPractices,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.ESG.DOCUMENTS,
    component: EsgDocuments,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.PORTFOLIO.INDEX,
    component: Portfolio,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.MANAGE_WIDGETS,
    component: ManageWidgetsPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.WIDGET_DETAILS,
    component: ManageWidgetDetailsPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.WIDGET_ADD,
    component: WidgetAddPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
  {
    path: ROUTES.WIDGET_EDIT,
    component: WidgetEditPage,
    role: [ROLE.ADMIN, ROLE.CUSTOMER],
  },
];

// Export work routes
export const WORK_ROUTES: IRoute[] = [
  {
    path: ROUTES.NEW_DOC,
    component: CreateNewTemplate,
  },
];
