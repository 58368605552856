// Dependencies
import React from 'react';
import { createTheme } from '@mui/material';

// Configs
import breakpoints from './breakpoints';
import components from './components';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

declare module '@mui/material/styles' {
  interface Palette {
    gray: Palette['primary'];
  }
  interface PaletteOptions {
    gray: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    display: React.CSSProperties;
    headline: React.CSSProperties;
    title: React.CSSProperties;
    subtitle: React.CSSProperties;
    label1: React.CSSProperties;
    label2: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    display?: React.CSSProperties;
    headline?: React.CSSProperties;
    title?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    label1?: React.CSSProperties;
    label2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true;
    headline: true;
    title: true;
    subtitle: true;
    label1: true;
    label2: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'varied-outlined': true;
    'varied-contained': true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsVariantOverrides {
    outlined: true;
    text: true;
    'no-outlined': true;
  }
}

// Create theme
const theme = createTheme({
  spacing: 1,
  breakpoints,
  components,
  palette,
  shadows,
  typography,
});

// Export theme
export default theme;
