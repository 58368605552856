// Dependencies
import { FC, ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import Header from './Header';
import Sidebar from './Sidebar';

// Styles
import styles from './styles';

// Interfaces
interface IWorkLayoutProps {
  children: ReactNode;
}

// Export work layout
export const WorkLayout: FC<IWorkLayoutProps> = ({ children }) => {
  // States
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  // Toggle menu handler
  const handleToggleMenu = () => {
    setOpenSidebar(!openSidebar);
  };

  // Close menu handler
  const handleCloseMenu = () => {
    setOpenSidebar(false);
  };

  // Return work layout
  return (
    <Box sx={styles.layout}>
      <Header onMenuClick={handleToggleMenu} />
      <Box sx={styles.aside}>
        <Sidebar open={openSidebar} onClose={handleCloseMenu} />
        <Box sx={styles.content} component={PerfectScrollbar}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
