import React, {useContext, useEffect, useState} from 'react';
import {
  Box, Button, CircularProgress, FormControlLabel, Radio, Switch, TextField, Typography
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import Widget from '../components/Widget';
import { ArrowBack, Save } from '@mui/icons-material';
import apis from '../../../apis';
import { useToastContext } from '../../../components/Toast/toastContext';
import styles from '../styles';
import { colors } from '../index';
import {AppContext} from '../../../context';

export const WidgetEditPage = () => {
  const { isAdmin, account } = useContext(AppContext);
  const navigate = useNavigate();
  const { widgetId } = useParams<{ widgetId: string }>();
  const { activeToast } = useToastContext();

  const [widget, setWidget] = useState<any>(null);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [themeColor, setThemeColor] = useState(colors[0]);
  const [isin, setIsin] = useState<string>('');
  const [name, setName] = useState<string>('');

  const goBack = () => {
    navigate(-1);
  };

  const onChangeWidget = (widget) => {
    setWidget(widget);
  };

  const editWidget = () => {
    apis.cdn.updateCdnWidget(widget._id, {
      ...widget,
      color: themeColor,
      isin: isin,
      name: name,
    }, !isAdmin ? account?.id : undefined)
      .then((res: any) => {
        if (res.status) {
          activeToast({
            type: 'success',
            text: res.msg,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (widgetId) {
      setLoading(true);
      console.log(isAdmin)
      apis.cdn.getCdnWidgetByWidgetId(widgetId, !isAdmin ? account?.id : undefined)
        .then((res) => {
          if (res) {
            setLoading(false);
            setWidget(res);
            setThemeColor(res.color);
            setIsin(res.isin);
            setName(res.name);
          }
        })
        .catch((err) => {
          console.log('catch>>>', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleChangeColor = (color) => (e) => {
    if (e.target.checked) {
      setThemeColor(color);
    }
  };

  const handleChangeIsin = (e) => {
    setIsin(e.target.value);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={20}>
        <Typography variant="h5">Edit Widget</Typography>
        <Button variant="outlined" startIcon={<ArrowBack />} onClick={goBack}>Back</Button>
      </Box>
      <Box display="flex" alignItems="center" gap={30}>
        <TextField
          label="NAME"
          fullWidth
          value={name || ""}
          onChange={handleChangeName}
          sx={styles.isinInput}
        />
        <TextField
          label="ISIN"
          fullWidth
          value={isin || ""}
          onChange={handleChangeIsin}
          sx={styles.isinInput}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" marginY={20}>
        <Box display="flex" alignItems="center" gap={16}>
          {
            colors.map((color, index) => (
              <Box>
                <Radio
                  color="default"
                  checked={themeColor === color}
                  size="medium"
                  sx={(theme) => styles.colorRadio(theme, color)}
                  onChange={handleChangeColor(color)}
                />
                <Typography variant="subtitle2" sx={{ color: color }}>Theme {index}</Typography>
              </Box>
            ))
          }
        </Box>
      </Box>
      <Box>
        {loading ? (
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={56} />
          </Box>
        ) : (
          <Widget
            isin={isin}
            widget={widget}
            onChangeWidget={onChangeWidget}
            preview={preview}
            themeColor={themeColor}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          startIcon={<Save />}
          onClick={editWidget}
          disabled={!widget}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
};
