import styles from '../Dialogs/CustomerDoc/styles';
import { Autocomplete, TextField } from '@mui/material';
import {useContext, useEffect, useMemo} from 'react';
import { AppContext } from '../../context';
import { IAccount } from '../../resource';

type Props = {
  multiple?: boolean;
  value?: any | null;
  onChange: (value: any) => void;
};

export const UserSelector = ({ multiple, value, onChange }: Props) => {
  const { isAdmin, users, account } = useContext(AppContext);

  useEffect(() => {
    if (isAdmin) {
      users.fetchData();
    }
  }, [isAdmin]);

  const handleOnChange = (value: IAccount | null) => {
    onChange(value);
  };

  const selectedValue = useMemo(() => {
    return multiple ? (users.value?.filter((user) => user.id !== account?.id) || []).filter((item) => value?.some((valueItem) => valueItem._id === item.id)) : value;
  }, [value, users]);

  return (
    <Autocomplete
      id="combo-box-demo"
      disablePortal
      multiple={multiple}
      getOptionLabel={(option: IAccount) => `${option.firstName} ${option.lastName}`}
      options={users.value?.filter((user) => user.id !== account?.id) || []}
      value={selectedValue}
      sx={styles.userSelect}
      onChange={(_, value) => {
        handleOnChange(value as any);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ minWidth: '300px' }}
          label="Customer"
        />
      )}
    />
  )
};
