// Dependencies
import React, { FC, ReactNode } from 'react';
import DraggableComponent, { DraggableData, DraggableEvent } from 'react-draggable';
import { Box } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';

// Styles
import styles from './styles';

// Interfaces
export interface IDraggableProps {
  x?: number | string;
  y?: number;
  onDrag?: (e: DraggableEvent, data: DraggableData) => void;
  children?: ReactNode;
}

// Export Draggable component
export const Draggable: FC<IDraggableProps> = ({ children, x = 0, y = 0, onDrag }) => {
  if (typeof x === 'string') {
    if (x.endsWith('%')) {
      x = (+x.slice(0, -1) / 100) * window.innerWidth;
    } else {
      x = 0;
    }
  }

  // Return Draggable component
  return (
    <DraggableComponent axis="both" handle=".handle" defaultPosition={{ x, y }} onDrag={onDrag}>
      <Box sx={styles.draggable}>
        <Box className="handle" sx={styles.handle}>
          <DragIndicator />
        </Box>
        {children}
      </Box>
    </DraggableComponent>
  );
};
