// Dependencies
import { FC, useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography, CircularProgress } from '@mui/material';

// Constants
import { ROUTES } from '../../../contants';

// Context
import { AppContext } from '../../../context';

// Styles
import styles from './styles';
import { verifyToken } from '../../../apis/auth.apis';

// Export confirmation page
export const ConfirmationPage: FC = () => {
  // States
  const { dialogs } = useContext(AppContext);

  // Token
  const { token } = useParams<{ token: string }>();

  // Get navigate from hook
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [resultMessage, setResultMessage] = useState<string>('');

  useEffect(() => {
    if (token) {
      setLoading(true);
      verifyToken('register', { token })
        .then((res) => {
          setLoading(false);
          setResultMessage(res?.msg || 'You are successfully confirmed!');
        })
        .catch((err) => {
          setResultMessage(err.msg || 'Failed to verify token');
          setLoading(false);
        });
    }
  }, [token]);

  // Go to login handler
  const handleGotoLogin = () => {
    navigate(ROUTES.AUTH.LOGIN);
    dialogs.login.setVisible(true);
  };

  // Return confirmation page
  return (
    <Stack spacing={30} sx={styles.container}>
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          <Typography variant="headline" align="center">
            {resultMessage}
          </Typography>
          <Button fullWidth variant="varied-outlined" onClick={handleGotoLogin} sx={{ maxWidth: 420 }}>
            Go to Login
          </Button>
        </>
      )}
    </Stack>
  );
};
