// Dependencies
import { IStyles } from '../../../resource';
import { alpha, darken } from '@mui/material';

// Define styles
const styles: IStyles = {
  drawer: (theme, params) => ({
    top: 116,
    height: { xs: 'calc(100vh - 116px)', md: 'calc(100vh - 78px)' },
    bgcolor: { xs: 'none', md: theme.palette.gray.light },
    transitionDuration: '300ms',
    boxShadow: params?.expanded ? theme.shadows[1] : 'none',
    zIndex: 1,
    borderRadius: theme.spacing(0, 30, 30, 0),
    width: params?.expanded ? 476 : 96,
    '.MuiBackdrop-root': {
      top: 116,
    },
    '.MuiPaper-root': {
      boxShadow: 'none',
      height: 'inherit',
      width: 'inherit',
      top: { xs: 116, md: 78 },
      borderRight: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'row',
      '> .MuiBox-root:last-of-type': {
        transform: { xs: 'none', md: params?.expanded ? 'translateX(0)' : 'translateX(-100%)' },
      },
      '> .MuiBox-root:first-of-type': {
        transform: 'none',
        borderRight: params?.expanded ? `1px solid ${alpha(theme.palette.gray.main, 0.2)}` : 'none',
      },
    },
  }),
  sidebar: (theme) => ({
    bgcolor: theme.palette.gray.light,
    width: 96,
    position: 'relative',
    p: theme.spacing(20, 0),
    zIndex: 1,
  }),
  item: (theme) => ({
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    p: theme.spacing(4, 16),
    cursor: 'pointer',
    ':hover': {
      '> div': {
        backgroundColor: darken(theme.palette.gray.light, 0.1),
      },
    },
    '&.active': {
      '> div': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.3),
      },
    },
  }),
  icon: (theme) => ({
    height: 32,
    width: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    mb: theme.spacing(7),
  }),
  profile: (theme) => ({
    mx: 'auto',
    left: 16,
    width: 64,
    bottom: 20,
    height: 64,
    cursor: 'pointer',
    position: 'absolute',
    boxShadow: theme.shadows[1],
  }),
  popover: (theme) => ({
    background: { xs: 'rgba(0, 0, 0, 0.4)', sm: 'transparent' },
    '.MuiPaper-root': {
      width: '100%',
      maxWidth: { xs: 'calc(100vw - 40px)', sm: 360 },
      ml: { xs: theme.spacing(-5), sm: theme.spacing(36) },
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.gray.light,
      borderRadius: theme.spacing(30, 0),
    },
  }),
  card: (theme) => ({
    p: theme.spacing(12),
  }),
  cardHeader: (theme) => ({
    p: theme.spacing(12, 16),
    '.MuiAvatar-root': {
      width: 76,
      height: 76,
      boxShadow: theme.shadows[1],
    },
    a: {
      textDecoration: 'none',
    },
  }),
  divider: (theme) => ({
    height: '1px',
    mx: theme.spacing(14),
    backgroundColor: alpha(theme.palette.gray.main, 0.2),
  }),
  menuitem: (theme) => ({
    ...theme.typography.label1,
    color: theme.palette.primary.main,
    p: theme.spacing(18, 20, 18, 56),
    borderRadius: 28,
    position: 'relative',
    justifyContent: 'flex-start',
    ':hover': {
      backgroundColor: darken(theme.palette.gray.light, 0.1),
    },
    svg: {
      left: 20,
      position: 'absolute',
    },
  }),
  panel: (theme) => ({
    position: 'relative',
    zIndex: 0,
    width: { xs: 'calc(100% - 32px)', sm: 348 },
    maxWidth: 348,
    p: { xs: theme.spacing(56, 16, 20), md: theme.spacing(20, 16, 62) },
    transitionDuration: '300ms',
    backgroundColor: { xs: theme.palette.gray.light, md: 'transparent' },
    borderRadius: { xs: theme.spacing(30, 0, 30, 0), md: theme.spacing(0, 30, 30, 0) },
    '> .MuiIconButton-root': {
      position: 'absolute',
      bottom: { xs: 'unset', md: 16 },
      top: { xs: 16, md: 'unset' },
      right: 16,
    },
  }),
  menuIcon: () => ({
    position: 'absolute',
    display: { xs: 'inline-flex', md: 'none' },
  }),
  dialog: () => ({
    '.MuiPaper-root': {
      alignItems: 'center',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      margin: 0,
      width: 1,
      maxWidth: 'calc(100vw - 40px)',
    },
  }),
};

// Export styles
export default styles;
