// Dependencies
import { alpha } from '@mui/material';

// Resources
import { IStyles } from '../../../../resource';

// Define styles
const styles: IStyles = {
  container: () => ({
    width: '100%',
  }),
  size: (theme) => ({
    width: { xs: 34, md: 44 },
    height: { xs: 36, md: 46 },
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    ':hover': {
      borderColor: 'transparent',
      backgroundColor: alpha(theme.palette.secondary.main, 0.3),
    },
  }),
  decrease: (theme) => ({
    height: { xs: 36, md: 46 },
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRight: 'none',
    p: { xs: theme.spacing(6, 2, 6, 6), md: theme.spacing(11, 7, 11, 11) },
    borderRadius: { xs: theme.spacing(18, 0, 0, 18), md: theme.spacing(23, 0, 0, 23) },
    ':hover': {
      borderColor: 'transparent',
      backgroundColor: alpha(theme.palette.secondary.main, 0.3),
    },
  }),
  increase: (theme) => ({
    height: { xs: 36, md: 46 },
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderLeft: 'none',
    p: { xs: theme.spacing(6, 6, 6, 2), md: theme.spacing(11, 11, 11, 7) },
    borderRadius: { xs: theme.spacing(0, 18, 18, 0), md: theme.spacing(0, 23, 23, 0) },
    ':hover': {
      border: 'none',
      backgroundColor: alpha(theme.palette.secondary.main, 0.3),
    },
  }),
};

// Export styles
export default styles;
