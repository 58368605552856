// Resources
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  longCell: () => ({
    // maxWidth: { lg: '300px', xl: '500px', xxl: '700px' },
    display: 'flex',
    '& > div': {
      width: 0,
      flexGrow: 1,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }
  })
};

// Export styles
export default styles;
