// Interfaces
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  layout: (theme) => ({
    minHeight: '100vh',
    backgroundColor: theme.palette.gray.light,
  }),
  content: () => ({
    height: `calc(100vh - 72px)`,
  }),
};

// Export styles
export default styles;
