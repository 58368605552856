import {
  Box,
  Button,
  MenuItem,
  IconButton,
  OutlinedInput,
  Radio,
  Select,
  Switch,
  Typography
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import { Add, Delete } from "@mui/icons-material";
import styles from './styles';

import {SolActivePanel} from './history';

export const colors = [
  '#003F7A',
  '#92c33e'
];

type Props = {
  id?: number;
  isin: string;
  content: any;
  themeColor: string;
  onChange: (id: number | undefined, value: any) => void;
  onChangeDescription: (text: string) => void;
  onChangeTheme: (theme: string) => void;
  onChangeChartType: (type: string) => void;
  onChangeChartIsin: (isin: string) => void;
}

export const HistoryChart = ({
  id,
  content,
  themeColor,
  onChange,
  onChangeTheme,
  onChangeChartType,
  onChangeChartIsin,
  isin,
}: Props) => {
  console.log(themeColor);
  const handleChangeChartTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(id, { ...content, chartTitle: e.target.value });
  };

  const handleChangeChartText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(id, { ...content, chartText: e.target.value });
  };

  const handleChangeColor = (color) => {
    onChangeTheme(color);
  };

  const handleChangeAxisType = (isPercent) => {
    onChange(id, { ...content, isPercent });
  }

  const handleAddAdditionalIsin = () => {
    onChange(id, {
      ...content,
      additionalIsins: [
        ...(content.additionalIsins || []),
        {
          id: +new Date(),
          isin: '',
          title: '',
          description: '',
          color: '',
        }
      ],
    });
  };

  const handleChangeAdditionalIsinData = (index) => (e) => {
    const additionalIsins = content.additionalIsins;
    additionalIsins[index][e.target.name] = e.target.value;
    onChange(id, {
      ...content,
      additionalIsins,
    });
  };

  const handleDeleteAdditionalIsin = (index) => {
    const additionalIsins = content.additionalIsins;
    additionalIsins.splice(index, 1);
    onChange(id, {
      ...content,
      additionalIsins,
    });
  };

  const handleChangeChartType = (e) => {
    onChangeChartType(e.target.value);
  };

  const handleChangeIsin = (e) => {
    onChangeChartIsin(e.target.value);
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(id, { ...content, title: e.target.value });
  };

  // @ts-ignore
  return (
    <Box>
      <Typography variant="h5">History Chart</Typography>

      <Box display="flex" alignItems="center" gap="16px">
        <Typography sx={{ width: '70px' }} variant="subtitle1">Title</Typography>
        <OutlinedInput sx={{ width: '300px' }} name="title" type="string" value={content.title || ""} onChange={handleChangeTitle} />
      </Box>

      <Box display="flex" alignItems="center" gap={16}>
        <Box>
          <Radio
            checked={content.theme ? content.theme === colors[0] : true}
            color="default"
            size="medium"
            sx={(theme) => styles.colorRadio(theme, colors[0])}
            onChange={() => handleChangeColor(colors[0])}
          />
          <Typography variant="subtitle2" sx={{ color: colors[0] }}>Color 1</Typography>
        </Box>
        <Box>
          <Radio
            color="default"
            checked={content?.theme === colors[1]}
            size="medium"
            sx={(theme) => styles.colorRadio(theme, colors[1])}
            onChange={() => handleChangeColor(colors[1])}
          />
          <Typography variant="subtitle2" sx={{ color: colors[1] }}>Color 2</Typography>
        </Box>
        <Box sx={{ ml: '30px' }}>
          <OutlinedInput value={content?.isin || ""} onChange={handleChangeIsin} placeholder="ISIN" />
        </Box>
        <Box>
          <Select sx={styles.chartTypeSelect} value={content?.chartType} onChange={handleChangeChartType}>
            <MenuItem value="SolActive">Solactive</MenuItem>
            <MenuItem value="Axxion">Axxion</MenuItem>
          </Select>
        </Box>
        <Box sx={(theme) => styles.axisSwitch(theme, content.isPercent)}>
          <Typography variant="body2" onClick={() => handleChangeAxisType(false)}>
            Point
          </Typography>
          <Switch
            checked={content.isPercent}
            onChange={(e) => handleChangeAxisType(e.target.checked)}
          />
          <Typography variant="body2" color="secondary" onClick={() => handleChangeAxisType(true)}>
            Percent
          </Typography>
        </Box>
      </Box>

      <SolActivePanel color={themeColor || '#003F7A'} content={content} isin={isin} />

      <Box sx={styles.title}>
        <Typography variant="subtitle2">Title</Typography>
        <input value={content.chartTitle} onChange={handleChangeChartTitle} />
      </Box>
      <Box sx={styles.description}>
        <Typography variant="subtitle2">Description</Typography>
        <textarea value={content.chartText} onChange={handleChangeChartText} />
      </Box>

      {
        content?.additionalIsins?.map((additionalIsin, index) => (
          <Box sx={styles.additionalIsinPanel}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h5">Additional ISIN #{index + 1}</Typography>
              <IconButton sx={{ width: 32, height: 32, border: 0 }} onClick={() => handleDeleteAdditionalIsin(index)}>
                <Delete sx={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
            {/*<Box sx={styles.colorPicker}>*/}
            {/*  <HexColorPicker color={additionalIsin.color} onChange={handleChangeIsinColor(index)} />*/}
            {/*</Box>*/}

            <Box display="flex">
              <Box>
                <OutlinedInput name="isin" value={additionalIsin?.isin || ""} onChange={handleChangeAdditionalIsinData(index)} placeholder="ISIN" />
              </Box>
              <Box>
                <Select name="chartType" sx={styles.chartTypeSelect} value={additionalIsin?.chartType} onChange={handleChangeAdditionalIsinData(index)}>
                  <MenuItem value="SolActive">Solactive</MenuItem>
                  <MenuItem value="Axxion">Axxion</MenuItem>
                </Select>
              </Box>
            </Box>

            <Box sx={styles.title}>
              <Typography variant="subtitle2">Title</Typography>
              <input name="title" value={additionalIsin.title} onChange={handleChangeAdditionalIsinData(index)} />
            </Box>
            <Box sx={styles.description}>
              <Typography variant="subtitle2">Description</Typography>
              <textarea name="chartText" value={additionalIsin.chartText} onChange={handleChangeAdditionalIsinData(index)} />
            </Box>
          </Box>
        ))
      }

      <Button variant="outlined" sx={{ mt: 16 }} startIcon={<Add />} onClick={handleAddAdditionalIsin}>
        Additional ISINs
      </Button>
    </Box>
  )
};
