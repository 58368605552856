// Dependencies
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';

// Component
import { Dialog } from '../../../../components/Dialog';

// Styles
import styles from '../../styles';

import apis from '../../../../apis';
import { API_SERVER } from '../../../../contants';

interface IContact {
  _id?: string;
  name: string;
  image: any;
  role: string;
  second_role: string;
  email: string;
  tel: string;
  link: string;
}

const FORMS = [
  { label: "Name", key: "name", type: "string" },
  { label: "Link", key: "link", type: "string" },
  { label: "Image", key: "image", type: "file" },
  { label: "Role", key: "role", type: "string" },
  { label: "Second Role", key: "second_role", type: "string" },
  { label: "Email", key: "email", type: "string" },
  { label: "Tel", key: "tel", type: "string" },
];

// Interfaces
interface IContactEditModalProps {
  title: string;
  open: boolean;
  data: IContact[];
  selectedId: string;
  loading: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export ArrayEdit Modal
export const ContactEditModal: FC<IContactEditModalProps> = ({
  title,
  open,
  data,
  loading,
  selectedId,
  onClose,
  onSave,
}) => {
  const fileSelector = useRef<any>();
  const [contact, setContact] = useState<IContact>({
    name: '',
    image: '',
    role: '',
    second_role: '',
    email: '',
    tel: '',
    link: '',
  });

  const handleOpenBrowser = () => {
    fileSelector.current.click();
  };

  const handleUploadLogoImage = async (e: any) => {
    apis.pdf.uploadLogoImage(e.target.files[0])
      .then((res) => {
        // setLogos([...tempLogos.filter((_, index) => index !== tempLogos.length - 1), { path: res.data, name: '' }]);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleSave = () => {
    onSave(contact);
  };

  const handleChangeAvatar = (e: any) => {
    if (contact) {
      setContact({
        ...contact,
        image: e.target.files[0]
      });
    }
  };

  const handleChange = (e: any) => {
    if (contact) {
      setContact({
        ...contact,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    const selectedContact = data.find((c) => c._id === selectedId)
    if (selectedContact) {
      setContact(selectedContact);
    }
  }, [data, selectedId]);

  // Return Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
      <DialogContent>
        <Box sx={styles.logoUploader} onClick={handleOpenBrowser}>
          <Avatar
            src={
              typeof contact?.image === 'string'
                ? contact?.image?.includes('https')
                ? contact?.image
                : `${API_SERVER}/${contact?.image}`
                : URL.createObjectURL(contact?.image)
            }
            sx={styles.logo}
          />
          <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleChangeAvatar} />
        </Box>
        <Box display="flex" flexDirection="column">
          {FORMS.map((form) => form.type === "string" && (
            <TextField
              sx={{ mt: 16, width: 400 }}
              variant="outlined"
              fullWidth
              label={form.label}
              name={form.key}
              value={contact && contact[form.key]}
              onChange={handleChange}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
};
