7// Dependencies
import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

// Interfaces
interface ILabelProps {
  hideDivider?: boolean;
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  children: ReactNode;
}

// Export
export const Label: FC<ILabelProps> = ({ color = 'primary', hideDivider, onClick, children }) => {
  // Return label component
  return (
    <Typography
      variant="h5"
      sx={(theme) => ({
        mb: theme.spacing(10),
        borderBottom: hideDivider ? '0px' : '1px solid #a7a8ab',
        color: color || '#6d6e70',
        cursor: onClick ? 'pointer' : 'auto',
        '&:hover': {
          background: onClick ? '#c3c3c3': 'inherit',
        }
      })}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};
