import { useState } from 'react';
import { Box, Input, Typography } from '@mui/material';
import { PencilIcon, CheckIcon } from '../../assets/icons';

export const LabelEditor = ({
  value,
  onChange,
  color,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [label, setLabel] = useState(value);

  const handleClickIcon = (e) => {
    e.stopPropagation();
    if (isEdit) {
      onChange(label);
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  return (
    <Box
      onClick={(e) => isEdit && e.stopPropagation()}
      sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
    >
      {isEdit ? (
        <Input value={label} onChange={(e) => setLabel(e.target.value)} />
      ) : (
        <Typography
          variant="h5"
          sx={{ color }}
        >
          {value}
        </Typography>
      )}
      <Box
        onClick={(e) => handleClickIcon(e)}
        sx={{ cursor: 'pointer', color, display: 'flex', alignItems: 'center' }}
      >
        {isEdit ? <CheckIcon /> : <PencilIcon />}
      </Box>
    </Box>
  );
};
