import { Dialog } from '../../Dialog';
import styles from './styles';
import {Box, Button, CircularProgress, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import apis from '../../../apis';
import { useToastContext } from '../../Toast/toastContext';
import {UserSelector} from '../../UserSelector';
import {IAccount} from '../../../resource';

type Props = {
  open: boolean;
  selectedWidget: any;
  onClose: (refresh?: boolean) => void;
}

export const DuplicateWidget = ({
  open,
  selectedWidget,
  onClose,
}: Props) => {
  const [isin, setIsin] = useState<string>(selectedWidget.isin);
  const [name, setName] = useState<string>(selectedWidget.name);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<IAccount | null>(null);
  const { activeToast } = useToastContext();

  const handleChangeIsin = (e) => {
    setIsin(e.target.value);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeCustomer = (value: IAccount | null) => {
    setCustomer(value);
  };

  const handleDuplicate = () => {
    setLoading(true);
    apis.cdn.duplicateCdnWidget(selectedWidget._id, {
      name,
      isin,
      customerId: customer?.id,
    })
    .then((res) => {
      activeToast({
        type: 'success',
        text: res.msg,
      });
      onClose(true);
    }).catch((e) => {
      activeToast({
        type: 'error',
        text: e.message || 'Failed to duplicate widget.',
      });
      onClose(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} sx={styles.dialog}>
        <Box>
          <Typography variant="h5">
            Duplicate Widget
          </Typography>
        </Box>

        <Box sx={{ mt: 8 }}>
          <UserSelector value={customer} onChange={handleChangeCustomer} />
          <TextField
            sx={{ mt: 8 }}
            label="NAME"
            fullWidth
            value={name || ""}
            onChange={handleChangeName}
          />
          <TextField
            sx={{ mt: 8 }}
            label="ISIN"
            fullWidth
            value={isin || ""}
            onChange={handleChangeIsin}
          />
        </Box>

        <Box sx={styles.footer}>
          <Button
            variant="outlined"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button onClick={handleDuplicate}>
            {loading && <CircularProgress size={16} color="inherit" sx={{ mr: 4 }} />}
            Duplicate
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
