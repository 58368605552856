import http from './http';
import { IFilter, IEsgAreaDocument, ICustomerDocument } from '../resource';

const convert2Form = (data) => {
  const form: any = new FormData();
  form.append('logo', data.logo_url);
  form.append('exclusionCriteria', data.exclusionCriteria);
  form.append('noSales', data.noSales);
  form.append('sales_value_less_5', data.sales_value_less_5);
  form.append('sales_value_more_5', data.sales_value_more_5);
  return form;
};

export const createEsgArea = async (customerId: string, data: Partial<IEsgAreaDocument>) => {
  return await http.post(`/esg/${customerId}/areas`, convert2Form(data));
};

export const updateEsgArea = async (customerId: string, id: string, data: Partial<IEsgAreaDocument>) => {
  return await http.put(`/esg/${customerId}/areas/${id}`, convert2Form(data));
};

export const getCustomerAreas = async (customerId: string, filter?: IFilter) => {
  return await http.get(`/esg/${customerId}/areas`, filter);
};

export const deleteCustomerArea = async (customerId: string, id: string) => {
  return await http.delete(`/esg/${customerId}/areas/${id}`);
}

export const updateEsgAreaDate = async (customerId: string, date: string) => {
  return await http.put(`/esg/${customerId}/areas/date`, { date });
};

export const updateEsgPractices = async (customerId: string, data: { practices: number[], practiceDate: string }) => {
  return await http.put(`/esg/${customerId}/practice`, { data });
};

export const getEsgData = async (customerId: string) => {
  return await http.get(`/esg/${customerId}`);
};

export const getAllEsgDocuments = async (customerId: string, filter?: IFilter) => {
  return await http.get(`/esg/${customerId}/document`, filter);
};

export const getEsgDocument = async (customerId: string, docId: string) => {
  return await http.get(`/esg/${customerId}/document/${docId}`);
};

export const getEsgDocuments = async (customerId: string, filter?: IFilter) => {
  return await http.get(`/esg/${customerId}/document`, filter);
};

export const removeEsgDocuments = async (customerId: string, ids: string[]) => {
  return await http.delete(`/esg/${customerId}/document`, { ids });
};

export const updateEsgDocument = async (
  customerId: string,
  docId: string,
  document: Partial<ICustomerDocument>
) => {
  return await http.put(`/esg/${customerId}/document/${docId}`, document);
};

export const createEsgDocument = async (customerId: string, document: Partial<ICustomerDocument>) => {
  return await http.post(`/esg/${customerId}/document`, document);
};
