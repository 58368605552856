// Dependencies
import { alpha } from '@mui/material';

// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  checkbox: (theme) => ({
    color: alpha(theme.palette.gray.dark, 0.5),
    '&.Mui-checked': {
      color: theme.palette.gray.dark,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.gray.dark,
    },
  }),
  sortLabel: (theme) => ({
    ml: theme.spacing(8),
    svg: {
      height: 12,
      width: 12,
    },
    '&.Mui-active': {},
  }),
};

// Export styles
export default styles;
