// Dependencies
import { darken } from '@mui/material';

// Resources
import { IStyles } from '../../../../resource';

// Define styles
const styles: IStyles = {
  dropzone: (theme) => ({
    height: 178,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(23),
    border: `1px dashed ${theme.palette.secondary.main}`,
    backgroundColor: darken(theme.palette.gray.light, 0.1),
    textTransform: 'none',
    cursor: 'pointer',
    ':focus': {
      outline: 'none',
    },
  }),
};

// Export styles
export default styles;
