// Interface
import { IStyles } from '../../resource';

// Styles
const styles: IStyles = {
  layout: () => ({
    display: 'flex',
  }),
  aside: () => ({
    flex: 1,
    position: 'relative',
  }),
  header: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: theme.palette.secondary.main,
    p: `${theme.spacing(5, 20)} !important`,
  }),
  logo: () => ({
    width: 60,
    height: 60,
  }),
  searchInput: (theme) => ({
    borderRadius: 1,
    p: theme.spacing(10, 20),
    bgcolor: theme.palette.common.white,
    '.MuiInputBase-input': {
      pb: 1,
    },
  }),
  content: (theme) => ({
    height: {
      xs: 'calc(100vh - 218px)',
      sm: 'calc(100vh - 242px)',
      md: 'calc(100vh - 238px)',
      lg: 'calc(100vh - 302px)',
    },
    p: { xs: theme.spacing(30, 20), sm: theme.spacing(42, 40), md: theme.spacing(64, 80), lg: theme.spacing(96, 140) },
  }),
  dialogRef: () => ({
    position: 'absolute',
    top: 110,
    width: '100%',
  }),
};

// Export styles
export default styles;
