// Resources
import { IStyles } from '../../../../resource';

// Define styles
const styles: IStyles = {
  template: (theme) => ({
    height: 240,
    borderRadius: theme.spacing(20, 0),
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    img: {
      width: 1,
      height: 1,
    },
  }),
};

// Export styles
export default styles;
