// Dependencies
import { FC, useContext, useState } from 'react';
import { Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Context
import { AppContext } from '../../../context';

// Constants
import { ROUTES } from '../../../contants';

// Icons
import { NewDocHoverIcon, NewDocIcon, TemplateHoverIcon, TemplateIcon } from '../../../assets/icons';

// Components
import { Dialog } from '../../Dialog';
import { TemplatesDialog } from '../Templates';

// Styles
import styles from './styles';

// Interfaces
interface INewDocDialogProps {
  open: boolean;
}

// Export new doc dialog
export const NewDocDialog: FC<INewDocDialogProps> = ({ open }) => {
  // States
  const [hoverId, setHoverId] = useState<number>();
  const [visibleTemplatesDialog, setVisibleTemplatesDialog] = useState<boolean>(false);

  // Theme
  const theme = useTheme();

  // Check window size
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get navigate from hook
  const navigate = useNavigate();

  // Get context
  const { dialogs } = useContext(AppContext);

  // Hover handler
  const handleHover = (id: number) => () => {
    setHoverId(id);
  };

  // Blur handler
  const handleBlur = () => {
    setHoverId(undefined);
  };

  // Close handler
  const handleClose = () => {
    dialogs.newDoc.setVisible(false);
  };

  // Open templates dialog handler
  const handleOpenTemplatesDialog = () => {
    setVisibleTemplatesDialog(true);
  };

  // Close templates dialog handler
  const handleCloseTemplatesDialog = () => {
    setVisibleTemplatesDialog(false);
    handleClose();
  };

  // Constants
  const actions = [
    {
      label: 'Choose from templates',
      icon: <TemplateIcon />,
      hoverIcon: <TemplateHoverIcon />,
      handler: handleOpenTemplatesDialog,
    },
    {
      label: 'Create New',
      icon: <NewDocIcon />,
      hoverIcon: <NewDocHoverIcon />,
      handler: () => navigate(ROUTES.NEW_DOC),
    },
  ];

  // Return
  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={styles.dialog}>
        {actions.map(({ label, icon, hoverIcon, handler }, index) => (
          <>
            <Stack
              m={24}
              key={index}
              sx={styles.action}
              onMouseEnter={handleHover(index)}
              onMouseLeave={handleBlur}
              onClick={handler}
            >
              <Stack
                height={{ xs: 180, sm: 240, md: 300 }}
                justifyContent="center"
                mt={hoverId === index ? 3 : 0}
                mb={hoverId === index ? -3 : 0}
              >
                {hoverId === index ? hoverIcon : icon}
              </Stack>
              <Typography variant="title" align="center">
                {label}
              </Typography>
            </Stack>
            {index === 0 && <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />}
          </>
        ))}
      </Dialog>
      <TemplatesDialog open={visibleTemplatesDialog} onClose={handleCloseTemplatesDialog} />
    </>
  );
};
