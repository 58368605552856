export enum DisplayMode {
  List = 'list',
  Module = 'module',
}

export enum ROLE {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
}

export enum ORDER {
  ASC = 'asc',
  DESC = 'desc',
}
