import { gql } from '@apollo/client';

export const GET_PERFORMANCES = gql`
  query getPerformance($type: String!) {
    getPerformance(type: $type) {
      performances
      count
    }
  }
`;
