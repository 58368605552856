import React, { ChangeEvent, useState } from 'react';
import { ContentWidget, WidgetProps } from '../index';
import { Button, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import { PlusIcon } from '../../../../../../assets/icons';
import { WidgetListDialog } from '../../../../../../components/Dialogs/WidgetList';
import styles from './styles';
import widgetStyles from '../styles';

export const Collapse = ({ id, content, preview, themeColor, onChange }: Omit<WidgetProps, 'type'>) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChangeValue = (widgetId, newContentData) => {
    const widgets = content.widgets || [];
    onChange(id, {
      title: content.title || "",
      widgets: [...widgets.map((w) => w.id === widgetId ? { ...w, content: newContentData } : w)],
    });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleSelect = (selected) => {
    onChange(id, {
      title: content.title,
      widgets: [...(content.widgets || []), selected],
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleSetTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(id, {
      title: e.target.value,
      widgets: content.widgets || [],
    });
  };

  return (
    <>
      <Accordion sx={styles.accordion} expanded={expanded} onChange={handleToggle} disableGutters elevation={0} square>
        <AccordionSummary
          id="collapse-header"
          sx={styles.accordionHeader}
          aria-controls="collapse-content"
          expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
        >
          <Box sx={styles.titleInput}>
            <input placeholder='Title' value={content.title || ''} onChange={handleSetTitle} onClick={(e) => e.stopPropagation()} />
          </Box>
        </AccordionSummary>
        {content.widgets && content.widgets?.map((w, index) => (
          <AccordionDetails sx={styles.accordionDetails} key={index}>
            <Box sx={(theme) => widgetStyles.widget(theme, { preview })}>
              <ContentWidget {...w} themeColor={themeColor} preview={preview} onChange={handleChangeValue} />
            </Box>
          </AccordionDetails>
        ))}
        {!preview && (
          <Button variant="outlined" sx={widgetStyles.widgetButton} startIcon={<PlusIcon />} onClick={handleOpenDialog}>
            Add
          </Button>
        )}
      </Accordion>

      {openDialog && (
        <WidgetListDialog
          open={openDialog}
          onSelect={handleSelect}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
}
