// Resources
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  tabWidgetContainer: (theme) => ({
    border: `1px solid ${theme.palette.gray.light}`,
    flexGrow: 1,
    display: 'flex'
  }),
  tabWidget: () => ({
    width: 0,
    flexGrow: 1,
  }),
  tabBar: (theme, color) => ({
    background: color || '#ccd5df',
  }),
  newTabInput: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    '& input': {
      border: 0,
      padding: '0 10px',
      width: '100px',
      background: 'transparent',
      outline: 'none',
      '&:focus': {
        outline: 'none',
      }
    }
  }),
  tabContent: (theme) => ({
    padding: theme.spacing(8),
  }),
  newTabIcon: () => ({
    border: 0,
    width: 24,
    height: 24,
    boxShadow: 'none',
    background: 'transparent',
    marginRight: '4px',
  }),
  addNewTab: () => ({
    borderRadius: '0 !important'
  }),
  colorRadio: (theme, color) => ({
    color: color,
    '&.Mui-checked': {
      color: color,
    },
  }),
  isinInput: () => ({
    maxWidth: '300px'
  }),
};

// Export styles
export default styles;
