// Dependencies
// import { Theme } from '@mui/material';

// Styles
const styles = {
  resizable: () => ({
    '&:hover': {
      '.react-resizable-handle': {
        opacity: 1,
        transitionDelay: '0.2s',
        transitionDuration: '0.2s',
      },
    },
    '.react-resizable-handle': {
      opacity: 0,
    },
  }),
};

// Export styles
export default styles;
