import React, {createContext, ReactNode, useContext, useState} from 'react';
import {AlertColor} from '@mui/material';

//interface
interface IAlert {
  isAlertShow: boolean,
  type: AlertColor | undefined, //error, warning, info, success
  text: string,
  link:string,
  description: string,
  activeAlert:(data:any, callback?: () => void)=>void
  closeAlert:(type?: string)=>void
}
interface IAlertProviderProps {
  children: ReactNode;
}

const AlertContext = createContext<IAlert>({
  isAlertShow: false,
  type: 'success',
  text: '',
  description: 'Cixon',
  link:'',
  activeAlert:(alert:any, callback?: () => void)=>{console.log(alert)},
  closeAlert:(type?: string)=>{console.log("onclose")}
});

export function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);

  return [
    state,
    (value, callback) => {
      setState(value);

      if (typeof callback === "function") {
        callback(value);
      }
    }
  ];
}

export const AlertProvider = ({children}:IAlertProviderProps) => {
  const [isAlertShow, setIsAlertShow] = useState<boolean>(false);
  const [type, setType] = useState<AlertColor>('success');
  const [text, setText] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [callback, setCallback] = useStateCallback({ cb: null });

  const activeAlert = (alert, confirmCallback) => {
    setIsAlertShow(true);
    setType(alert?.type);
    setText(alert?.text);
    setLink(alert?.link);
    setDescription(
      alert?.description !== undefined ? alert.description : '',
    );
    setCallback({ cb: confirmCallback });
  };

  const closeAlert = (type?: string) => {
    if (type === 'confirmed' && callback.cb) {
      // @ts-ignore
      callback.cb();
    }
    setIsAlertShow(false);
  };

  const value  = {
    isAlertShow,
    activeAlert,
    closeAlert,
    type,
    text,
    link,
    description,
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  return useContext(AlertContext);
};
