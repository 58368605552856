import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {useAlertContext} from './alertContext';
import styles from './styles';
import {Box, Button, Link, SnackbarContent, Typography} from '@mui/material';
import {SuccessIcon, CloseCircleIcon, ErrorIcon, InfoIcon, WarningIcon} from '../../assets/icons';
import {Dialog} from '../Dialog';

const  AlertType= {
   SUCCESS : "success",
   ERROR : "error",
   INFO : "info",
   WARNING:'warning'
}

export default function CustomizedAlerts() {
  const {isAlertShow, closeAlert, type, text, description, link} = useAlertContext();

  const handleClose = (type?: string) => {
    if (closeAlert) {
      closeAlert(type);
    }
  };

  const getIcon =()=> {
    if(type === AlertType.SUCCESS){
      return <SuccessIcon height={20} width={20}/>
    }else  if(type === AlertType.ERROR){
      return <ErrorIcon  height={20} width={20}/>
    }else if(type == AlertType.INFO){
      return <InfoIcon height={20} width={20}/>
    }else if(type === AlertType.WARNING){
      return <WarningIcon height={20} width={20}/>
    }
  }

  const getColor =()=>{
    if(type === AlertType.SUCCESS){
      return '#d2ffe7'
    }else  if(type === AlertType.ERROR){
      return '#e0b3b3'
    }else if(type == AlertType.INFO){
      return '#d1daf3'
    }else if(type === AlertType.WARNING){
      return '#f6f0c3'
    }
  }
  return (
    <Dialog open={isAlertShow} onClose={handleClose} sx={styles.dialog}>
      <Typography>{ text }</Typography>
      <Typography variant="body2">{ description }</Typography>
      <Box sx={styles.action}>
        <Button variant="outlined" onClick={() => handleClose('cancel')}>Cancel</Button>
        <Button onClick={() => handleClose('confirmed')}>Confirm</Button>
      </Box>
    </Dialog>
  );
}
