// Dependencies
import React, {FC, useMemo} from 'react';
import { Box, Stack, Typography, LinearProgress, Button } from '@mui/material';
import { Viewer } from '@react-pdf-viewer/core';

// Components
import { Dialog } from '../../Dialog';

// Styles
import styles from './styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';

// Interfaces
interface IPreviewDocDialogProps {
  open: boolean;
  previewPdf: string;
  onClose: () => void;
}

// Export preview document dialog
export const PreviewDocDialog: FC<IPreviewDocDialogProps> = ({ open, previewPdf, onClose }) => {
  // Copy url to clipboard handler
  const handleCopyUrlToClipboard = () => {
    navigator.clipboard.writeText(previewPdf);
  };

  const noCacheUrl = useMemo(() => `${previewPdf}?nocache=${(new Date()).getTime()}`, [previewPdf]);

  // Return preview document dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Typography variant="title" sx={styles.dialogPadding}>
        Preview
      </Typography>
      <Box mb={20} component={PerfectScrollbar} sx={styles.scrollbar}>
        {previewPdf && (
          <Viewer
            fileUrl={noCacheUrl}
            renderLoader={(percentages: number) => {
              return (
                <div style={{ width: '100%', minHeight: '50px', padding: '0 40px' }}>
                  <LinearProgress variant="determinate" value={Math.round(percentages)}/>
                </div>
              )
            }}
          />
        )}
      </Box>
      <Stack direction="row" justifyContent="flex-end" sx={styles.dialogPadding}>
        <Button variant="varied-outlined" onClick={handleCopyUrlToClipboard}>
          Copy Url
        </Button>
      </Stack>
    </Dialog>
  );
};
