// Dependencies
import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  Typography,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { Dialog } from '../../Dialog';

// Services
import apis from '../../../apis';

// Icons
import { ArrowLeftIcon, ArrowRightIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Interfaces
import { ITemplate } from '../../../resource';
import { AppContext } from '../../../context';

interface ITemplatesDialogProps {
  open: boolean;
  onClose: () => void;
}

// Export templates dialog
export const TemplatesDialog: FC<ITemplatesDialogProps> = ({ open, onClose }) => {
  // States
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { account, fetchDocuments } = useContext(AppContext);

  // Fetch templates
  const fetchTemplates = async () => {
    const res: ITemplate[] = await apis.template.getTemplates();
    setTemplates(res);
  };

  // Click template handler
  const handleClickTemplate = (id: string) => () => {
    if (id !== selectedTemplateId) {
      setSelectedTemplateId(id);
    } else {
      setSelectedTemplateId(undefined);
    }
  };

  // Generate document
  const handleGenerateDocument = async () => {
    if (account?.id) {
      try {
        if (!isLoading) setIsLoading(true);
        await apis.pdf.generateDocument(account?.id, selectedTemplateId);
        account?.id && fetchDocuments(account.id);
        setIsLoading(false);
        onClose();
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  // On mounted
  useEffect(() => {
    (async () => {
      await fetchTemplates();
    })();
  }, []);

  // Return templates dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Backdrop open={isLoading} sx={styles.loader}>
        <CircularProgress />
      </Backdrop>

      <Typography mb={35} variant="title">
        Templates
      </Typography>
      <Box component={PerfectScrollbar} sx={styles.scrollbar}>
        <Grid container spacing={[20, 22]}>
          {templates.map(({ _id, name, thumbnail_url, updatedAt }, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box
                sx={(theme) => styles.template(theme, { active: _id === selectedTemplateId })}
                onClick={handleClickTemplate(_id)}
              >
                <Box>
                  <img src={thumbnail_url} alt={thumbnail_url} />
                </Box>
                <FormControlLabel checked={_id === selectedTemplateId} control={<Radio />} label={name} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Button variant="varied-outlined" startIcon={<ArrowLeftIcon />}>
          Back
        </Button>
        <Button
          variant="varied-outlined"
          endIcon={<ArrowRightIcon />}
          disabled={!selectedTemplateId}
          onClick={handleGenerateDocument}
        >
          Next
        </Button>
      </Stack>
    </Dialog>
  );
};
