// Dependencies
import { FC, MouseEvent, useContext, useEffect, useState } from 'react';
import { Avatar, Box, ButtonBase, Drawer, IconButton, Popover, Stack, Typography } from '@mui/material';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { KeyboardReturn, Language } from '@mui/icons-material';

// Global constants
import { ACCESS_ADMIN_TOKEN_KEY, ACCESS_TOKEN_KEY, ROUTES } from '../../../contants';

// Icons
import { HelpIcon, LogoutIcon, SettingsIcon } from '../../../assets/icons';

// Context
import { AppContext } from '../../../context';

// Resource
import { ROLE } from '../../../resource';

// Styles
import styles from './styles';

// Constants
const navlinks = {
  [ROLE.CUSTOMER]: [
    {
      label: 'Dashboard',
      icon: 'bxs-dashboard',
      path: ROUTES.DASHBOARD,
    },
    {
      label: 'Customer Documents',
      icon: 'content',
      path: ROUTES.CUSTOMER_DOCUMENT,
    },
    {
      label: 'Template',
      icon: 'template',
      path: ROUTES.TEMPLATE.LIST,
    },
    {
      label: 'ESG',
      icon: '',
      path: '/esg',
      children: [
        {
          label: 'Geschäftsfelder',
          path: ROUTES.ESG.AREAS,
        },
        {
          label: 'Geschäftspraktiken',
          path: ROUTES.ESG.PRACTICES,
        },
        {
          label: 'Documents',
          path: ROUTES.ESG.DOCUMENTS,
        }
      ]
    },
    {
      label: 'Portfolio',
      icon: '',
      path: '/portfolio',
      children: [
        {
          label: 'Regionen',
          path: ROUTES.PORTFOLIO.REGION,
        },
        {
          label: 'Branchen',
          path: ROUTES.PORTFOLIO.BRANCHEN,
        },
        {
          label: 'Marktkapitalisierung',
          path: ROUTES.PORTFOLIO.MARKET,
        }
      ]
    },
    {
      label: 'Manage Widgets',
      icon: 'doc',
      path: ROUTES.MANAGE_WIDGETS,
    },
  ],
  [ROLE.ADMIN]: [
    {
      label: 'Dashboard',
      icon: 'bxs-dashboard',
      path: ROUTES.DASHBOARD,
    },
    {
      label: 'Users',
      icon: 'user',
      path: ROUTES.USER.LIST,
    },
    {
      label: 'Customer Documents',
      icon: 'content',
      path: ROUTES.CUSTOMER_DOCUMENT,
    },
    {
      label: 'Template',
      icon: 'template',
      path: ROUTES.TEMPLATE.LIST,
    },
    {
      label: 'ESG',
      icon: '',
      path: '/esg',
      children: [
        {
          label: 'Geschäftsfelder',
          path: ROUTES.ESG.AREAS,
        },
        {
          label: 'Geschäftspraktiken',
          path: ROUTES.ESG.PRACTICES,
        },
        {
          label: 'Documents',
          path: ROUTES.ESG.DOCUMENTS,
        }
      ]
    },
    {
      label: 'Portfolio',
      icon: '',
      path: '/portfolio',
      children: [
        {
          label: 'Regionen',
          path: ROUTES.PORTFOLIO.REGION,
        },
        {
          label: 'Branchen',
          path: ROUTES.PORTFOLIO.BRANCHEN,
        },
        {
          label: 'Marktkapitalisierung',
          path: ROUTES.PORTFOLIO.MARKET,
        }
      ]
    },
    {
      label: 'Manage Widgets',
      icon: 'doc',
      path: ROUTES.MANAGE_WIDGETS,
    },
  ],
};

const SubMenuItem = ({
  id,
  path,
  label,
  menuItems,
  handleHover,
  handleBlur,
  getIconCom,
}) => {
  const { setAccount, account, getMe } = useContext(AppContext);
  const [esgAnchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  // Get pathname from hook
  const { pathname } = useLocation();

  // Open popover handler
  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Get navigate from hook
  const navigate = useNavigate();

  // Close handler
  const handleClose = () => {
    setAnchorEl(null);
  };

  return account?.role ? (
    <>
      <Box
        className={ pathname.includes(path) ? 'active' : '' }
        sx={styles.navlink}
        onMouseEnter={handleHover(navlinks[account.role].length)}
        onMouseLeave={handleBlur}
        onClick={handleOpen}
      >
        <Box sx={styles.icon}>
          {getIconCom({ id: id, path: path, Mui: Language })}
        </Box>
        <Typography variant="label1" textAlign="center">
          {label}
        </Typography>
      </Box>
      {esgAnchorEl && (
        <Popover
          anchorEl={esgAnchorEl}
          open={Boolean(esgAnchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleClose}
          sx={styles.esgPopover}
        >
          <Box sx={styles.card}>
            <Stack>
              {menuItems.map(({ label, path }, index) => (
                <ButtonBase className={ pathname === path ? 'active' : '' } key={index} sx={styles.menuitem} onClick={() => { navigate(path) }}>
                  {label}
                </ButtonBase>
              ))}
            </Stack>
          </Box>
        </Popover>
      )}
    </>
  ) : <></>
};

// Create sidebar
const Sidebar: FC = () => {
  // States
  const [hoverId, setHoverId] = useState<number>();

  // Context
  const { setAccount, account, getMe } = useContext(AppContext);

  // Anchor
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [adminLoginAsMode, setAdminLoginAsMode] = useState<boolean>(false);

  // Get pathname from hook
  const { pathname } = useLocation();

  // Get navigate from hook
  const navigate = useNavigate();

  // On mounted
  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (account && account.role !== ROLE.ADMIN) {
      const adminToken = localStorage.getItem(ACCESS_ADMIN_TOKEN_KEY);
      if (adminToken) {
        setAdminLoginAsMode(true);
        return;
      }
    }
    setAdminLoginAsMode(false);
  }, [account]);

  // Hover handler
  const handleHover = (id: number) => () => {
    setHoverId(id);
  };

  // Blur handler
  const handleBlur = () => {
    setHoverId(undefined);
  };

  // Open popover handler
  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close handler
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Logout handler
  const handleLogout = () => {
    setAccount(null);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ACCESS_ADMIN_TOKEN_KEY);
  };

  // Back to admin dashboard
  const handleBackToAdmin = () => {
    const adminToken = localStorage.getItem(ACCESS_ADMIN_TOKEN_KEY);
    if (adminToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, adminToken);
      getMe();
    }
  };

  const getIconCom = (data) => {
    const { id, icon, path, Mui } = data;

    if (Mui) {
      if (pathname.includes(path)) {
        return <Mui sx={{ fill: '#BF0A76' }} />;
      } else if (id === hoverId) {
        return <Mui />;
      } else {
        return <Mui sx={{ fill: '#808080' }} />;
      }
    }

    return <img src={'/icons/' + getIcon(id, icon, path) + '.svg'} alt={icon} />;
  };

  // Get source
  const getIcon = (id: number, icon: string, path: string) => {
    if (pathname.includes(path)) {
      return icon + '-active';
    } else if (id === hoverId) {
      return icon + '-hover';
    } else {
      return icon;
    }
  };

  // Define profile
  const profile = [
    {
      label: 'Help',
      icon: <HelpIcon />,
    },
    {
      label: 'Setting',
      icon: <SettingsIcon />,
    },
    {
      label: 'Log Out',
      icon: <LogoutIcon />,
      handler: handleLogout,
    },
  ];

  // generate String avatar
  function stringAvatar() {
    return {
      children: `${account?.firstName ? account?.firstName.charAt(0).toUpperCase() : 'D'}${
        account?.lastName ? account?.lastName.charAt(0).toUpperCase() : 'P'
      }`,
    };
  }

  // Return sidebar
  return (
    <>
      <Box sx={styles.sidebar}>
        <Box sx={styles.logo} component={Link} to={ROUTES.DASHBOARD}>
          <img src="/logo.png" alt="logo" />
        </Box>
        {account?.role &&
          navlinks[account.role].map(({ label, path, icon, children }, index) => !children ? (
            <Box
              to={path}
              key={index}
              component={NavLink}
              sx={styles.navlink}
              onMouseEnter={handleHover(index)}
              onMouseLeave={handleBlur}
            >
              <Box sx={styles.icon}>
                {getIconCom({ id: index, icon, path })}
              </Box>
              <Typography variant="label1" textAlign="center">
                {label}
              </Typography>
            </Box>
        ) : (
          <SubMenuItem
            id={index}
            key={index}
            getIconCom={getIconCom}
            handleBlur={handleBlur}
            handleHover={handleHover}
            path={path}
            label={label}
            menuItems={children}
          />
        ))}
        {adminLoginAsMode && (
          <IconButton color="secondary" sx={styles.backToAdmin} onClick={handleBackToAdmin}>
            <KeyboardReturn />
          </IconButton>
        )}
        <Avatar {...stringAvatar()} src={account?.avatar} sx={styles.profile} onClick={handleOpen} />
      </Box>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handleClose}
          sx={styles.popover}
        >
          <Box sx={styles.card}>
            <Stack direction="row" alignItems="center" spacing={16} sx={styles.cardHeader}>
              <Avatar {...stringAvatar()} src={account?.avatar} />
              <Stack spacing={14}>
                <Typography variant="title">
                  {account?.firstName} {account?.lastName}
                </Typography>
                <Typography
                  variant="subtitle"
                  color="secondary"
                  component={Link}
                  to={ROUTES.PROFILE}
                  onClick={handleClose}
                >
                  Edit Profile
                </Typography>
              </Stack>
            </Stack>
            <Box sx={styles.divider} />
            <Stack>
              {profile.map(({ label, icon, handler }, index) => (
                <ButtonBase key={index} sx={styles.menuitem} onClick={handler}>
                  {icon}
                  {label}
                </ButtonBase>
              ))}
            </Stack>
          </Box>
        </Popover>
      )}
    </>
  );
};

// Export sidebar
export default Sidebar;
