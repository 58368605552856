// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  container: (theme) => ({
    p: { xs: theme.spacing(80, 20, 30), sm: theme.spacing(120, 80, 40), md: theme.spacing(180, 140, 63) },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: 'url(/images/login-bg.png)',
    backgroundPosition: theme.spacing(-230, -742),
    backgroundColor: theme.palette.gray.light,
    backgroundBlendMode: 'overlay',
  }),
  actions: () => ({
    width: '100%',
    maxWidth: 420,
  }),
  landLogo: () => ({
    textAlign: 'center',
    display: { xs: 'none', lg: 'block' },
    img: {
      borderTopLeftRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  }),
};

// Export styles
export default styles;
