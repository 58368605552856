// Resources
import { IStyles } from '../../../../../../resource';

// Define styles
const styles: IStyles = {
  richTextBox: (theme) => ({
    padding: '10px',
    minHeight: '100px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    marginBottom: '16px'
  })
};

// Export styles
export default styles;
