// Interfaces

// Styles
import { IStyles } from '../../../resource';

const styles: IStyles = {
  paper: (theme) => ({
    p: theme.spacing(20),
  }),
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: theme.spacing(10, 16),
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    flexDirection: 'column',
  }),
  esgImage: (theme) => ({
    overflow: 'hidden',
    border: 'none',
    width: '54px',
    height: '54px',
    '&:hover': {
      background: 'white',
      border: `1px solid ${theme.palette.secondary.main}`
    },
    img: {
      width: '60px',
      height: '60px',
      objectFit: 'contain'
    }
  }),
};

// Export styles
export default styles;
