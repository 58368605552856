// Dependencies
import React, { FC } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';

// Component
import { Dialog } from '../../../../components/Dialog'

// Styles
import styles from '../../styles';

// Interfaces
interface IObjectEditModalProps {
  title: string;
  open: boolean;
  data: object;
  loading: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export ObjectEdit Modal
export const ObjectEditModal: FC<IObjectEditModalProps> = ({ title, open, data, loading, onClose, onSave }) => {
  // Create form form hook
  const { values, handleChange, submitForm } = useFormik({
    initialValues: data,
    onSubmit: (values) => onSave(values),
  });

  // Return ObjectEdit Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>{title}</DialogTitle>
      <DialogContent>
        <Grid pt={20} container columns={2} spacing={[10, 20]}>
          {Object.entries(data).map(([key], index) => (
            <Grid item xs={1} key={index}>
              {/* @ts-ignore */}
              <TextField fullWidth name={key} label={key.toUpperCase()} value={values[key]} onChange={handleChange} />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={submitForm}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
};
