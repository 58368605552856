// Dependencies
import React, { FC, ReactNode, useState } from 'react';
import { Resizable as RC } from 'react-resizable';
import { Box } from '@mui/material';

const ResizableComponent: any = RC;

// Styles
import styles from './styles';
import 'react-resizable/css/styles.css';

// Interfaces
export interface IResizableProps {
  width?: number | string;
  height?: number;
  onResize?: (size: { width: number; height: number }) => void;
  children?: ReactNode;
}

// Export Resizable component
export const Resizable: FC<IResizableProps> = ({
  width: originalWidth = 200,
  height: originalHeight = 100,
  onResize,
  children,
}) => {
  if (typeof originalWidth === 'string' && originalWidth.endsWith('%')) {
    originalWidth = (+originalWidth.slice(0, -1) / 100) * window.innerWidth;
  }

  // States
  const [width, setWidth] = useState(originalWidth);
  const [height, setHeight] = useState(originalHeight);

  // Resize handler
  const handleResize = (_: unknown, { size }: { size: { width: number; height: number } }) => {
    setWidth(size.width);
    setHeight(size.height);
    if (onResize) {
      onResize(size);
    }
  };

  // Return Resizable component
  // @ts-ignore
  return (
    // @ts-ignore
    <ResizableComponent width={width} height={height} onResize={handleResize} minConstraints={[180, 70]}>
      <Box sx={styles.resizable} style={{ width, height }}>
        {children}
      </Box>
    </ResizableComponent>
  );
};
