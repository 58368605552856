// Dependencies
import { FC } from 'react';
import { Box, Typography } from '@mui/material';

// Components
import { Dialog } from '../../Dialog';

// Icons
import { SuccessIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

interface IDoneDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  next: string;
  onNext: () => void;
}

// Export publish dialog
export const DoneDialog: FC<IDoneDialogProps> = ({ open, title, description, next, onNext, onClose }) => {
  // Return publish dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.icon}>
        <SuccessIcon />
      </Box>
      <Typography mb={17} variant="headline">
        {title}
      </Typography>
      <Typography mb={23} variant="body2" color="gray.dark">
        {description}
      </Typography>
      <Typography color="success.main" variant="button" textTransform="none" onClick={onNext}>
        {next} &gt;
      </Typography>
    </Dialog>
  );
};
