import http from './http';
import {IRegister} from '../resource';

export const login = (email: string, password: string) => {
  return http.post('/auth/login', { email, password });
};

export const adminLoginAsUser = (id: string) => {
  return http.post('/auth/admin-loginAs', { id });
};

export const register = (params: IRegister) => {
  return http.post('/auth/register', params);
};

export const forgotPassword = (email: string) => {
  return http.post('/auth/forgot-password', { email });
};

export const me = () => {
  return http.get('/auth/me');
};

export const resetPassword = (id:string, currentPassword:string, password: string) => {
  return http.post('/auth/reset-password', {id,currentPassword,password});
};

export const verifyToken = (type, data) => {
  return http.post(`/auth/verify/${type}`, data);
};

export const editProfile = (data: any) => {
  const form: any = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    form.append(key, value);
  });

  return http.post('/auth/edit-profile', form);
};
