// Resources
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  container: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  template: (theme) => ({
    cursor: 'pointer',
    height: 322,
    overflow: 'hidden',
    width: 'calc(100% - 4px)',
    p: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(12, 0),
    ':hover': {
      p: 0,
      borderWidth: 2,
      boxShadow: theme.shadows[1],
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    img: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(20, 0),
    },
  }),
};

// Export styles
export default styles;
