// Resources
import { IStyles } from '../../../../../../resource';
import { alpha } from '@mui/material/styles';

// Define styles
const styles: IStyles = {
  title: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    mb: '8px',
    '& input': {
      p: '12px 10px',
      fontSize: '14px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 1,
      '&:focus': {
        outline: `1px solid ${theme.palette.secondary.main}`,
      },
    }
  }),
  description: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& textarea': {
      p: '16px 10px',
      fontSize: '14px',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 1,
      '&:focus': {
        outline: `1px solid ${theme.palette.secondary.main}`,
      },
    }
  }),
  colorRadio: (theme, color) => ({
    color: color,
    '&.Mui-checked': {
      color: color,
    },
  }),
  chartTypeSelect: () => ({
    width: '300px',
    marginLeft: '10px'
  }),
  axisSwitch: (theme, isPercent) => ({
    ml: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiSwitch-switchBase': {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTypography-root': {
      fontWeight: 900,
      cursor: 'pointer',
      '&:first-child': {
        opacity: isPercent ? 0.5 : 1,
      },
      '&:last-child': {
        opacity: isPercent ? 1 : 0.5,
      },
    }
  }),
  additionalIsinPanel: (theme) => ({
    border: `1px solid ${theme.palette.grey["300"]}`,
    borderRadius: '5px',
    padding: '16px 8px',
    marginTop: '16px',
  }),
  colorPicker: (theme) => ({
    '.react-colorful': {
      height: '240px'
    },
    '.react-colorful__saturation': {
      borderRadius: '4px 4px 0 0',
    },
    '.react-colorful__hue': {
      height: '40px',
      borderRadius: '0 0 4px 4px',
    },
    '.react-colorful__hue-pointer': {
      width: '12px',
      height: 'inherit',
      borderRadius: 0,
    }
  }),
};

// Export styles
export default styles;
