// Dependencies
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// App
import App from './App';

// Styles
import './index.css';
import { PageType } from './contants';

if (process.env.NODE_ENV !== 'dev') {
  Sentry.init({
    dsn: "https://521bfedb4bf24d478650a1cdfe83b98d@o4504017382146048.ingest.sentry.io/4504886437543936",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// Create root
const root = ReactDOM.createRoot(document.getElementById('cdn-root') as HTMLElement);

const apiKey = document.getElementById('cdn-root')?.getAttribute('apiKey') || '';
const page = document.getElementById('cdn-root')?.getAttribute('page') || '';

// Render app
root.render(<App apiKey={apiKey} page={page as PageType} />);
