import http from './http';

export const saveCdnWidget = async (customerId: string, data: any) => {
  return await http.post(`cdn/${customerId}`, data);
};

export const updateCdnWidget = async (widgetId: string, data: any, customerId?: string) => {
  return await http.put(`cdn/${customerId ? customerId + '/' : ''}${widgetId}`, data);
};

export const searchCdnWidget = async (params?: any) => {
  return await http.get(`cdn`, params);
};

export const getCdnWidget = async (customerId: string) => {
  return await http.get(`cdn/${customerId}`);
};

export const getCdnWidgetByWidgetId = async (widgetId: string, customerId?: string) => {
  return await http.get(`cdn/widgets/${customerId ? customerId + '/' : ''}${widgetId}`);
};

export const removeCdnWidgetByWidgetId = async (widgetId: string, customerId?: string) => {
  return await http.delete(`cdn/widgets/${customerId ? customerId + '/' : ''}${widgetId}`);
};

export const duplicateCdnWidget = async (widgetId: string, payload: any) => {
  return await http.post(`cdn/widgets/${widgetId}`, payload);
};

export const uploadImage = async (file) => {
  const form: any = new FormData();
  form.append('image', file);
  return await http.post(`cdn/widgets/upload`, form);
};

export const getHistoryChartData = async (type, isin, mode) => {
  return await http.get(`cdn/widgets/history?type=${type}&isin=${isin}&mode=${mode}`);
};

export const getCdnWidgetByIsin = async (isin: string) => {
  return await http.get(`cdn/widgets/isin/${isin}`);
};
