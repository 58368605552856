// Dependencies
import { Theme } from '@mui/material';

// Styles
const styles = {
  label: (theme: Theme) => ({
    p: 0,
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    border: '1px dashed transparent',
    borderRadius: 1,
    textDecoration: 'underline',
    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.common.black,
    },
  }),
};

// Export styles
export default styles;
