import { IStyles } from '../../resource';

const styles: IStyles = {
  container: (theme, params) => ({
    position: 'relative',
    overflow: 'hidden',
    borderRadius: { xs: 2.25, md: 7.5 },
    height: params?.direction === 'vertical' ? 372 : { xs: 36, md: 48 },
    mb: params?.direction === 'vertical' ? theme.spacing(-324) : 0,
    width: params?.direction === 'vertical' ? { xs: 36, md: 48 } : 372,
    'flexShrink': 0,
    '> .MuiIconButton-root': {
      boxShadow: 'none',
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }),
  actions: (theme, params) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: params?.visible ? params.archived ? '110px' : '-50px' : '10px',
    transform: params?.visible
      ? params?.direction === 'horizontal'
        ? 'translateX(100)'
        : 'translateY(0%)'
      : params?.direction === 'horizontal'
      ? 'translateX(100%)'
      : 'translateY(-100%)',
    transitionDuration: '500ms',
    justifyContent: 'flex-end',
    mr:
      params?.direction === 'horizontal'
        ? { xs: theme.spacing(params?.visible ? 44 : 0), md: theme.spacing(params?.visible ? 54 : 0) }
        : 0,
    mb:
      params?.direction === 'vertical'
        ? { xs: theme.spacing(params?.visible ? 44 : 0), md: theme.spacing(params?.visible ? 54 : 0) }
        : 0,
  }),
  button: (theme) => ({
    boxShadow: 'none',
    ':hover': {
      borderColor: theme.palette.common.white,
    },
  }),
  previewDialog: () => ({
    '.MuiPaper-root': {
      width: '100%',
    },
  }),
  pdfContent: () => ({
    '.rpv-core__inner-pages': {
      overflow: 'hidden !important',
    },
  }),
};

export default styles;
