import React, { useRef, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const data = [
  {
    "date": "2017-09-01",
    "value": 1000
  },
  {
    "date": "2017-09-04",
    "value": 1000
  },
  {
    "date": "2017-09-05",
    "value": 998.7
  },
  {
    "date": "2017-09-06",
    "value": 999.1
  },
  {
    "date": "2017-09-07",
    "value": 999
  },
  {
    "date": "2017-09-08",
    "value": 998.9
  },
  {
    "date": "2017-09-11",
    "value": 999.7
  },
  {
    "date": "2017-09-12",
    "value": 1002.6
  },
  {
    "date": "2017-09-13",
    "value": 1004.5
  },
  {
    "date": "2017-09-14",
    "value": 1006.2
  },
  {
    "date": "2017-09-15",
    "value": 1004.5
  },
  {
    "date": "2017-09-18",
    "value": 1006.1
  },
  {
    "date": "2017-09-19",
    "value": 1007.4
  },
  {
    "date": "2017-09-20",
    "value": 1007.7
  },
  {
    "date": "2017-09-21",
    "value": 1009.1
  },
  {
    "date": "2017-09-22",
    "value": 1008.2
  },
  {
    "date": "2017-09-25",
    "value": 1009.4
  },
  {
    "date": "2017-09-26",
    "value": 1011.2
  },
  {
    "date": "2017-09-27",
    "value": 1013.4
  },
  {
    "date": "2017-09-28",
    "value": 1015
  },
  {
    "date": "2017-09-29",
    "value": 1015.3
  },
  {
    "date": "2017-10-02",
    "value": 1015.9
  },
  {
    "date": "2017-10-04",
    "value": 1015.6
  },
  {
    "date": "2017-10-05",
    "value": 1014.8
  },
  {
    "date": "2017-10-06",
    "value": 1014.9
  },
  {
    "date": "2017-10-09",
    "value": 1013.5
  },
  {
    "date": "2017-10-10",
    "value": 1012.4
  },
  {
    "date": "2017-10-11",
    "value": 1012
  },
  {
    "date": "2017-10-12",
    "value": 1012
  },
  {
    "date": "2017-10-13",
    "value": 1012.8
  },
  {
    "date": "2017-10-16",
    "value": 1013.6
  },
  {
    "date": "2017-10-17",
    "value": 1013.7
  },
  {
    "date": "2017-10-18",
    "value": 1013.9
  },
  {
    "date": "2017-10-19",
    "value": 1008.3
  },
  {
    "date": "2017-10-20",
    "value": 1008.6
  },
  {
    "date": "2017-10-23",
    "value": 1010.5
  },
  {
    "date": "2017-10-24",
    "value": 1009.1
  },
  {
    "date": "2017-10-25",
    "value": 1007.7
  },
  {
    "date": "2017-10-26",
    "value": 1007.3
  },
  {
    "date": "2017-10-27",
    "value": 1008
  },
  {
    "date": "2017-10-30",
    "value": 1010
  },
  {
    "date": "2017-11-02",
    "value": 1008.2
  },
  {
    "date": "2017-11-03",
    "value": 1008.6
  },
  {
    "date": "2017-11-06",
    "value": 1007.7
  },
  {
    "date": "2017-11-07",
    "value": 1009
  },
  {
    "date": "2017-11-08",
    "value": 1008.6
  },
  {
    "date": "2017-11-09",
    "value": 1007.1
  },
  {
    "date": "2017-11-10",
    "value": 1008.9
  },
  {
    "date": "2017-11-13",
    "value": 1007.7
  },
  {
    "date": "2017-11-14",
    "value": 1004.8
  },
  {
    "date": "2017-11-15",
    "value": 1000.1
  },
  {
    "date": "2017-11-16",
    "value": 1003.8
  },
  {
    "date": "2017-11-17",
    "value": 1003.4
  },
  {
    "date": "2017-11-20",
    "value": 1006.9
  },
  {
    "date": "2017-11-21",
    "value": 1007.7
  },
  {
    "date": "2017-11-22",
    "value": 1009.2
  },
  {
    "date": "2017-11-23",
    "value": 1008.3
  },
  {
    "date": "2017-11-24",
    "value": 1006.7
  },
  {
    "date": "2017-11-27",
    "value": 1006.5
  },
  {
    "date": "2017-11-28",
    "value": 1010.5
  },
  {
    "date": "2017-11-29",
    "value": 1012.8
  },
  {
    "date": "2017-11-30",
    "value": 1013.7
  },
  {
    "date": "2017-12-01",
    "value": 1012.6
  },
  {
    "date": "2017-12-04",
    "value": 1012.7
  },
  {
    "date": "2017-12-05",
    "value": 1013.5
  },
  {
    "date": "2017-12-06",
    "value": 1014.6
  },
  {
    "date": "2017-12-07",
    "value": 1015
  },
  {
    "date": "2017-12-08",
    "value": 1017.2
  },
  {
    "date": "2017-12-11",
    "value": 1017
  },
  {
    "date": "2017-12-12",
    "value": 1020.6
  },
  {
    "date": "2017-12-13",
    "value": 1019.3
  },
  {
    "date": "2017-12-14",
    "value": 1017.6
  },
  {
    "date": "2017-12-15",
    "value": 1017.1
  },
  {
    "date": "2017-12-18",
    "value": 1015.8
  },
  {
    "date": "2017-12-19",
    "value": 1016.6
  },
  {
    "date": "2017-12-20",
    "value": 1017.9
  },
  {
    "date": "2017-12-21",
    "value": 1017.6
  },
  {
    "date": "2017-12-22",
    "value": 1020.3
  },
  {
    "date": "2017-12-27",
    "value": 1021.9
  },
  {
    "date": "2017-12-28",
    "value": 1025.2
  },
  {
    "date": "2017-12-29",
    "value": 1024.3
  },
  {
    "date": "2018-01-02",
    "value": 1025.8
  },
  {
    "date": "2018-01-03",
    "value": 1029.6004
  },
  {
    "date": "2018-01-04",
    "value": 1030.8005
  },
  {
    "date": "2018-01-05",
    "value": 1033.7008
  },
  {
    "date": "2018-01-08",
    "value": 1035.801
  },
  {
    "date": "2018-01-09",
    "value": 1039.0013
  },
  {
    "date": "2018-01-10",
    "value": 1038.5012
  },
  {
    "date": "2018-01-11",
    "value": 1035.5009
  },
  {
    "date": "2018-01-12",
    "value": 1038.3012
  },
  {
    "date": "2018-01-15",
    "value": 1036.201
  },
  {
    "date": "2018-01-16",
    "value": 1038.3012
  },
  {
    "date": "2018-01-17",
    "value": 1037.5011
  },
  {
    "date": "2018-01-18",
    "value": 1038.3012
  },
  {
    "date": "2018-01-19",
    "value": 1038.4012
  },
  {
    "date": "2018-01-22",
    "value": 1042.1016
  },
  {
    "date": "2018-01-23",
    "value": 1042.4016
  },
  {
    "date": "2018-01-24",
    "value": 1040.6014
  },
  {
    "date": "2018-01-25",
    "value": 1039.4013
  },
  {
    "date": "2018-01-26",
    "value": 1041.6015
  },
  {
    "date": "2018-01-29",
    "value": 1040.1014
  },
  {
    "date": "2018-01-30",
    "value": 1036.101
  },
  {
    "date": "2018-01-31",
    "value": 1035.2009
  },
  {
    "date": "2018-02-01",
    "value": 1039.0013
  },
  {
    "date": "2018-02-02",
    "value": 1037.1011
  },
  {
    "date": "2018-02-05",
    "value": 1032.2006
  },
  {
    "date": "2018-02-06",
    "value": 1027.4002
  },
  {
    "date": "2018-02-07",
    "value": 1036.8011
  },
  {
    "date": "2018-02-08",
    "value": 1033.7008
  },
  {
    "date": "2018-02-09",
    "value": 1028.7003
  },
  {
    "date": "2018-02-12",
    "value": 1028.9003
  },
  {
    "date": "2018-02-13",
    "value": 1030.7005
  },
  {
    "date": "2018-02-14",
    "value": 1033.1007
  },
  {
    "date": "2018-02-15",
    "value": 1038.6012
  },
  {
    "date": "2018-02-16",
    "value": 1041.0015
  },
  {
    "date": "2018-02-19",
    "value": 1041.6015
  },
  {
    "date": "2018-02-20",
    "value": 1039.4013
  },
  {
    "date": "2018-02-21",
    "value": 1041.6015
  },
  {
    "date": "2018-02-22",
    "value": 1037.8012
  },
  {
    "date": "2018-02-23",
    "value": 1042.4016
  },
  {
    "date": "2018-02-26",
    "value": 1043.2017
  },
  {
    "date": "2018-02-27",
    "value": 1045.5019
  },
  {
    "date": "2018-02-28",
    "value": 1044.4018
  },
  {
    "date": "2018-03-01",
    "value": 1042.4016
  },
  {
    "date": "2018-03-02",
    "value": 1035.2009
  },
  {
    "date": "2018-03-05",
    "value": 1037.1011
  },
  {
    "date": "2018-03-06",
    "value": 1039.8014
  },
  {
    "date": "2018-03-07",
    "value": 1036.301
  },
  {
    "date": "2018-03-08",
    "value": 1037.9012
  },
  {
    "date": "2018-03-09",
    "value": 1040.9015
  },
  {
    "date": "2018-03-12",
    "value": 1044.4018
  },
  {
    "date": "2018-03-13",
    "value": 1045.7019
  },
  {
    "date": "2018-03-14",
    "value": 1043.5017
  },
  {
    "date": "2018-03-15",
    "value": 1040.4014
  },
  {
    "date": "2018-03-16",
    "value": 1042.3016
  },
  {
    "date": "2018-03-19",
    "value": 1043.1017
  },
  {
    "date": "2018-03-20",
    "value": 1041.3015
  },
  {
    "date": "2018-03-21",
    "value": 1043.6017
  },
  {
    "date": "2018-03-22",
    "value": 1037.7012
  },
  {
    "date": "2018-03-23",
    "value": 1036.501
  },
  {
    "date": "2018-03-26",
    "value": 1034.7009
  },
  {
    "date": "2018-03-27",
    "value": 1036.101
  },
  {
    "date": "2018-03-28",
    "value": 1034.5008
  },
  {
    "date": "2018-03-29",
    "value": 1034.7009
  },
  {
    "date": "2018-04-03",
    "value": 1034.9009
  },
  {
    "date": "2018-04-04",
    "value": 1032.0722
  },
  {
    "date": "2018-04-05",
    "value": 1035.305
  },
  {
    "date": "2018-04-06",
    "value": 1035.8101
  },
  {
    "date": "2018-04-09",
    "value": 1036.3152
  },
  {
    "date": "2018-04-10",
    "value": 1040.1542
  },
  {
    "date": "2018-04-11",
    "value": 1039.447
  },
  {
    "date": "2018-04-12",
    "value": 1039.7501
  },
  {
    "date": "2018-04-13",
    "value": 1041.3665
  },
  {
    "date": "2018-04-16",
    "value": 1041.2655
  },
  {
    "date": "2018-04-17",
    "value": 1040.7603
  },
  {
    "date": "2018-04-18",
    "value": 1042.0737
  },
  {
    "date": "2018-04-19",
    "value": 1043.387
  },
  {
    "date": "2018-04-20",
    "value": 1041.3665
  },
  {
    "date": "2018-04-23",
    "value": 1041.5685
  },
  {
    "date": "2018-04-24",
    "value": 1044.1952
  },
  {
    "date": "2018-04-25",
    "value": 1041.4675
  },
  {
    "date": "2018-04-26",
    "value": 1042.1747
  },
  {
    "date": "2018-04-27",
    "value": 1042.5788
  },
  {
    "date": "2018-04-30",
    "value": 1042.4778
  },
  {
    "date": "2018-05-02",
    "value": 1045.1044
  },
  {
    "date": "2018-05-03",
    "value": 1042.3767
  },
  {
    "date": "2018-05-04",
    "value": 1044.4983
  },
  {
    "date": "2018-05-07",
    "value": 1045.7106
  },
  {
    "date": "2018-05-08",
    "value": 1047.7311
  },
  {
    "date": "2018-05-09",
    "value": 1045.4075
  },
  {
    "date": "2018-05-11",
    "value": 1045.8116
  },
  {
    "date": "2018-05-14",
    "value": 1047.327
  },
  {
    "date": "2018-05-15",
    "value": 1047.1249
  },
  {
    "date": "2018-05-16",
    "value": 1046.6198
  },
  {
    "date": "2018-05-17",
    "value": 1047.63
  },
  {
    "date": "2018-05-18",
    "value": 1045.4075
  },
  {
    "date": "2018-05-22",
    "value": 1046.1147
  },
  {
    "date": "2018-05-23",
    "value": 1044.8013
  },
  {
    "date": "2018-05-24",
    "value": 1045.7106
  },
  {
    "date": "2018-05-25",
    "value": 1047.0239
  },
  {
    "date": "2018-05-28",
    "value": 1048.5393
  },
  {
    "date": "2018-05-29",
    "value": 1049.0444
  },
  {
    "date": "2018-05-30",
    "value": 1048.8423
  },
  {
    "date": "2018-06-01",
    "value": 1045.7106
  },
  {
    "date": "2018-06-04",
    "value": 1044.4983
  },
  {
    "date": "2018-06-05",
    "value": 1042.8819
  },
  {
    "date": "2018-06-06",
    "value": 1043.1849
  },
  {
    "date": "2018-06-07",
    "value": 1043.488
  },
  {
    "date": "2018-06-08",
    "value": 1044.3972
  },
  {
    "date": "2018-06-11",
    "value": 1042.1747
  },
  {
    "date": "2018-06-12",
    "value": 1041.5685
  },
  {
    "date": "2018-06-13",
    "value": 1039.8511
  },
  {
    "date": "2018-06-14",
    "value": 1038.1337
  },
  {
    "date": "2018-06-15",
    "value": 1036.2142
  },
  {
    "date": "2018-06-18",
    "value": 1038.3357
  },
  {
    "date": "2018-06-19",
    "value": 1039.1439
  },
  {
    "date": "2018-06-20",
    "value": 1040.7603
  },
  {
    "date": "2018-06-21",
    "value": 1043.387
  },
  {
    "date": "2018-06-22",
    "value": 1043.286
  },
  {
    "date": "2018-06-25",
    "value": 1045.0034
  },
  {
    "date": "2018-06-26",
    "value": 1044.2962
  },
  {
    "date": "2018-06-27",
    "value": 1042.0737
  }
];

function getDifference(data) {
  const firstItem = data[0]?.timestamp || 0;
  const secondItem = data[1]?.timestamp || 0;
  const diff = secondItem - firstItem;
  return diff > 10000 ? diff : 10000;
}

export const AmChart = () => {
  const id = 'chartdiv';
  const chart = useRef(null);
  const root = useRef(null);

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    const r = am5.Root.new("chartdiv");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    r.setThemes([am5themes_Animated.new(r)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const x = r.container.children.push(
      am5xy.XYChart.new(r, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX"
      })
    );

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = x.xAxes.push(
      am5xy.DateAxis.new(r, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(r, {
          minGridDistance: 50
        }),
        tooltip: am5.Tooltip.new(r, {})
      })
    );

    const yAxis = x.yAxes.push(
      am5xy.ValueAxis.new(r, {
        maxDeviation: 0.1,
        renderer: am5xy.AxisRendererY.new(r, {})
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = x.series.push(
      am5xy.LineSeries.new(r, {
        minBulletDistance: 10,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date"
      })
    );

    // Set up data processor to parse string dates
    // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
    series.data.processor = am5.DataProcessor.new(r, {
      dateFormat: "yyyy-MM-dd",
      dateFields: ["date"]
    });

    series.data.setAll(data);

    const tooltip = am5.Tooltip.new(r, {
      pointerOrientation: "horizontal"
    });
    tooltip.label.set("text", "{valueY}");
    series.set("tooltip", tooltip);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = x.set(
      "cursor",
      am5xy.XYCursor.new(r, {
        xAxis: xAxis
      })
    );
    cursor.lineY.set("visible", false);

    //xadd scrollbar
    x.set(
      "scrollbarX",
      am5.Scrollbar.new(r, {
        orientation: "horizontal"
      })
    );

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000, 100);
    x.appear(1000, 100);

    // @ts-ignore
    chart.current = x;
    // @ts-ignore
    root.current = r;
    return () => {
      r.dispose();
    };
  }, []);

  return <div id={id} style={{ width: '100%', height: '300px' }} />;
}
