// Styles
const styles = {
  container: () => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  card: () => ({
    height: '100%',
  }),
  cardContent: () => ({
    height: '100%',
  }),
  generateButton: () => ({
    width: 200,
    marginBottom: 8,
    marginLeft: 'auto',
    borderRadius: '4px',
  }),
};

// Export styles
export default styles;
