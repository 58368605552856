import { Box, Popover } from '@mui/material';
import styles from './styles';
import { HexColorPicker } from "react-colorful";
import React, {MouseEvent, useState} from 'react';

export const ColorPicker = ({ color, onChange }: any) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        sx={(theme) => styles.circle(theme, { color })}
        onClick={handleOpen}
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        sx={styles.popover}
      >
        <Box sx={{ height: '201px' }}>
          <HexColorPicker color={color} onChange={onChange} />
        </Box>
      </Popover>
    </Box>
  );
};
