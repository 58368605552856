// Dependencies
import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import { Dialog } from '../../Dialog';

// Apis
import apis from '../../../apis';

// Icons
import { DocFilledIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Context
import { AppContext } from '../../../context';
import { useToastContext } from '../../Toast/toastContext';

// Interfaces
import { IAccount, IEsgAreaDocument, ORDER } from '../../../resource';

interface IField {
  name: string;
  label: string;
  type?: 'text' | 'number';
  size?: 1 | 2;
}

interface IEsgAreaDlgProps {
  customer: IAccount | null;
  open: boolean;
  document?: IEsgAreaDocument;
  onClose: () => void;
}

// Constants
const initialValues = {
  exclusionCriteria: '',
  noSales: 0,
  sales_value_less_5: 0,
  sales_value_more_5: 0,
};

const fields: IField[] = [
  {
    name: 'exclusionCriteria',
    label: 'Ausschlusskriterien',
    type: 'text'
  },
  {
    name: 'noSales',
    label: 'Kein Umsatz im Geschäftsfeld',
    type: 'number'
  },
  {
    name: 'sales_value_less_5',
    label: 'Umsatz <5%',
    type: 'number'
  },
  {
    name: 'sales_value_more_5',
    label: 'Umsatz >5%',
    type: 'number'
  },
];

// Validation schema
const validationSchema = Yup.object().shape({
  exclusionCriteria: Yup.string(),
  noSales: Yup.string(),
  sales_value_less_5: Yup.string(),
  sales_value_more_5: Yup.string(),
});

// Export ESG Geschäftsfelder doc dialog
export const EsgAreaDialog: FC<IEsgAreaDlgProps> = ({ customer, open, document, onClose }) => {
  // Get toast from hook
  const { activeToast } = useToastContext();

  // Context
  const { esgAreaDocs } = useContext(AppContext);

  const [logo, setLogo] = useState<any>("");

  // Create form hook
  const { values, errors, touched, setValues, setSubmitting, isSubmitting, handleChange, handleBlur, submitForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        if (customer) {
          if (document) {
            apis.esg
              .updateEsgArea(customer.id, document._id, { ...values, logo_url: logo })
              .then((res) => {
                onClose();
                esgAreaDocs.fetchData(customer.id, { options: { sort: { name: ORDER.ASC } } });
                activeToast({
                  type: 'success',
                  text: res.msg,
                });
              })
              .catch((err) => {
                activeToast({
                  type: 'error',
                  text: err.msg,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            apis.esg
              .createEsgArea(customer.id, { ...values, logo_url: logo })
              .then((res) => {
                onClose();
                esgAreaDocs.fetchData(customer.id, { options: { sort: { name: ORDER.ASC } } });
                activeToast({
                  type: 'success',
                  text: res.msg,
                });
              })
              .catch((err) => {
                activeToast({
                  type: 'error',
                  text: err.msg,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }
      },
    });

  // File selector
  const fileSelector = useRef<any>();

  // Open explorer to update image
  const handleOpenBrowser = () => {
    fileSelector.current.click();
  };

  // Update profile imagea
  const handleUpdateLogo = async (e: any) => {
    setLogo(e.target.files[0]);
  };

  // Discard handler
  const handleDiscard = () => {
    onClose();
  };

  // On user change
  useEffect(() => {
    if (document) {
      const { exclusionCriteria = '', noSales, sales_value_less_5, sales_value_more_5 } = document;
      setLogo(document.logo_url || "");
      setValues({
        exclusionCriteria,
        noSales,
        sales_value_less_5,
        sales_value_more_5,
      });
    }
  }, [document]);

  // Return customer doc dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.form}>
        <Grid container columns={2} columnSpacing={8} rowSpacing={34}>
          <Box sx={styles.logoUploader} onClick={handleOpenBrowser}>
            <Avatar src={typeof logo === 'string' ? document?.logo_url : URL.createObjectURL(logo)} sx={styles.logo} />
            <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleUpdateLogo} />
          </Box>

          {fields.map(({ name, label, type = 'text', size = 2 }, index) => (
            <Grid key={index} item xs={size}>
              <TextField
                fullWidth
                name={name}
                type={type}
                placeholder={label}
                value={values[name]}
                disabled={isSubmitting}
                error={Boolean(errors[name] && touched[name])}
                helperText={Boolean(errors[name] && touched[name]) && (errors[name] as string)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          ))}
        </Grid>
        <Stack spacing={8} mt={30}>
          <Button
            fullWidth
            variant="varied-contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={28} color="inherit" /> : <DocFilledIcon />}
            onClick={submitForm}
          >
            {document ? 'Edit' : 'Create New'} Geschäftsfelder
          </Button>
          <Button variant="text" disabled={isSubmitting} onClick={handleDiscard}>
            Discard
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
