// Dependencies
import { FC, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// Global constants
import { ROUTES } from '../../../contants';

// Context
import { AppContext } from '../../../context';

// Apis
import apis from '../../../apis';

// Styles
import styles from './styles';
import { useToastContext } from '../../../components/Toast/toastContext';

//Assets
import { ProfileColorIcon } from '../../../assets/icons';

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Field!').required('Required Field!'),
  password: Yup.string().required('Required Field!'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Confirm password does not matched!')
    .required('Required Field!'),
});

// Export sign-up page
export const SignUpPage: FC = () => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dialogs } = useContext(AppContext);
  const { activeToast } = useToastContext();

  // Submit handler
  const handleSubmit = ({ email, password, lastName, firstName }: any) => {
    setIsLoading(true);
    apis.auth
      .register({ email, password, firstName, lastName })
      .then((res) => {
        setIsLoading(false);
        activeToast({
          type: 'success',
          text: res.msg,
        });
        dialogs.login.setVisible(true);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        activeToast({
          type: 'error',
          text: err.msg,
        });
        console.log(err);
      });
  };

  // Go to login handler
  const handleGotoLogin = () => {
    dialogs.login.setVisible(true);
  };

  // Return sign-up page
  return (
    <Box sx={styles.container}>
      <Stack spacing={18} sx={styles.content}>
        <Formik
          initialValues={{
            email: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
            <Box component={Form} onSubmit={handleSubmit}>
              <Stack spacing={34}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack width={'48%'}>
                    <TextField
                      fullWidth
                      name="firstName"
                      value={values.firstName}
                      placeholder="First Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ProfileColorIcon />
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack width={'48%'}>
                    <TextField
                      fullWidth
                      name="lastName"
                      value={values.lastName}
                      placeholder="Last Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ProfileColorIcon />
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                </Stack>
                <TextField
                  fullWidth
                  name="email"
                  value={values.email}
                  error={Boolean(errors.email && touched.email)}
                  helperText={Boolean(errors.email && touched.email) && errors.email}
                  placeholder="Enter your email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icons/mail.svg" alt="mail" />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  type="password"
                  name="password"
                  value={values.password}
                  error={Boolean(errors.password && touched.password)}
                  helperText={Boolean(errors.password && touched.password) && errors.password}
                  placeholder="Choose a Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icons/lock.svg" alt={'img'} />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                  helperText={Boolean(errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword}
                  placeholder="Repeat Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icons/lock.svg" alt="lock" />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Stack>
              <Stack mt={48} spacing={16}>
                <Button
                  fullWidth
                  type="submit"
                  disabled={isLoading}
                  startIcon={<img src="/icons/profile.svg" alt="profile" />}
                >
                  {isLoading ? <CircularProgress color={'inherit'} size={30} /> : 'Create my account'}
                </Button>
                <Typography px={46} variant="caption" textAlign="center">
                  By creating your account, you are agreeing with our{' '}
                  <Typography variant="caption" color="secondary" component={Link} to="#">
                    terms of service
                  </Typography>
                </Typography>
              </Stack>
            </Box>
          )}
        </Formik>
        <Stack spacing={16}>
          <Button fullWidth variant="outlined" startIcon={<img src="/icons/google.svg" alt="google" />}>
            Continue with Google
          </Button>
          <Typography variant="caption" textAlign="center">
            Don't have an account?{' '}
            <Typography
              variant="caption"
              color="secondary"
              component={Link}
              to={ROUTES.AUTH.LOGIN}
              onClick={handleGotoLogin}
            >
              Login
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
