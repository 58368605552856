// Dependencies
import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Apis
import apis from '../../../apis';

// Components
import { TemplateDialog } from '../../../components';

// Resources
import { ITemplate } from '../../../resource';

// Constants
import { ROUTES } from '../../../contants';

// Styles
import styles from './styles';

// Export Template list page
export const TemplateListPage: FC = () => {
  // States
  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visibleTemplateDialog, setVisibleTemplateDialog] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  // Get navigate from hook
  const navigate = useNavigate();

  // Fetch templates
  const fetchTemplates = () => {
    setIsLoading(true);
    apis.template
      .getTemplates()
      .then((res) => {
        setTemplates(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Open template dialog handler
  const handleOpenTemplateDialog = (id: string) => () => {
    setSelectedTemplateId(id);
    setVisibleTemplateDialog(true);
  };

  // Close template dialog handler
  const handleCloseTemplateDialog = () => {
    setVisibleTemplateDialog(false);
  };

  // Open blank template handler
  const handleOpenBlankTemplate = () => {
    navigate(ROUTES.NEW_DOC);
  };

  // On mounted
  useEffect(() => {
    fetchTemplates();
  }, []);

  // Return template list page
  return (
    <>
      <Box sx={styles.container}>
        {isLoading ? (
          <CircularProgress />
        ) : templates && templates.length > 0 ? (
          <Grid container rowSpacing={{ xs: 30, md: 65 }} columnSpacing={33}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box sx={styles.template} onClick={handleOpenBlankTemplate}>
                <img src="/logo.png" alt="blank" style={{ width: 88, height: 88 }} />
              </Box>
              <Typography mt={15} variant="title">
                Blank
              </Typography>
            </Grid>
            {templates.map(({ _id, name, thumbnail_url }, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Box sx={styles.template} onClick={handleOpenTemplateDialog(_id)}>
                  <img src={thumbnail_url} alt={thumbnail_url} />
                </Box>
                <Typography mt={15} variant="title">
                  {name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="title" align="center">
            No Templates
          </Typography>
        )}
      </Box>
      <TemplateDialog
        templateId={selectedTemplateId}
        open={visibleTemplateDialog}
        onClose={handleCloseTemplateDialog}
      />
    </>
  );
};
