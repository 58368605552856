// Dependencies
import { alpha, Components } from '@mui/material';

// Configs
import breakpoints from './breakpoints';
import shadows from './shadows';
import palette from './palette';
import typography from './typography';

// Icons
import { CheckboxIcon, CheckboxIndeterminateIcon, CheckboxOutlineIcon } from '../assets/icons';

// Override components
const components = {
  MuiButton: {
    defaultProps: {
      color: 'secondary',
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        '&.Mui-disabled': {
          opacity: 0.5,
        },
        '.MuiButton-startIcon': {
          left: 16,
          width: 26,
          height: 26,
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.MuiButton-endIcon': {
          right: 16,
          width: 26,
          height: 26,
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      text: {
        ':hover': {
          backgroundColor: palette.gray.light,
        },
      },
      contained: {
        boxShadow: shadows[1],
      },
      outlined: {
        boxShadow: shadows[1],
        backgroundColor: palette.common.white,
        ':hover': {
          backgroundColor: palette.common.white,
        },
      },
      fullWidth: {
        '.MuiButton-startIcon': {
          left: 32,
          position: 'absolute',
        },
      },
      sizeMedium: {
        height: 46,
        borderRadius: 23,
        padding: '10px 16px',
      },
    },
    variants: [
      {
        props: { variant: 'varied-outlined', color: 'secondary' },
        style: {
          boxShadow: shadows[1],
          color: palette.secondary.main,
          backgroundColor: palette.common.white,
          border: `1px solid ${palette.secondary.main}`,
          ':hover': {
            color: palette.common.white,
            backgroundColor: palette.secondary.main,
          },
          '&.Mui-disabled': {
            color: palette.secondary.main,
            opacity: 0.5,
          },
        },
      },
      {
        props: { variant: 'varied-contained', color: 'secondary' },
        style: {
          boxShadow: shadows[1],
          color: palette.common.white,
          backgroundColor: palette.secondary.main,
          ':hover': {
            color: palette.secondary.main,
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.secondary.main}`,
          },
          '&.Mui-disabled': {
            color: palette.common.white,
            opacity: 0.5,
          },
        },
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          height: 34,
          borderRadius: 17,
          padding: theme.spacing(4, 8),
          [theme.breakpoints.down('md')]: {
            height: 28,
            borderRadius: 14,
            padding: theme.spacing(3, 6),
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          height: 46,
          borderRadius: 23,
          padding: theme.spacing(10, 16),
          [theme.breakpoints.down('md')]: {
            height: 36,
            borderRadius: 18,
            padding: theme.spacing(6, 12),
          },
        }),
      },
    ],
  },
  MuiFab: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      root: {
        boxShadow: shadows[1],
      },
      secondary: {
        ':hover': {
          backgroundColor: palette.secondary.main,
        },
      },
      sizeSmall: {
        width: 36,
        height: 36,
      },
    },
    variants: [
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          width: 46,
          height: 46,
          [theme.breakpoints.down('md')]: {
            width: 36,
            height: 36,
          },
        }),
      },
    ],
  },
  MuiIconButton: {
    defaultProps: {
      color: 'secondary',
      size: 'large',
      // @ts-ignore
      variant: 'outlined',
    },
    variants: [
      {
        props: { variant: 'no-outlined', color: 'secondary' },
        style: ({ theme }) => ({
          border: 0,
          backgroundColor: theme.palette.common.white,
        }),
      },
      {
        // @ts-ignore
        props: { variant: 'outlined', color: 'secondary' },
        style: ({ theme }) => ({
          border: `1px solid ${theme.palette.secondary.main}`,
          backgroundColor: theme.palette.common.white,
          boxShadow: theme.shadows[1],
          ':hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          },
        }),
      },
      {
        // @ts-ignore
        props: { variant: 'contained', color: 'secondary' },
        style: ({ theme }) => ({
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }),
      },
      {
        // @ts-ignore
        props: { variant: 'text', color: 'secondary' },
        style: ({ theme }) => ({
          color: theme.palette.secondary.main,
          backgroundColor: 'transparent',
        }),
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          width: 26,
          height: 26,
          [theme.breakpoints.down('md')]: {
            width: 24,
            height: 24,
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          width: 46,
          height: 46,
          [theme.breakpoints.down('md')]: {
            width: 36,
            height: 36,
          },
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          width: 60,
          height: 60,
          [theme.breakpoints.down('md')]: {
            width: 48,
            height: 48,
          },
        }),
      },
    ],
  },
  MuiToggleButton: {
    defaultProps: {
      color: 'secondary',
      size: 'medium',
      // @ts-ignore
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        borderRadius: '50%',
      },
    },
    variants: [
      {
        // @ts-ignore
        props: { variant: 'outlined', color: 'secondary' },
        style: {
          color: palette.secondary.main,
          border: `1px solid ${palette.secondary.main}`,
          backgroundColor: 'transparent',
          ':hover': {
            border: 'none',
            backgroundColor: alpha(palette.secondary.main, 0.3),
          },
          '&.Mui-selected': {
            backgroundColor: palette.common.white,
            ':hover': {
              border: `1px solid ${palette.secondary.main}`,
              backgroundColor: palette.common.white,
            },
          },
        },
      },
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          width: 26,
          height: 26,
          [theme.breakpoints.down('md')]: {
            width: 24,
            height: 24,
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          width: 46,
          height: 46,
          [theme.breakpoints.down('md')]: {
            width: 36,
            height: 36,
          },
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          width: 60,
          height: 60,
          [theme.breakpoints.down('md')]: {
            width: 48,
            height: 48,
          },
        }),
      },
    ],
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        display: 'h1',
        headline: 'h2',
        title: 'h3',
        subtitle: 'h4',
        label1: 'h5',
        label2: 'h6',
        body1: 'p',
        body2: 'p',
        button: 'span',
        caption: 'span',
        overline: 'span',
      },
    },
    styleOverrides: {
      root: {
        color: palette.primary.main,
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'secondary',
      icon: <CheckboxOutlineIcon />,
      checkedIcon: <CheckboxIcon />,
      indeterminateIcon: <CheckboxIndeterminateIcon />,
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
      color: 'secondary',
    },
    styleOverrides: {
      root: {
        '.MuiSvgIcon-fontSizeSmall': {
          width: 16,
          height: 16,
        },
      },
      colorSecondary: {
        '.MuiSvgIcon-root': {
          color: palette.secondary.main,
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      outlined: {
        display: 'flex',
        alignItems: 'center'
      }
    },
  },
  MuiFormControlLabel: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          '.MuiFormControlLabel-label': {
            ...theme.typography.button,
            textTransform: 'none',
          },
        }),
      },
    ],
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        ...typography.caption,
        [`@media (max-width: ${breakpoints.values.sm - 0.5}px)`]: {
          '& .MuiInputBase-input': {
            padding: '6px 0 7px',
          },
        },
      },
    },
  },
  MuiInput: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      colorSecondary: {
        '::before': {
          borderBottomColor: palette.secondary.main,
        },
        ':hover:not(.Mui-disabled):before': {
          borderBottomWidth: 1,
          borderBottomColor: palette.secondary.main,
        },
        '.MuiInputAdornment-root': {
          color: palette.secondary.main,
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      size: 'medium',
      color: 'secondary',
    },
    styleOverrides: {
      multiline: {
        height: 'auto',
      },
    },
    variants: [
      {
        props: { color: 'secondary' },
        style: ({ theme }) => ({
          color: theme.palette.secondary.main,
          ':hover': {
            backgroundColor: alpha(theme.palette.secondary.main, 0.3),
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(theme.palette.secondary.main, 0.3),
            },
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.common.white,
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
            },
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          height: 46,
          borderRadius: 23,
          [theme.breakpoints.down('md')]: {
            height: 36,
            borderRadius: 18,
          },
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          borderRadius: 23,
          [theme.breakpoints.down('md')]: {
            borderRadius: 18,
          },
        }),
      },
    ],
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        width: 26,
        height: 26,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...typography.label1,
        opacity: 0.5,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        boxShadow: shadows[1],
      },
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: alpha(palette.gray.main, 0.2),
        backgroundColor: alpha(palette.gray.main, 0.2),
      },
    },
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        borderRadius: 23,
        padding: '4px 3px',
        border: `1px solid ${palette.secondary.main}`,
        boxShadow: shadows[1],
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        width: 38,
        height: 22,
        ...typography.button,
        color: alpha(palette.primary.main, 0.5),
        margin: '0px 4px',
        ':hover': {
          color: palette.primary.main,
          backgroundColor: palette.gray.light,
        },
        '&.Mui-selected': {
          color: palette.secondary.main,
          backgroundColor: alpha(palette.secondary.main, 0.3),
        },
      },
      previousNext: {
        width: 36,
        height: 36,
        margin: '0 3px',
        color: palette.secondary.main,
      },
    },
  },
  MuiTableRow: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          ':hover': {
            '.MuiTableCell-body': {
              backgroundColor: alpha(theme.palette.secondary.light, 0.5),
            },
          },
          '&.Mui-selected': {
            '.MuiTableCell-body': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.secondary.main,
              backgroundColor: theme.palette.secondary.light,
              '&:first-child': {
                borderColor: theme.palette.secondary.main,
              },
              '&:last-child': {
                borderColor: theme.palette.secondary.main,
              },
            },
          },
        }),
      },
    ],
  },
  MuiTableCell: {
    variants: [
      {
        props: {},
        style: ({ theme }) => ({
          padding: theme.spacing(0, 10),
          '&.MuiTableCell-head': {
            ...theme.typography.overline,
            height: 30,
            textTransform: 'uppercase',
            color: alpha(theme.palette.gray.dark, 0.5),
            ':hover': {
              color: theme.palette.gray.dark,
            },
          },
          '&.MuiTableCell-body': {
            height: 48,
            borderTop: '1px solid transparent',
            borderBottom: '1px solid transparent',
            color: theme.palette.secondary.main,
            '&:first-child': {
              borderTopLeftRadius: 8,
              borderLeft: '1px solid transparent',
            },
            '&:last-child': {
              borderBottomRightRadius: 8,
              borderRight: '1px solid transparent',
            },
          },
        }),
      },
    ],
  },
  MuiTable: {
    styleOverrides: {
      root: {
        borderCollapse: 'initial',
      },
    },
  },
} as Components;

// Export components
export default components;
