// Dependencies
import { FC, useContext, useEffect } from 'react';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import { Dialog } from '../../Dialog';

// Apis
import apis from '../../../apis';

// Icons
import { DocFilledIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Context
import { AppContext } from '../../../context';
import { useToastContext } from '../../Toast/toastContext';

// Interfaces
import { IAccount, ICustomerDocument, ORDER } from '../../../resource';

interface IField {
  name: string;
  label: string;
  type?: 'text' | 'checkbox';
  size?: 1 | 2;
}

interface IEsgDocDialogProps {
  customer: IAccount | null;
  open: boolean;
  document?: ICustomerDocument;
  onClose: () => void;
}

// Constants
const initialValues = {
  name: '',
  url: '',
  is_active: true,
  is_editable: false,
};

const fields: IField[] = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'url',
    label: 'Url',
  },
  {
    name: 'is_active',
    label: 'Active',
    type: 'checkbox',
    size: 1,
  },
  {
    name: 'is_editable',
    label: 'Editable',
    type: 'checkbox',
    size: 1,
  },
];

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required Field!'),
  url: Yup.string().url('Invalid Field!').required('Required Field!'),
});

// Export esg doc dialog
export const EsgDocDialog: FC<IEsgDocDialogProps> = ({ customer, open, document, onClose }) => {
  // Get toast from hook
  const { activeToast } = useToastContext();

  // Context
  const { esgDocs } = useContext(AppContext);

  // Create form hook
  const { values, errors, touched, setValues, setSubmitting, isSubmitting, handleChange, handleBlur, submitForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        if (customer) {
          if (document) {
            apis.esg
              .updateEsgDocument(customer.id, document._id, values)
              .then((res) => {
                onClose();
                esgDocs.setSelected([]);
                esgDocs.fetchData(customer.id, { options: { sort: { name: ORDER.ASC } } });
                activeToast({
                  type: 'success',
                  text: res.msg,
                });
              })
              .catch((err) => {
                activeToast({
                  type: 'error',
                  text: err.msg,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            apis.esg
              .createEsgDocument(customer.id, values)
              .then((res) => {
                onClose();
                esgDocs.fetchData(customer.id, { options: { sort: { name: ORDER.ASC } } });
                activeToast({
                  type: 'success',
                  text: res.msg,
                });
              })
              .catch((err) => {
                activeToast({
                  type: 'error',
                  text: err.msg,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }
      },
    });

  // Discard handler
  const handleDiscard = () => {
    onClose();
  };

  // On user change
  useEffect(() => {
    if (document) {
      const { name = '', url = '', is_active, is_editable } = document;
      setValues({
        name,
        url,
        is_active,
        is_editable,
      });
    }
  }, [document]);

  // Return ESG doc dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.form}>
        <Grid container columns={2} columnSpacing={8} rowSpacing={34}>
          {fields.map(({ name, label, type = 'text', size = 2 }, index) => (
            <Grid key={index} item xs={size}>
              {type === 'text' ? (
                <TextField
                  fullWidth
                  name={name}
                  placeholder={label}
                  value={values[name]}
                  disabled={isSubmitting}
                  error={Boolean(errors[name] && touched[name])}
                  helperText={Boolean(errors[name] && touched[name]) && (errors[name] as string)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              ) : (
                <FormControlLabel
                  name={name}
                  label={label}
                  checked={values[name]}
                  disabled={isSubmitting}
                  control={<Checkbox />}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <Stack spacing={8} mt={30}>
          <Button
            fullWidth
            variant="varied-contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={28} color="inherit" /> : <DocFilledIcon />}
            onClick={submitForm}
          >
            {document ? 'Edit' : 'Create New'} Document
          </Button>
          <Button variant="text" disabled={isSubmitting} onClick={handleDiscard}>
            Discard
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
