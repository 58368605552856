// Dependencies
import { ChangeEvent, FC, useContext, useState } from 'react';
import { Box, Button, ButtonBase, Divider, Grid, InputBase, Stack, Typography } from '@mui/material';

// Components
import { ColorPicker } from '../../ColorPicker';

// Context
import { AppContext } from '../../../../context';

// Icons
import {
  Chart1Icon,
  Chart2Icon,
  Chart3Icon,
  Chart4Icon,
  Chart5Icon,
  Chart6Icon,
  Chart7Icon,
  Chart8Icon,
  Chart9Icon,
  Chart10Icon,
  Chart11Icon,
  Chart12Icon,
  UploadIcon,
} from '../../../../assets/icons';

// Styles
import styles from './styles';

// Constants
const charts = [
  {
    icon: <Chart1Icon />,
  },
  {
    icon: <Chart2Icon />,
  },
  {
    icon: <Chart3Icon />,
  },
  {
    icon: <Chart4Icon />,
  },
  {
    icon: <Chart5Icon />,
  },
  {
    icon: <Chart6Icon />,
  },
  {
    icon: <Chart7Icon />,
  },
  {
    icon: <Chart8Icon />,
  },
  {
    icon: <Chart9Icon />,
  },
  {
    icon: <Chart10Icon />,
  },
  {
    icon: <Chart11Icon />,
  },
  {
    icon: <Chart12Icon />,
  },
];

// Export chart panel
export const ChartPanel: FC = () => {
  // States
  const [isHoverTable, setIsHoverTable] = useState(false);

  // Context
  const {
    chart: { table },
  } = useContext(AppContext);

  // Create table
  const tablePanel = [
    new Array(7).fill(''),
    new Array(7).fill(''),
    new Array(7).fill(''),
    new Array(7).fill(''),
    new Array(7).fill(''),
    new Array(7).fill(''),
  ];

  // Row change handler
  const handleRowChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setValue({
      ...table.value,
      row: +e.currentTarget.value,
    });
  };

  // Column change handler
  const handleColumnChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setValue({
      ...table.value,
      column: +e.currentTarget.value,
    });
  };

  // Hover table handler
  const handleHoverTable = () => {
    setIsHoverTable(true);
  };

  // Leave table handler
  const handleLeaveTable = () => {
    setIsHoverTable(false);
  };

  // Hover cell handler
  const handleHoverCell = (x: number, y: number) => () => {
    if (isHoverTable) {
      table.setValue({
        row: y + 1,
        column: x + 1,
      });
    }
  };

  // Return chart panel
  return (
    <Stack divider={<Divider sx={{ my: 20 }} />}>
      <Box>
        <Typography mb={29} variant="title">
          Table
        </Typography>
        <Grid container columns={2} spacing={{ xs: 20, md: 44 }}>
          <Grid item xs={1}>
            <Grid container columns={7} spacing={4} onMouseEnter={handleHoverTable} onMouseLeave={handleLeaveTable}>
              {tablePanel.map((row, y) =>
                row.map((_, x) => (
                  <Grid key={`${y}-${x}`} item xs={1}>
                    <Box
                      sx={(theme) => styles.cell(theme, { active: x < table.value.column && y < table.value.row })}
                      onClick={handleLeaveTable}
                      onMouseEnter={handleHoverCell(x, y)}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Stack spacing={20}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="label2" color="secondary">
                  Row
                </Typography>
                <InputBase type="number" sx={styles.input} value={table.value.row} onChange={handleRowChange} />
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="label2" color="secondary">
                  Column
                </Typography>
                <InputBase type="number" sx={styles.input} value={table.value.column} onChange={handleColumnChange} />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography mb={29} variant="title">
          Charts
        </Typography>
        <Grid container columns={4}>
          {charts.map(({ icon }, index) => (
            <Grid key={index} item xs={1}>
              <ButtonBase sx={styles.chart}>{icon}</ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Box>
      <ColorPicker />
      <label>
        <input accept=".csv" multiple type="file" style={{ display: 'none' }} />
        <Button fullWidth variant="varied-outlined" startIcon={<UploadIcon />} component="span">
          Upload CSV
        </Button>
      </label>
    </Stack>
  );
};
