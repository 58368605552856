// Dependencies
import React, { FC, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Fab, FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack, Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Context
import { AppContext } from '../../../context';
import { useToastContext } from '../../../components/Toast/toastContext';

// Resource
import { DisplayMode, ORDER } from '../../../resource';

// Apis
import apis from '../../../apis';

// Constants
import { PAGE_NAME, ROUTES, getPageName } from '../../../contants';

// Icons
import { DeleteIcon, ListIcon, MenuIcon, ModuleIcon, PencilIcon, PlusIcon } from '../../../assets/icons';

// Styles
import styles from './styles';
import Sidebar from '../Sidebar';

// Export header
const Header: FC = () => {
  // States
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  // Check dashboard page
  const isDashboard = Boolean(useMatch(ROUTES.DASHBOARD));
  const isUsersPage = Boolean(useMatch(ROUTES.USER.LIST));
  const isCustomerDocumentsPage = Boolean(useMatch(ROUTES.CUSTOMER_DOCUMENT));
  const isPdfPage = Boolean(useMatch(ROUTES.PDF_VIEWER))
  const isEsgDocumentsPage = Boolean(useMatch(ROUTES.ESG.DOCUMENTS));
  const isCdnPage = Boolean(useMatch(ROUTES.MANAGE_WIDGETS));

  useEffect(() => {
    setSearch("");
  }, [
    isDashboard,
    isUsersPage,
    isCustomerDocumentsPage,
    isPdfPage,
    isEsgDocumentsPage,
  ]);

  // Theme
  const theme = useTheme();

  // Check window size
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  // Get context
  const {
    account,
    displayMode,
    setDisplayMode,
    dialogs,
    user,
    users,
    selectedUsers,
    customerDoc,
    customerDocs,
    esgDoc,
    esgDocs,
    showDeleted,
    setShowDeleted,
    search,
    setSearch,
  } = useContext(AppContext);

  // Get toast from hook
  const { activeToast } = useToastContext();

  // Toggle display mode handler
  const handleToggleDisplayMode = () => {
    setDisplayMode(displayMode === DisplayMode.List ? DisplayMode.Module : DisplayMode.List);
  };

  // Open new dialog handler
  const handleOpenNewDocumentDialog = () => {
    dialogs.newDoc.setVisible(true);
  };

  // Create handler
  const handleCreate = () => {
    if (isUsersPage) {
      user.setValue(undefined);
      dialogs.user.setVisible(true);
    }

    if (isCustomerDocumentsPage) {
      customerDoc.setValue(undefined);
      dialogs.customerDoc.setVisible(true);
    }

    if (isEsgDocumentsPage) {
      esgDoc.setValue(undefined);
      dialogs.esgDoc.setVisible(true);
    }
  };

  // Edit handler
  const handleEdit = () => {
    if (isUsersPage) {
      if (selectedUsers.value.length > 0) {
        apis.user
          .getUser(selectedUsers.value[0])
          .then((res) => {
            user.setValue(res);
            dialogs.user.setVisible(true);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        activeToast({
          type: 'warning',
          text: 'No selected user!',
        });
      }
    }

    if (isCustomerDocumentsPage && account) {
      if (customerDocs.selected.length > 0) {
        apis.pdf
          .getCustomerDocument(account.id, customerDocs.selected[0])
          .then((res) => {
            customerDoc.setValue(res.document);
            dialogs.customerDoc.setVisible(true);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        activeToast({
          type: 'warning',
          text: 'No selected document!',
        });
      }
    }

    if (isEsgDocumentsPage && account) {
      if (esgDocs.selected.length > 0) {
        apis.esg
          .getEsgDocument(account.id, esgDocs.selected[0])
          .then((res) => {
            esgDoc.setValue(res.document);
            dialogs.esgDoc.setVisible(true);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        activeToast({
          type: 'warning',
          text: 'No selected document!',
        });
      }
    }
  };

  // Delete handler
  const handleDelete = () => {
    if (isUsersPage) {
      if (selectedUsers.value.length > 0) {
        apis.user
          .removeUsers(selectedUsers.value)
          .then((res) => {
            activeToast({
              type: 'success',
              text: res.msg,
            });
            users.fetchData({
              options: {
                limit: 15,
                skip: 0,
                sort: {
                  firstName: ORDER.ASC,
                },
              },
            });
          })
          .catch((err) => {
            activeToast({
              type: 'error',
              text: err.msg,
            });
          })
          .finally(() => {
            customerDoc.setValue(undefined);
            selectedUsers.setValue([]);
          });
      } else {
        activeToast({
          type: 'warning',
          text: 'No selected user!',
        });
      }
    }

    if (isCustomerDocumentsPage && account) {
      if (customerDocs.selected.length > 0) {
        apis.pdf
          .removeCustomerDocuments(account.id, customerDocs.selected)
          .then((res) => {
            activeToast({
              type: 'success',
              text: res.msg,
            });
            customerDocs.fetchData(account.id);
          })
          .catch((err) => {
            activeToast({
              type: 'error',
              text: err.msg,
            });
          })
          .finally(() => {
            customerDocs.setSelected([]);
          });
      } else {
        activeToast({
          type: 'warning',
          text: 'No selected document!',
        });
      }
    }

    if (isEsgDocumentsPage && account) {
      if (esgDocs.selected.length > 0) {
        apis.esg
          .removeEsgDocuments(account.id, esgDocs.selected)
          .then((res) => {
            activeToast({
              type: 'success',
              text: res.msg,
            });
            esgDocs.fetchData(account.id);
          })
          .catch((err) => {
            activeToast({
              type: 'error',
              text: err.msg,
            });
          })
          .finally(() => {
            esgDocs.setSelected([]);
          });
      } else {
        activeToast({
          type: 'warning',
          text: 'No selected document!',
        });
      }
    }
  };

  // Get location from hook
  const { pathname } = useLocation();

  const handleSearch = (e) => {
    setSearch(e.target.value)
  };

  // Open search panel handler
  const handleOpenSearchPanel = () => {
    setOpenSearch(true);
  };

  // Close search panel handler
  const handleCloseSearchPanel = () => {
    if (!search) {
      setOpenSearch(false);
    }
  };

  // Open drawer handler
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  // Close drawer handler
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  // On mounted
  useEffect(() => {
    if (isTablet) {
      setOpenDrawer(false);
    }
  }, [isTablet]);

  // Return header
  return (
    <>
      <Stack sx={styles.header}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box sx={{ height: { xs: 52, md: 46 } }}>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
              {isDashboard && (
                <Box sx={styles.newButton} onClick={handleOpenNewDocumentDialog}>
                  <Fab>
                    <img src="/icons/plus.svg" alt="plus" />
                  </Fab>
                  <Button variant="outlined">New</Button>
                </Box>
              )}
              <Typography variant="headline">{getPageName(pathname)}</Typography>
              {isDashboard && (
                <FormControlLabel
                  sx={{ ml: 12 }}
                  onChange={() => setShowDeleted(!showDeleted)}
                  control={<Switch checked={showDeleted} color="primary" />}
                  label="Archived"
                />
              )}
            </Box>
            {!openSearch && (
              <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                {/* @ts-ignore */}
                <IconButton size="medium" variant="text" onClick={handleOpenDrawer}>
                  <MenuIcon />
                </IconButton>
                <Box sx={styles.logo} component={Link} to={ROUTES.DASHBOARD}>
                  <img src="/logo.png" alt="logo" />
                </Box>
              </Box>
            )}
          </Box>

          {!isPdfPage && !isCdnPage && (
            <Stack
              direction="row"
              spacing={{ xs: 8, md: 16 }}
              divider={(isUsersPage || isCustomerDocumentsPage || isEsgDocumentsPage) && <Divider orientation="vertical" flexItem />}
            >
              <OutlinedInput
                color="secondary"
                sx={styles.searchInput}
                placeholder="Search"
                value={search}
                onChange={handleSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <img src="/icons/search.svg" alt="search" />
                  </InputAdornment>
                }
                onMouseEnter={handleOpenSearchPanel}
                onMouseLeave={handleCloseSearchPanel}
              />
              {(isUsersPage || isCustomerDocumentsPage || isEsgDocumentsPage) && (
                <Stack direction="row" spacing={16}>
                  <IconButton size="medium" onClick={handleDelete}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton size="medium" onClick={handleEdit}>
                    <PencilIcon />
                  </IconButton>
                </Stack>
              )}
              {(isUsersPage || isCustomerDocumentsPage || isEsgDocumentsPage) && (
                <Button disabled={isEsgDocumentsPage && !esgDocs.esgDocAllowed} startIcon={<PlusIcon />} variant="varied-outlined" onClick={handleCreate}>
                  Add New{' '}
                  {isTablet && (
                    <>
                      {isUsersPage && 'User'}
                      {(isCustomerDocumentsPage || isEsgDocumentsPage) && 'Document'}
                    </>
                  )}
                </Button>
              )}
              {isDashboard && (
                <Button
                  sx={styles.toggleButton}
                  variant={displayMode === DisplayMode.List ? 'contained' : 'outlined'}
                  onClick={handleToggleDisplayMode}
                >
                  {displayMode === DisplayMode.List ? <ListIcon /> : <ModuleIcon />}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
          {isDashboard && (
            <Box sx={styles.newButton} onClick={handleOpenNewDocumentDialog}>
              <Fab>
                <img src="/icons/plus.svg" alt="plus" />
              </Fab>
              <Button variant="outlined">New</Button>
            </Box>
          )}
          <Typography variant="headline">{PAGE_NAME[pathname]}</Typography>
        </Stack>
      </Stack>
      <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer} sx={styles.drawer}>
        <Sidebar />
      </Drawer>
    </>
  );
};

// Export header
export default Header;
