// Dependencies
import { FC } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

// Interfaces
interface IDataTableProps {
  data: object;
}

// Export Table component
export const DataTable: FC<IDataTableProps> = ({ data = {} }) => {
  // Return Table component
  return (
    <Table>
      <TableBody>
        {Object.entries(data).map(([key, value], index) => (
          <TableRow key={index} sx={{ '&:hover': { '.MuiTableCell-body': { background: 'transparent !important' } } }}>
            <TableCell sx={{ fontSize: 20, color: '#808080 !important' }}>{key}:</TableCell>
            <TableCell sx={{ fontSize: 20, color: '#000000 !important' }}>{value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
