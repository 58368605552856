// Interfaces
import { IStyles } from '../../../resource';

// Define styles

const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: { xs: theme.spacing(0, 20), md: theme.spacing(24, 46) },
    borderRadius: theme.spacing(30, 0),
    width: '100%',
    maxWidth: { xs: 380, sm: 480, md: 798 },
    minHeight: 706,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  preview: () => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: { xs: 'column', md: 'row' },
  }),
  divider: () => ({
    mx: { xs: 0, md: 46 },
    my: { xs: 23, md: 0 },
    height: { xs: 1, md: 'calc(100vh - 333px)' },
  }),
  thumbnail: (theme) => ({
    width: { xs: 1, md: '55%' },
    height: { xs: 'calc(50vh - 200px)', md: 'calc(100vh - 379px)' },
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(20, 0),
    overflow: 'hidden',
    img: {
      width: '100%',
      height: '100%',
    },
  }),
  description: () => ({
    width: { xs: 1, md: '45%' },
    height: { xs: 'calc(50vh - 200px)', md: 'calc(100vh - 379px)' },
  }),
};

// Export styles
export default styles;
