// Dependencies
import { FC, useEffect, useState } from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

// Resources
import { ITemplate } from '../../../../resource';

// Apis
import apis from '../../../../apis';

// Styles
import styles from './styles';

// Export template panel
export const TemplatePanel: FC = () => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templates, setTemplates] = useState<ITemplate[]>([]);

  // Fetch templates
  const fetchTemplates = () => {
    setIsLoading(true);
    apis.template
      .getTemplates()
      .then((res) => {
        setTemplates(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // On mounted
  useEffect(() => {
    fetchTemplates();
  }, []);

  // Return template panel
  return (
    <Stack divider={<Divider sx={{ mt: 24, mb: 23 }} />}>
      <Box>
        <Typography mb={29} variant="title">
          Recently used
        </Typography>
        <Grid container />
      </Box>
      <Box>
        <Typography mb={29} variant="title">
          Templates
        </Typography>
        {
          !isLoading && (
            <Grid container columns={2} rowSpacing={20} columnSpacing={8}>
              {templates.map(({ name, thumbnail_url }, index) => (
                <Grid key={index} item xs={1}>
                  <Box sx={styles.template}>
                    <img src={thumbnail_url} alt={thumbnail_url} />
                  </Box>
                  <Typography mt={6} variant="overline">
                    {name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )
        }
      </Box>
    </Stack>
  );
};
