// Dependencies
import React, {FC, useRef, useState} from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, IconButton,
  TextField, Tooltip,
} from '@mui/material';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';

// Component
import { Dialog } from '../../../../components/Dialog'

// Styles
import styles from '../../styles';
import {businessTableData} from '../../index';
import apis from '../../../../apis';
import {API_SERVER} from '../../../../contants';
import {EsgImagesModal} from '../../../../components/Dialogs/EsgImages';

// Interfaces
interface ITableEditModalProps {
  open: boolean;
  data: { id: string, _id: string, label: string; no_turnover: number, sales_volume: number }[];
  loading: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export ArrayEdit Modal
export const BusinessTableEditModal: FC<ITableEditModalProps> = ({
  open,
  data,
  loading,
  onClose,
  onSave,
}) => {
  const fileSelector = useRef<any>();
  const [dataArray, setDataArray] = useState<any[]>(data.map((item) => ({ ...item, id: item._id || item.id })));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isOpenImageListModal, setIsOpenImageListModal] = useState(false);
  const initialValues = {};
  for (let i = 0; i < dataArray.length; i++) {
    // @ts-ignore
    initialValues[`label__${dataArray[i].id}`] = dataArray[i].label;
    initialValues[`no_turnover__${dataArray[i].id}`] = dataArray[i].no_turnover;
    initialValues[`sales_volume__${dataArray[i].id}`] = dataArray[i].sales_volume;
  }
  // Create form hook
  const { values, handleChange, submitForm, setValues } = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      onSave(
        dataArray.map((item) => ({
          ...item,
          label: values[`label__${item.id}`],
          no_turnover: values[`no_turnover__${item.id}`],
          sales_volume: values[`sales_volume__${item.id}`],
        }))
      );
    },
  });

  const handleOpenBrowser = (index: number) => {
    // fileSelector.current.click();
    setSelectedIndex(index);
    setIsOpenImageListModal(true);
  };

  const handleChangeImage = async (e: any) => {
    if (e.target.files[0]) {
      apis.pdf.uploadLogoImage(e.target.files[0])
      .then((res) => {
        if (res.success) {
          setDataArray(dataArray.map((item, i) => i === selectedIndex ? { ...item, image: res.data } : item));
        }
      })
      .catch(e => {
        console.log(e);
      });
    }
  };

  const onAdd = () => {
    const key = +new Date();
    const labelKey = `label__${key}`;
    const noTurnoverKey = 'no_turnover__' + key;
    const salesVolumeKey = 'sales_volume__' + key;
    setValues({ ...values, [labelKey]: '', [noTurnoverKey]: '', [salesVolumeKey]: '' });
    setDataArray([...dataArray, { id: key, label: '', no_turnover: '', sales_volume: '' }]);
  };

  const handleRemoveRow = (index) => {
    setDataArray(dataArray.filter((_, i) => i !== index));
  };

  const getEsgItemImage = (image, index) => {
    if (image) {
      return image.includes('./public') ? `${API_SERVER}/${image}` : image;
    } else {
      return businessTableData[index] ? `${API_SERVER}/${businessTableData[index]?.[0]}` : '';
    }
  }

  const handleSaveImage = (image) => {
    setDataArray(dataArray.map((item, i) => i === selectedIndex ? { ...item, image } : item));
  };

  // Return ArrayEdit Modal
  return (
    <>
      <Dialog open={open} onClose={onClose} sx={styles.dialog}>
        <DialogTitle sx={{ textTransform: 'uppercase' }}>KONTROVERSE GESCHÄFTSFELDER</DialogTitle>
        <DialogContent sx={{ maxHeight: '400px', maxWidth: '1000px' }}>
          <Grid pt={20} container columns={14} spacing={[10, 20]} sx={{ alignItems: "center" }}>
            {
              dataArray.map(({ id, label, image }, index) => (
                <>
                  <Grid sx={styles.imageCell} item xs={1} key={index}>
                    <Tooltip title="Remove row">
                      <IconButton sx={styles.deleteIcon} onClick={() => handleRemoveRow(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid sx={styles.imageCell} item xs={1} key={index}>
                    <Tooltip title="Click here to change the image">
                      <IconButton sx={styles.esgImage} onClick={() => handleOpenBrowser(index)}>
                        <img src={getEsgItemImage(image, index)} alt={id} />
                      </IconButton>
                    </Tooltip>
                    <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleChangeImage} />
                  </Grid>
                  <Grid item xs={4} key={index} sx={{ textAlign: 'right' }}>
                    <TextField
                      fullWidth
                      name={`label__${id}`}
                      label={'Label'}
                      /* @ts-ignore */
                      value={values[`label__${id}`]}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4} key={index}>
                    <TextField
                      fullWidth
                      name={`no_turnover__${id}`}
                      label={'Kein Umsatz im Geschäftsfeld'}
                      /* @ts-ignore */
                      value={values[`no_turnover__${id}`]}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={4} key={index}>
                    <TextField
                      fullWidth
                      name={`sales_volume__${id}`}
                      label="Umsatz"
                      /* @ts-ignore */
                      value={values[`sales_volume__${id}`]}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              ))
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAdd}>Add</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={submitForm}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
        </DialogActions>
      </Dialog>
      <EsgImagesModal
        open={isOpenImageListModal}
        onClose={() => setIsOpenImageListModal(false)}
        onSave={handleSaveImage}
      />
    </>
  );
};
