// Dependencies
import React, {ChangeEvent, FC, useEffect} from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  OutlinedInput,
  IconButton,
  Divider,
  Switch,
  Select,
  MenuItem, FormControlLabel
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import styles from "../HistoryChart/styles";
import apis from '../../../../../../apis';

type BarChartContentProps = {
  id: number,
  content: any,
  preview: boolean,
  onChange: (id: number, contentData: any) => void;
}

// Export template panel
export const BarChartContent: FC<BarChartContentProps> = ({
  id,
  content,
  preview,
  onChange,
}) => {
  // Change data handler
  const handleChangeLabel = (e, index) => {
    onChange(id, {
      ...content,
      chartData: content.chartData.map((p, i) => index === i ? { name: e.target.value, data: p.data } : p)
    });
  };

  // Add data handler
  const handleAddData = () => {
    onChange(id, {
      ...content,
      chartData: [...(content.chartData || []), { name: '', data: [] }],
    });
  };

  const handleAddSubItem = (index: number) => {
    onChange(id, {
      ...content,
      chartData: content.chartData.map((p, i) => index === i ? { ...p, data: [...p.data, 0] } : p),
    });
  };

  // Change remove item
  const handleRemoveLine = (index: number) => {
    onChange(id, {
      ...content,
      chartData: content.chartData.slice(0, index).concat(content.slice(index + 1)),
    });
  };

  const handleRemoveSubItem = (index: number, subIndex: number) => {
    onChange(id, {
      ...content,
      chartData: content.chartData.map((item, i) => i === index ? { ...item, data: item.data.filter((s, si) => si !== subIndex) } : item),
    })
  };

  const handleChangeData = (e, index, subIndex) => {
    onChange(id, {
      ...content,
      chartData: content.chartData.map((p, i) => i === index
      ? { ...p, data: p.data.map((s, si) => si === subIndex ? e.target.value : s) }
      : p)
    });
  };

  const handleAddCategory = () => {
    onChange(id, {
      ...content,
      categories: [...(content.categories || []), ""],
    })
  };

  const handleChangeCategories = (index, e) => {
    onChange(id, {
      ...content,
      categories: content.categories.map((category, cIndex) => cIndex === index ? e.target.value : category),
    })
  };

  const handleRemoveCategory = (index) => {
    onChange(id, {
      ...content,
      categories: content.categories.filter((category, cIndex) => cIndex !== index),
    });
  }

  const handleChangeAxisType = (isPercent) => {
    onChange(id, { ...content, isPercent });
  }

  const handleChangeChartType = (e) => {
    onChange(id, { ...content, chartType: e.target.value });
  }

  const handleChangeIsin = (e) => {
    onChange(id, { ...content, isin: e.target.value });
  }

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(id, { ...content, title: e.target.value });
  };

  const handleChangePdfOption = (e) => {
    onChange(id, { ...content, pdfField: e.target.value });
  };

  // Return template panel
  return (
    <Stack>
      <Box>
        {preview ? (
          <>
            {preview}
            {/*<Chart data={content.map((item) => ({ ...item, value: +item.value }))} type={type} />*/}
          </>
        ) : (
          <>
            <Box sx={{ mb: 16 }}>
              <Typography variant="h5">Bar chart</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={16} mb={16}>
              <Typography variant="subtitle1">Title:</Typography>
              <OutlinedInput sx={{ width: '300px' }} name="title" type="string" value={content.title || ""} onChange={handleChangeTitle} />
            </Box>

            <Box display="flex" alignItems="center" gap={16} mb={16}>
              <Typography variant="subtitle1">PDF field to sync:</Typography>
              <Select sx={styles.chartTypeSelect} value={content?.pdfField} onChange={handleChangePdfOption}>
                <MenuItem value="wertentwicklung_nach_jahren">WERTENTWICKLUNG NACH JAHREN</MenuItem>
                <MenuItem value="gesamtportfolio">GESAMTPORTFOLIO</MenuItem>
              </Select>
            </Box>

            <Box display="flex" sx={{ my: 16 }}>
              <Box>
                <Select sx={styles.chartTypeSelect} value={content?.chartType || "Manuall"} onChange={handleChangeChartType}>
                  <MenuItem value="Axxion">Axxion</MenuItem>
                  <MenuItem value="Manuall">Manuall</MenuItem>
                </Select>
              </Box>

              {content?.chartType === "Axxion" && (
                <Box sx={{ ml: '30px' }}>
                  <OutlinedInput value={content?.isin || ""} onChange={handleChangeIsin} placeholder="ISIN" />
                </Box>
              )}

              <Box sx={(theme) => ({ ...styles.axisSwitch(theme, content.isPercent), alignSelf: 'flex-end'})}>
                <Typography variant="body2" onClick={() => handleChangeAxisType(false)}>
                  Point
                </Typography>
                <Switch
                  checked={content.isPercent}
                  onChange={(e) => handleChangeAxisType(e.target.checked)}
                />
                <Typography variant="body2" color="secondary" onClick={() => handleChangeAxisType(true)}>
                  Percent
                </Typography>
              </Box>
            </Box>
            {content.chartType === "Axxion" ? (
              <Box>

              </Box>
              ) : (
              <Grid container spacing={30}>
                {content?.categories && content?.categories.map((item, index) => (
                  <Grid key={index} item container xs={12} sm={4} spacing={8} display="flex">
                    <Grid item xs={12} sm={10} display="flex">
                      <OutlinedInput
                        name="category"
                        fullWidth
                        value={item || ""}
                        onChange={(e) => handleChangeCategories(index, e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={1} display="flex">
                      <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveCategory(index)}>
                        <Remove />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12} sm={3}>
                  <Button fullWidth startIcon={<Add />} onClick={handleAddCategory}>
                    Add Category
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} />
                {content?.chartData && content.chartData.map((item, index) => (
                  <React.Fragment key={`${item.id}_${index}`}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ pl: 6, pb: 6 }}>Label:</Typography>
                      <OutlinedInput name="name" type="string" fullWidth value={item.name || ""} onChange={(e) => handleChangeLabel(e, index)} />
                    </Grid>
                    <Grid item xs={12} sm={1} display="flex">
                      <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveLine(index)}>
                        <Remove />
                      </IconButton>
                    </Grid>
                    <Grid xs={0} sm={5} />

                    {item.data.map((subItem, subIndex) => (
                      <Grid key={subIndex} item container xs={12} sm={4} spacing={8} display="flex">
                        <Grid item xs={12} sm={10} display="flex">
                          <OutlinedInput
                            name="data"
                            type="number"
                            fullWidth
                            value={subItem || ""}
                            onChange={(e) => handleChangeData(e, index, subIndex)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={1} display="flex">
                          <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveSubItem(index, subIndex)}>
                            <Remove />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={1}>
                      <IconButton size="medium" onClick={() => handleAddSubItem(index)}>
                        <Add />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Divider orientation="horizontal" />
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item container xs={12} sm={6} md={4} spacing={10}>
                  <Grid item xs={12} sm={6}>
                    <Button fullWidth startIcon={<Add />} onClick={handleAddData}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};
