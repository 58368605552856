// Resources
import { IStyles } from '../../../../../../resource';

// Define styles
const styles: IStyles = {
  accordion: (theme) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },

    '& .MuiAccordionSummary-expandIconWrapper': {
      position: 'absolute',
      right: theme.spacing(8)
    }
  }),
  accordionHeader: (theme) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
  accordionDetails: (theme) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }),
  widget: (theme, preview) => ({
    border: preview ? '0' : `1px solid ${theme.palette.gray.light}`,
    padding: preview ? '10px 0' : '20px',
    margin: '10px 0',
    borderRadius: '5px'
  }),
  titleInput: (theme) => ({
    marginLeft: '10px',
    '& input': {
      fontSize: '20px',
      border: 0,
      padding: '0 10px',
      minWidth: '300px',
      height: '30px',
      background: 'transparent',
      outline: 'none',
      borderBottom: `2px solid transparent`,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      },
      '&:focus': {
        outline: 'none',
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
      }
    }
  }),
};

// Export styles
export default styles;
