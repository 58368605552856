// Dependencies
import { FC } from 'react';
import { Button, ButtonBase, Divider, MenuItem, Select, Stack, ToggleButton } from '@mui/material';

// Components
import { ColorPicker } from '../../ColorPicker';

// Icons
import { AlignJustifyIcon, OrderListIcon, PlusIcon, SubtractIcon } from '../../../../assets/icons';

// Styles
import styles from './styles';

// Constants
const fontStyles = [
  {
    label: 'B',
    style: {
      fontWeight: 600,
    },
  },
  {
    label: 'I',
    style: {
      fontStyle: 'italic',
    },
  },
  {
    label: 'U',
    style: {
      textDecoration: 'underline',
    },
  },
  {
    label: 'T',
    style: {
      textDecoration: 'line-through',
    },
  },
];

// Export text panel
export const TextPanel: FC = () => {
  // Return text panel
  return (
    <Stack sx={styles.container} divider={<Divider sx={{ my: 20 }} />} spacing={20}>
      <Button fullWidth variant="varied-outlined">
        Add a text box
      </Button>
      <Stack spacing={20}>
        <Select>
          <MenuItem value="changa">Changa</MenuItem>
        </Select>
        <Stack direction="row" spacing={8}>
          {fontStyles.map(({ label, style }, index) => (
            // @ts-ignore
            <ToggleButton
              key={index}
              sx={{
                fontSize: { xs: 18, md: 24 },
                lineHeight: { xs: '12px', md: '16px' },
                fontFamily: 'Constantia',
                ...style,
              }}
            >
              {label}
            </ToggleButton>
          ))}
          <Stack direction="row">
            <ButtonBase sx={styles.decrease}>
              <SubtractIcon />
            </ButtonBase>
            <ButtonBase sx={styles.size}>12</ButtonBase>
            <ButtonBase sx={styles.increase}>
              <PlusIcon />
            </ButtonBase>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={8}>
        {/* @ts-ignore */}
        <ToggleButton>
          <AlignJustifyIcon />
        </ToggleButton>
        {/* @ts-ignore */}
        <ToggleButton>
          <OrderListIcon />
        </ToggleButton>
      </Stack>
      <ColorPicker />
    </Stack>
  );
};
