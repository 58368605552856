import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export const RegionenChart: FC = () => {
  const chartData = {
    series: [8, 32, 56, 4],
    options: {
      labels: [],
      colors: ['#0461B8', '#1B5993', '#33526F', '#4A4A4A'],
      chart: {
        width: 400,
        type: 'donut',
      },
      legend: {
        show: true,
        position: 'left',
        fontSize: '16px',
        fontFamily: 'Fira Sans',
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            legend: {
              position: 'bottom',
              width: '100%',
            },
          },
        },
      ],
    },
  };

  return (
    <div className="pie-chart-wrapper">
      <ReactApexChart options={chartData.options as ApexOptions} series={chartData.series} type="donut" />
    </div>
  );
};
