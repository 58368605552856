// Dependencies
import React, { FC, useState } from 'react';
import { Tab, Tabs, useMediaQuery } from '@mui/material';

// Panel
import { DocumentPanel, ESGPanel, PortfolioPanel, PortraitPanel } from './panels';

// Theme
import theme from '../../theme';
import './panels.css';
import { SolActivePanel } from './panels/SolActive';

// Interfaces
interface ITab {
  label: string;
  id: string | number;
  panel?: FC;
}

const tabs: ITab[] = [
  {
    label: 'PORTRAIT',
    id: 1,
    panel: PortraitPanel,
  },
  {
    label: 'WERTENTWICKLUNG',
    id: 2,
    panel: SolActivePanel,
  },
  {
    label: 'PORTFOLIO',
    id: 3,
    panel: PortfolioPanel,
  },
  {
    label: 'ESG',
    id: 4,
    panel: ESGPanel,
  },
  {
    label: 'DOKUMENTE',
    id: 5,
    panel: DocumentPanel,
  },
];

// Export fact-sheet age
export const FactSheetPage: FC = () => {
  // States
  const [activeTabId, setActiveTabId] = useState<number | string>(tabs[0].id);

  // Check window size
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  // Tab change handler
  const handleTabChange = (_: React.SyntheticEvent, activeId: number) => {
    setActiveTabId(activeId);
  };

  // Export fact-sheet page
  return (
    <>
      {/*<Tabs value={activeNavId} orientation={isXs ? 'vertical' : 'horizontal'}>*/}
      {/*  {navTabs.map(({ label, id }, index) => (*/}
      {/*    <Tab*/}
      {/*      key={index}*/}
      {/*      value={id}*/}
      {/*      label={label}*/}
      {/*      component={Link as React.ElementType}*/}
      {/*      to={`${ROUTES.PRODUCT.PREFIX}/${id}/fact-sheet`}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</Tabs>*/}
      <Tabs
        className="_Tabs"
        value={activeTabId}
        orientation={isSm ? 'vertical' : 'horizontal'}
        onChange={handleTabChange}
      >
        {tabs.map(({ label, id }, index) => (
          <Tab sx={{ fontWeight: 400 }} key={index} value={id} label={label} />
        ))}
      </Tabs>
      {tabs.map(({ id, panel: P }) => (
        <React.Fragment key={id}>{activeTabId === id && P && <P />}</React.Fragment>
      ))}
    </>
  );
};
