// Interfaces
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  container: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }),
  card: (theme) => ({
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(30, 0, 30, 0),
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ':hover': {
      bgcolor: theme.palette.secondary.main,
      boxShadow: theme.shadows[1],
      '.MuiTypography-root': {
        color: `${theme.palette.common.white} !important`,
      },
    },
  }),
  cardHeader: (theme) => ({
    p: theme.spacing(15, 16, 12),
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    flex: 1,
    '.MuiTypography-body2': {
      opacity: 0.5,
      mt: theme.spacing(12),
    },
    display: 'flex'
  }),
  thumbnail: () => ({
    minHeight: 368,
    maxHeight: 368,
    overflow: 'hidden',
    img: {
      width: '100%',
    },
  }),
  cardBox: () => ({
    marginTop: '0 !important',
  }),
  cardAction: (theme) => ({
    m: theme.spacing(16),
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 48,
  }),
  listItem: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    p: theme.spacing(15, 24, 16),
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(16, 0, 16, 0),
    ':hover': {
      bgcolor: theme.palette.secondary.main,
      boxShadow: theme.shadows[1],
      '.MuiTypography-root': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    '.MuiTypography-body2': {
      opacity: 0.5,
      mt: theme.spacing(1),
    },
  }),
  listAction: () => ({
    position: 'absolute',
    right: 24,
  }),
};

// Export styles
export default styles;
