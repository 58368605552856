// Dependencies
import { FC, ReactElement, useContext, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { CopyIcon } from '../../assets/icons';
import * as Yup from 'yup';

// Components
import { ResetPasswordDialog, UploadAvatarDialog } from '../../components';

// Icons
import {
  CameraIcon,
  CheckIcon,
  EmailIcon,
  EyeFilledIcon,
  EyeIcon,
  LocationIcon,
  MobileIcon,
  ProfileIcon,
} from '../../assets/icons';

// Interfaces
interface IField {
  name: string;
  label: string;
  type?: string;
  icon: ReactElement;
  endIcon?: ReactElement;
}

// Styles
import styles from './styles';
import { AppContext } from '../../context';
import apis from '../../apis';
import { useToastContext } from '../../components/Toast/toastContext';

// Constants
const initialFields: IField[] = [
  {
    name: 'firstName',
    label: 'First Name',
    icon: <ProfileIcon />,
  },
  {
    name: 'email',
    label: 'Email Address',
    icon: <EmailIcon />,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    icon: <ProfileIcon />,
  },
  {
    name: 'address',
    label: 'Address',
    icon: <LocationIcon />,
  },
  {
    name: 'phoneNumber',
    label: 'Phone',
    icon: <MobileIcon />,
  },
];

// Validation schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required Field!'),
  lastName: Yup.string().required('Required Field!'),
  address: Yup.string().required('Required Field!'),
  phoneNumber: Yup.string().required('Required Field!'),
  email: Yup.string().email('Invalid Field!').required('Required Field!'),
});

// Export profile page
export const ProfilePage: FC = () => {
  // States
  const [fields, setFields] = useState<IField[]>(initialFields);
  const [visibleUploadAvatarDialog, setVisibleUploadAvatarDialog] = useState<boolean>(false);
  const [visibleResetPasswordDialog, setVisibleResetPasswordDialog] = useState<boolean>(false);

  const { account, getMe } = useContext(AppContext);
  const { activeToast } = useToastContext();

  // Submit handler
  const handleSubmit = (values: any) => {
    apis.auth
      .editProfile({
        id: account?.id,
        ...values,
      })
      .then((res) => {
        activeToast({
          type: 'success',
          text: res.msg,
        });
        getMe();
      })
      .catch((err) => {
        activeToast({
          type: 'error',
          text: err.msg,
        });
      });
  };

  // Open upload avatar dialog handler
  const handleOpenAvatarDialog = () => {
    setVisibleUploadAvatarDialog(true);
  };

  // Close upload avatar dialog handler
  const handleCloseAvatarDialog = () => {
    setVisibleUploadAvatarDialog(false);
  };

  // Open upload avatar dialog handler
  const handleOpenResetDialog = () => {
    setVisibleResetPasswordDialog(true);
  };

  // Close upload avatar dialog handler
  const handleCloseResetDialog = () => {
    setVisibleResetPasswordDialog(false);
  };

  // Toggle visible handler
  const handleToggleVisible = (name: string) => () => {
    setFields(
      fields.map((field) => {
        if (field.name === name) {
          if (field.type === 'password') {
            field.type = 'text';
            field.endIcon = <EyeIcon />;
          } else {
            field.type = 'password';
            field.endIcon = <EyeFilledIcon />;
          }
        }
        return field;
      })
    );
  };

  // Copy customer id
  const handleCopyCustomerId = () => {
    if (account?.id) {
      navigator.clipboard.writeText(account.id);
    }
  };

  const initialValues: any = useMemo(() => {
    return {
      firstName: account?.firstName || '',
      lastName: account?.lastName || '',
      address: account?.address || '',
      phone: account?.phone || '',
      email: account?.email || '',
      avatar: account?.avatar || '',
      currentPassword: '',
      password: '',
      confirmNewPassword: '',
    };
  }, []);

  // Return profile page
  return (
    <Box sx={styles.container}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Stack maxWidth={926} alignItems="center" component={Form} onSubmit={handleSubmit}>
            <Stack direction="row" justifyContent="center" alignItems="center" mb={16} spacing={16}>
              <Stack direction={{ xs: 'column', md: 'row' }}>
                <Typography sx={{ mr: 4 }} variant="h6">
                  Customer ID:{' '}
                </Typography>
                <Typography sx={{ mr: 8 }} variant="h6">
                  {account?.id}
                </Typography>
              </Stack>
              <IconButton onClick={handleCopyCustomerId}>
                <CopyIcon />
              </IconButton>
            </Stack>
            <Box sx={styles.avatar} onClick={handleOpenAvatarDialog}>
              <Avatar
                src={
                  typeof values.avatar === 'string'
                    ? values.avatar || '/images/avatar.png'
                    : URL.createObjectURL(values.avatar)
                }
              />
              <Box sx={styles.camera}>
                <CameraIcon />
              </Box>
            </Box>
            <Grid container columns={2} rowSpacing={{ xs: 20, md: 35 }} columnSpacing={86}>
              {fields.map(({ name, label, type, icon, endIcon }, index) => (
                <Grid item xs={2} sm={1} key={index}>
                  <TextField
                    fullWidth
                    name={name}
                    label={label}
                    value={values[name]}
                    type={type || 'text'}
                    error={Boolean(errors[name] && touched[name])}
                    helperText={Boolean(errors[name] && touched[name]) && (errors[name] as string)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                      endAdornment: endIcon && (
                        <InputAdornment position="end">
                          {/* @ts-ignore */}
                          <IconButton variant="text" size="medium" onClick={handleToggleVisible(name)}>
                            {endIcon}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: (theme) => ({
                        ...theme.typography.body2,
                      }),
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              ))}
              <Typography
                mt={50}
                ml={80}
                justifyContent={'center'}
                alignItems={'center'}
                color={'#bf0a76'}
                variant="body2"
                onClick={handleOpenResetDialog}
              >
                Reset Password
              </Typography>
            </Grid>
            <Stack mt={{ xs: 50, md: 70, xl: 94 }} width={420} spacing={8}>
              <Button fullWidth type="submit" variant="varied-contained" startIcon={<CheckIcon />}>
                Apply
              </Button>
              <Button variant="text">Discard</Button>
            </Stack>
            <UploadAvatarDialog
              avatar={values.avatar}
              open={visibleUploadAvatarDialog}
              onClose={handleCloseAvatarDialog}
              onChange={(value) => setFieldValue('avatar', value)}
            />
          </Stack>
        )}
      </Formik>
      <ResetPasswordDialog open={visibleResetPasswordDialog} onClose={handleCloseResetDialog} id={account?.id} />
    </Box>
  );
};
