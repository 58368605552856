// Dependencies
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Avatar, Pagination, Stack, CircularProgress, Box , Chip} from '@mui/material';

// Context
import { AppContext } from '../../../context';

// Components
import { IColumn, Table, UserDialog } from '../../../components';

// Resources
import { ISortBy, ORDER, ROLE } from '../../../resource';
import apis from '../../../apis';
import { ACCESS_ADMIN_TOKEN_KEY, ACCESS_TOKEN_KEY } from '../../../contants';
import { useToastContext } from '../../../components/Toast/toastContext';
import { ContentCopy, Check } from '@mui/icons-material';
import {useDebounce} from '../../../hooks/useDebounce';

// Styles
// import styles from './styles';

// Constants
const pageLimit = 15;

let timeoutId;

// Export user list page
export const UserListPage: FC = () => {
  // States
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<ISortBy>({
    field: 'firstName',
    order: ORDER.ASC,
  });
  const [loginAsLoading, setLoginAsLoading] = useState<string | null>(null);
  const [copiedId, setCopiedId] = useState<string | null>(null);

  // Context
  const {
    account,
    setAccount,
    dialogs,
    user,
    users,
    selectedUsers,
    search
  } = useContext(AppContext);
  const debouncedSearch = useDebounce(search);

  const { activeToast } = useToastContext();

  useEffect(() => {
    if (copiedId) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setCopiedId(null);
      }, 5000);
    }
  }, [copiedId]);

  // Define columns
  const columns: IColumn[] = [
    {
      field: 'profile',
      label: 'Profile',
      sortable: false,
      render: ({ avatar }) => <Avatar src={avatar} />,
    },
    {
      field: 'firstName',
      label: 'Name',
      render: ({ firstName, lastName }) => `${firstName || ''} ${lastName || ''}`,
    },
    {
      field: 'email',
      label: 'Email',
    },
    {
      field: 'id',
      label: 'Customer ID',
      render: ({ id }) => {
        return (
          <Box display="flex" alignItems="center">
            <Chip label={id} />
            <Box ml={5} sx={{ cursor: 'pointer' }} onClick={() => handleCopyCustomerId(id)}>
              {copiedId === id ? <Check color="success" /> : <ContentCopy width={6} height={6} />}
            </Box>
          </Box>
        )
      }
    },
    {
      field: 'role',
      label: 'Access Level',
      render: (row) => (row.role === ROLE.ADMIN ? 'Administrator' : 'Customer'),
    },
    {
      field: '',
      label: 'Login As',
      sortable: false,
      render: (row) => (
        <Button size="small" sx={{ minWidth: 110 }} disabled={account?.id === row.id} onClick={() => handleLoginAs(row.id)}>
          {loginAsLoading && loginAsLoading === row.id && <CircularProgress size={16} sx={{ mr: 2, color: 'white' }} />}
          Login As
        </Button>
      )
    },
  ];

  // Calc page count
  const pageCnt = useMemo(() => {
    return Math.ceil((users.pagination?.total || 0) / pageLimit);
  }, [users.pagination]);

  const handleCopyCustomerId = (id: string) => {
    setCopiedId(id);
    navigator.clipboard.writeText(id);
  };

  // Login as
  const handleLoginAs = (id: string) => {
    setLoginAsLoading(id);
    apis.auth
      .adminLoginAsUser(id)
      .then((res) => {
        setLoginAsLoading(null);
        const { user, token } = res;
        setAccount(user);
        const adminToken = localStorage.getItem(ACCESS_TOKEN_KEY) || "";
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        localStorage.setItem(ACCESS_ADMIN_TOKEN_KEY, adminToken);
        activeToast({
          type: 'success',
          text: `Logged in as ${user.email}`,
        });
      })
      .catch((err) => {
        activeToast({
          type: 'error',
          text: err.msg || 'Failed to login',
        });
      })
      .finally(() => {
        setLoginAsLoading(null);
      })
  };

  // Fetch data
  const fetchData = () => {
    users.fetchData({
      options: {
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
        sort: {
          [sort.field]: sort.order,
        },
        search: debouncedSearch,
      },
    });
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSort(value);
  };

  // Close user dialog handler
  const handleCloseUserDialog = () => {
    dialogs.user.setVisible(false);
  };

  // Selected change handler
  const handleChangeSelected = (selected: (string | number)[]) => {
    selectedUsers.setValue(selected as string[]);
  };

  // Page change handler
  const handlePageChange = (_, page: number) => {
    setPageNumber(page);
    selectedUsers.setValue([]);
  };

  // On mounted
  useEffect(() => {
    fetchData();

    // On unmounted
    return () => {
      selectedUsers.setValue([]);
      dialogs.user.setVisible(false);
    };
  }, []);

  // On page number changed
  useEffect(() => {
    fetchData();
  }, [pageNumber, sort, debouncedSearch]);

  // Return user list page
  return (
    <>
      <Stack alignItems="center" spacing={{ xs: 36, md: 66 }}>
        <Table
          stickyHeader
          rowSelectable
          columns={columns}
          data={users.value}
          order={sort.order}
          orderBy={sort.field}
          isLoading={users.isLoading}
          selected={selectedUsers.value}
          contentSize={{
            width: {
              xs: 'calc(100vw - 40px)',
              sm: 'calc(100vw - 80px)',
              md: 'calc(100vw - 256px)',
              lg: 'calc(100vw - 376px)',
            },
          }}
          onSort={handleSort}
          onChangeSelected={handleChangeSelected}
        />
        {users.pagination && pageCnt > 1 && (
          <Pagination page={pageNumber} count={pageCnt} onChange={handlePageChange} />
        )}
      </Stack>
      {dialogs.user.visible && (
        <UserDialog open={dialogs.user.visible} user={user.value} onClose={handleCloseUserDialog} />
      )}
    </>
  );
};
