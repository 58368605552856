// Dependencies
import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import Header from './Header';

// Styles
import styles from './styles';

// Interfaces
interface IAuthLayoutProps {
  children: ReactNode;
}

// Export auth layout
export const AuthLayout: FC<IAuthLayoutProps> = ({ children }) => {
  // Return auth layout
  return (
    <Box sx={styles.layout}>
      <Header />
      <Box component={PerfectScrollbar} sx={styles.content}>
        {children}
      </Box>
    </Box>
  );
};
