// Resources
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  container: () => ({
    display: 'flex',
    justifyContent: 'center',
  }),
  avatar: (theme) => ({
    cursor: 'pointer',
    borderRadius: 114,
    mb: theme.spacing(73),
    position: 'relative',
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    ':hover': {
      '.MuiBox-root': {
        transform: 'translateY(0)',
      },
    },
    '.MuiAvatar-root': {
      width: 228,
      height: 228,
    },
  }),
  camera: (theme) => ({
    height: 57,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
    transitionDuration: '500ms',
    opacity: 0.8,
    backgroundColor: theme.palette.common.white,
    transform: 'translateY(100%)',
  }),
};

// Export styles
export default styles;
