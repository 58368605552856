// Dependencies
import { useNavigate } from 'react-router-dom';
import { FC, ReactNode, useContext, useState } from 'react';
import { CircularProgress, IconButton, Stack } from '@mui/material';

// Components
import { DoneDialog, PreviewDocDialog, PublishDialog } from '../Dialogs';

// Context
import { AppContext } from '../../context';

// Icons
import { CopyIcon, DeleteIcon, DownloadIcon, EyeIcon, MoreIcon, PencilIcon, PublishIcon } from '../../assets/icons';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// Constants
import { ROUTES } from '../../contants';

// Apis
import apis from '../../apis';

// Styles
import styles from './styles';

// Interfaces
import { IDocument } from '../../resource';
import { useToastContext } from '../Toast/toastContext';
import {removeForeverDocument} from '../../apis/pdf.apis';
import {useAlertContext} from '../Alert/alertContext';

interface IAction {
  icon: ReactNode;
  handler: () => any;
}

interface IDocActionsProps {
  doc: IDocument;
  direction?: 'vertical' | 'horizontal';
  archived?: boolean;
  fetch?: () => void;
}

// Export doc actions
export const DocActions: FC<IDocActionsProps> = ({ doc, direction = 'horizontal', archived, fetch }) => {
  // States
  const [visibleActions, setVisibleActions] = useState<boolean>(false);
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [visiblePublishDialog, setVisiblePublishDialog] = useState<boolean>(false);
  const [visibleSuccessDialog, setVisibleSuccessDialog] = useState<boolean>(false);
  const [previewPdf, setPreviewPdf] = useState<string | null>(null);
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  // Get navigate from hook
  const navigate = useNavigate();

  // Get context
  const { account, fetchDocuments } = useContext(AppContext);
  const { activeToast } = useToastContext();
  const { activeAlert } = useAlertContext();

  // Open publish dialog handler
  const handleOpenPublishDialog = () => {
    setSelectedDocId(doc._id);
    setVisiblePublishDialog(true);
  };

  // Download pdf document
  const handleDownloadDocument = async () => {
    setIsDownloading(true);
    apis.pdf
      .downloadPdf(doc.file_url)
      .then((res) => {
        const fileURL = window.URL.createObjectURL(res.data);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = doc.name;
        alink.click();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  // Duplicate
  const handleDuplicate = () => {
    if (account?.id) {
      setIsDuplicating(true);
      apis.pdf
        .duplicateDocument(account.id, doc._id)
        .then(() => {
          fetchDocuments(account?.id);
          activeToast({
            type: 'success',
            text: 'Document duplicated successfully!',
          });
        })
        .finally(() => {
          setIsDuplicating(false);
        });
    }
  };

  // Hover handler
  const handleToggleActions = () => {
    setVisibleActions(!visibleActions);
  };

  // Remove button click handler
  const handleRemoveClick = () => {
    if (account?.id) {
      setSelectedDocId(doc._id);
      setIsRemoving(true);
      apis.pdf
        .removeDocument(account?.id, doc._id)
        .then(() => {
          fetch && fetch();
          activeToast({
            type: 'success',
            text: 'Document archived successfully!',
          });
        })
        .finally(() => {
          setIsRemoving(false);
        });
    }
  };

  // Edit handler
  const handleEdit = () => {
    navigate(ROUTES.PDF_VIEWER.replace(':id', doc._id));
  };

  const handleOpenPreviewModal: any = () => {
    setPreviewPdf(doc.file_url);
  };

  // Close publish dialog handler
  const handleClosePublishDialog = () => {
    setVisiblePublishDialog(false);
    setSelectedDocId(null);
  };

  // Open success dialog handler
  const handleOpenSuccessDialog = () => {
    setVisibleSuccessDialog(true);
  };

  // Close success dialog handler
  const handleCloseSuccessDialog = () => {
    setVisibleSuccessDialog(false);
  };

  // View document handler
  const handleViewDocument = () => {
    setVisibleSuccessDialog(false);
  };

  // Close preview modal
  const handleClosePreviewModal = () => {
    setPreviewPdf(null);
  };

  const handleRestoreDocument = () => {
    if (account?.id) {
      setSelectedDocId(doc._id);
      setIsRemoving(true);
      apis.pdf
      .restoreDocument(account?.id, doc._id)
      .then(() => {
        fetch && fetch();
        activeToast({
          type: 'success',
          text: 'Document restored successfully!',
        });
      })
      .finally(() => {
        setIsRemoving(false);
      });
    }
  };

  const handleDeleteForeverDocument = () => {
    activeAlert({
      type: 'info',
      text: "Are you sure to remove this document?",
      description: " Once it's removed you cannot revert it.",
    }, () => {
      if (account?.id) {
        setSelectedDocId(doc._id);
        setIsRemoving(true);
        apis.pdf
        .removeForeverDocument(account?.id, doc._id)
        .then(() => {
          fetch && fetch();
          activeToast({
            type: 'success',
            text: 'Document removed successfully!',
          });
        })
        .finally(() => {
          setIsRemoving(false);
        });
      }
    });
  };

  // Define actions
  const actions: IAction[] = archived ? [
    {
      icon: <RefreshOutlinedIcon />,
      handler: handleRestoreDocument,
    },
    {
      icon: <DeleteForeverIcon />,
      handler: handleDeleteForeverDocument,
    },
    {
      icon: <EyeIcon />,
      handler: handleOpenPreviewModal,
    }
  ] : [
    {
      icon: <PublishIcon />,
      handler: handleOpenPublishDialog,
    },
    {
      icon: isDuplicating ? <CircularProgress size={28} color="inherit" /> : <CopyIcon />,
      handler: handleDuplicate,
    },
    {
      icon: isRemoving ? <CircularProgress size={28} color="inherit" /> : <DeleteIcon />,
      handler: handleRemoveClick,
    },
    {
      icon: isDownloading ? <CircularProgress size={28} color="inherit" /> : <DownloadIcon />,
      handler: handleDownloadDocument,
    },
    {
      icon: <PencilIcon />,
      handler: handleEdit,
    },
    {
      icon: <EyeIcon />,
      handler: handleOpenPreviewModal,
    },
  ];

  // Return doc actions
  return (
    <>
      <Stack
        direction={direction === 'horizontal' ? 'row' : 'column'}
        sx={(theme) => styles.container(theme, { direction })}
      >
        <Stack
          direction={direction === 'horizontal' ? 'row' : 'column'}
          spacing={8}
          sx={(theme) => styles.actions(theme, { visible: visibleActions, direction, archived })}
        >
          {actions.map(({ icon, handler }, aIndex) => (
            <IconButton key={aIndex} size="medium" sx={styles.button} onClick={handler}>
              {icon}
            </IconButton>
          ))}
        </Stack>
        <IconButton size="medium" sx={styles.button} onClick={handleToggleActions}>
          <MoreIcon />
        </IconButton>
      </Stack>
      {visiblePublishDialog && selectedDocId && (
        <PublishDialog
          open={visiblePublishDialog}
          doc={doc}
          docId={selectedDocId}
          onClose={handleClosePublishDialog}
          onPublish={handleOpenSuccessDialog}
        />
      )}
      {visibleSuccessDialog && (
        <DoneDialog
          open={visibleSuccessDialog}
          title="Well Done"
          description="You have successfully published this document."
          next="View Document"
          onNext={handleViewDocument}
          onClose={handleCloseSuccessDialog}
        />
      )}
      {previewPdf && <PreviewDocDialog open={!!previewPdf} previewPdf={previewPdf} onClose={handleClosePreviewModal} />}
    </>
  );
};
