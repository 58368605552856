import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export const ControversialLevelsChart: FC = () => {
  const series = [
    {
      name: 'Kontroversenlevels',
      data: [16, 40, 32, 12, 0],
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'bottom',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['1', '2', '3', '4', '5'],
    },
    yaxis: {
      title: {
        text: 'Gewicht im Portfolio',
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#003F7A'],
  };

  return (
    <>
      <ReactApexChart type="bar" height={350} options={options} series={series} />
    </>
  );
};
