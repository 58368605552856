// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  container: (theme) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.gray.light,
    backgroundImage: 'url(/images/sign-up-bg.png)',
    backgroundPosition: theme.spacing(-170, -275),
    backgroundBlendMode: 'overlay',
    minHeight: 600,
    px: { xs: theme.spacing(20), sm: theme.spacing(80), md: theme.spacing(140) },
  }),
  content: () => ({
    width: '100%',
    maxWidth: 420,
  }),
};

// Export styles
export default styles;
