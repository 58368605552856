// Resources
import { IStyles } from '../../../../../resource';

// Define styles
const styles: IStyles = {
  widgetButton: () => ({
    width: '100%',
    padding: '40px 0',
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0',
    }
  }),
  widget: (theme, { preview, padding }) => ({
    position: 'relative',
    background: 'white',
    border: preview ? '0' : `1px solid ${theme.palette.gray.light}`,
    padding: (padding && preview) ? '0 0' : '8px 8px',
    margin: '10px 0',
    borderRadius: '5px',
    '& > MuiButtonBase-root': {
      display: 'none',
    },
    '&:hover': {
      border: `1px solid ${theme.palette.gray.dark}`,
      '& > MuiButtonBase-root': {
        display: 'block',
      }
    }
  }),
  remove: () => ({
    position: 'absolute',
    width: '32px',
    height: '32px',
    top: '16px',
    right: '16px',
    border: '0',
    'svg': {
      width: '20px',
      height: '20px',
    }
  }),
  dragIndicator: (theme) => ({
    position: 'absolute',
    background: 'white',
    width: 'fit-content',
    border: `1px solid ${theme.palette.gray.light}`,
    borderRight: '0',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
    left: '-28px',
    top: '10px',
    display: 'flex',
    alignItems: 'center'
  }),
  draggingWidget: (theme) => ({
    zIndex: 10,
    background: 'white',
    border: `1px solid ${theme.palette.gray.light}`,
    padding: '14px 8px',
    borderRadius: '5px',
    textTransform: 'uppercase',
    fontWeight: 600,
  }),
};

// Export styles
export default styles;
