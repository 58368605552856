// Dependencies
import { alpha, Theme } from '@mui/material';

// Styles
const styles = {
  draggable: () => ({
    overflow: 'hidden',
    position: 'absolute',
    '&:hover': {
      '.handle': {
        transform: 'translateX(0)',
      },
    },
  }),
  handle: (theme: Theme) => ({
    position: 'absolute',
    bgcolor: alpha(theme.palette.common.black, 0.2),
    zIndex: 1,
    cursor: 'move',
    transform: 'translateX(-102%)',
    transitionDuration: '0.2s',
  }),
};

// Export styles
export default styles;
