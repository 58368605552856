import React from 'react';
import { Box, Button, Grid, IconButton, OutlinedInput, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

export type Document = { label: string, url: string };

type Props = {
  id: number;
  content: Document[];
  onChange: (id: number, content: Document[]) => void;
}

export const DocumentList = ({ id, content, onChange }: Props) => {
  const handleChangeList = (e, index) => {
    onChange(id, content.map((p, i) => index === i ? { ...p, [e.target.name]: e.target.value } : p));
  };

  const handleAddDocument = () => {
    onChange(id, [...content, { label: '', url: '' }]);
  };

  const handleRemoveLine = (index: number) => {
    onChange(id, content.slice(0, index).concat(content.slice(index + 1)));
  };

  return (
    <Box>
      <Grid container spacing={30}>
        {content?.map((document, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={5}>
              <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>Label:</Typography>
              <OutlinedInput name="label" type="string" fullWidth value={document.label || ""} onChange={(e) => handleChangeList(e, index)} />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>Url:</Typography>
              <OutlinedInput name="url" type="string" fullWidth value={document.url || ""} onChange={(e) => handleChangeList(e, index)} />
            </Grid>
            <Grid item xs={12} sm={1} display="flex">
              <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveLine(index)}>
                <Remove />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
        <Grid item container xs={12} sm={6} md={4} spacing={10}>
          <Grid item xs={12} sm={6}>
            <Button fullWidth startIcon={<Add />} onClick={handleAddDocument}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
