// Dependencies
import { FC } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

// Global constants
import { ROUTES } from '../../../contants';

// Styles
import styles from './styles';

// Export login page
export const LoginPage: FC = () => {
  // Return login page
  return (
    <Box sx={styles.container}>
      <Grid mb={{ xs: 0, lg: 72 }} container columns={2} spacing={80} alignItems="center">
        <Grid item xs={2} lg={1}>
          <Typography variant="display" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
            Welcome to CIXON Finance Software
          </Typography>
        </Grid>
        <Grid item xs={0} lg={1}>
          <Box sx={styles.landLogo}>
            <img src="/images/landing_new.jpg" alt="landing" />
          </Box>
        </Grid>
      </Grid>
      <Stack spacing={16} sx={styles.actions}>
        <Button fullWidth variant="outlined" startIcon={<img src="/icons/google.svg" alt="google" />}>
          Continue with Google
        </Button>
        <Typography variant="caption" textAlign="center">
          Don't have an account?{' '}
          <Typography variant="caption" color="secondary" component={Link} to={ROUTES.AUTH.SIGN_UP}>
            Sign Up
          </Typography>
        </Typography>
      </Stack>
    </Box>
  );
};
