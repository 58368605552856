// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    display: 'flex',
    p: theme.spacing(24),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(30, 0),
    maxWidth: { xs: 340, sm: 720, md: 780, lg: 952 },
    width: 1,
    position: 'relative',
    flexDirection: { xs: 'column', sm: 'row' },
  }),
  action: (theme) => ({
    flex: 1,
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  }),
};

// Export styles
export default styles;
