// Interfaces
import { IStyles } from '../../resource';

// Styles
const styles: IStyles = {
  paper: (theme) => ({
    p: theme.spacing(20),
  }),
  intro: (theme) => ({
    p: theme.spacing(0, 20),
    backgroundColor: '#d2d2d4',
    m: theme.spacing(70, 0, 50),
    cursor: 'pointer',
    '&:hover': {
      background: '#c3c3c3',
    }
  }),
  subIntro: () => ({
    cursor: 'pointer',
    '&:hover': {
      background: '#c3c3c3',
    }
  }),
  introImage: () => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    img: {
      background: 'transparent !important',
      width: '100%',
    },
  }),
  title: (theme, params) => ({
    flex: 1,
    input: {
      fontWeight: 900,
      fontSize: '32px',
      padding: `8px ${params?.active ? '20px' : '0'}`,
      border: `2px solid ${params?.active ? theme.palette.secondary.main : 'transparent'}`,
      borderRadius: params?.active ? '50px' : 0,
      background: 'transparent',
      outline: 'none',
      cursor: params?.active ? 'text' : 'pointer',
      width: '100%',
      boxSizing: 'border-box',
    },

    '&:hover': {
      input: {
        background: params?.active ? 'transparent' : 'lightgrey',
      }
    }
  }),
  actions: () => ({
    zIndex: 10,
    position: 'fixed',
    top: '28px',
    right: '144px',
    display: "flex",
    gap: "4px",
    justifyContent: "flex-end",
    marginTop: "8px"
  }),
  widget: (theme) => ({
    mb: theme.spacing(40),
  }),
  divider: () => ({
    borderColor: '#a7a8ab',
  }),
  blackDivider: () => ({
    borderColor: '#000000'
  }),
  contact: () => ({
    cursor: 'pointer',
    width: 250,
    padding: 20,
    '&:hover': {
      background: '#c3c3c3',
    }
  }),
  socialSites: () => ({
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 20,
    '&:hover': {
      background: '#c3c3c3',
    }
  }),
  chartsLabel: (theme, templateColor) => ({
    mb: theme.spacing(10),
    borderBottom: '1px solid #a7a8ab',
    borderTop: '1px solid #a7a8ab',
    cursor: 'pointer',
    span: {
      color: templateColor || '#003f7a',
      marginRight: '8px',
      fontWeight: 'bold',
    },
    '&:hover': {
      background: '#c3c3c3',
    },
    color: '#000'
  }),
  chartsContent: () => ({
    cursor: 'pointer'
  }),
  textEditorWrapper: (theme) => ({
    textarea: {
      minWidth: 700,
      lineHeight: 1.5,
      padding: 10,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: '10px',
      fontSize: '14px',
      height: 200,
      flex: 1,
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
    }
  }),
  textEditor: () => ({
    lineHeight: 1.5,
    minWidth: 700,
    height: 300,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#e5e5e5',
      borderRadius: '6px',
    },

    '::-webkit-scrollbar-button': {
      display: 'none',
    },
  }),
  introEditor: (theme) => ({
    display: 'flex',
    padding: 10,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '10px',
    minWidth: 1000,
    textarea: {
      fontSize: '14px',
      height: 200,
      flex: 1,
      outline: 'none',
      border: 0,
      '&:focus': {
        outline: 'none',
      },

      '::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },

      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: '#e5e5e5',
        borderRadius: '6px',
      },

      '::-webkit-scrollbar-button': {
        display: 'none',
      },
    },

    '&;focus': {
      border: `1px solid ${theme.palette.secondary.main}`,
    }
  }),
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: theme.spacing(10, 16),
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    flexDirection: 'column',
  }),
  businessTable: () => ({
    marginTop: '-10px',
  }),
  businessTableHeaderCell: () => ({
    padding: '8px 0',
    background: '#D2D2D4',
    '& .MuiTypography-root': {
      color: '#484848 !important',
      fontWeight: 600,
    },
    borderBottom: '1px solid #949599',
  }),
  businessTableBodyCell: () => ({
    padding: '8px 0',
    img: {
      width: '100%'
    },
    borderBottom: '1px solid #949599',
    '& .MuiTypography-root': {
      color: '#484848 !important',
      fontWeight: 600,
    },
  }),
  removeLogo: () => ({
    border: '0',
    background: 'transparent',
    width: 100,
    height: 100,
    position: 'absolute',
    top: '20px',
    left: '20px',
    borderRadius: '50%',
    color: 'transparent',
    '&:hover': {
      background: '#ff00ff40',
      color: '#fff',
    }
  }),
  logoUploader: () => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  }),
  logo: () => ({
    width: 100,
    height: 100,
  }),
  site: (theme) => ({
    border: `1px solid transparent`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      borderColor: theme.palette.secondary.main
    }
  }),
  imageCell: () => ({
    textAlign: 'right'
  }),
  esgImage: (theme) => ({
    overflow: 'hidden',
    border: 'none',
    '&:hover': {
      background: 'white',
      border: `1px solid ${theme.palette.secondary.main}`
    },
    width: '48px',
    height: '48px',
    img: {
      width: '52px',
      height: '52px',
      objectFit: 'contain'
    }
  }),
  deleteIcon: () => ({
    width: '32px',
    height: '32px',
    border: 'none',
    boxShadow: 'none',
    svg: {
      width: '20px',
      height: '20px'
    }
  }),
};

// Export styles
export default styles;
