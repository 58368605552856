// Dependencies
import { createContext, FC, ReactNode, useMemo, useState } from 'react';

// Resources
import {
  DisplayMode,
  IAccount,
  ICustomerDocument,
  IDocument,
  IEsgAreaDocument,
  IFilter,
  IPagination,
  ITable,
  ROLE
} from '../resource';

// Apis
import apis from '../apis';
import {useAlertContext} from '../components/Alert/alertContext';
import {useToastContext} from '../components/Toast/toastContext';

// Interfaces
interface IAppProviderProps {
  children: ReactNode;
}

export interface IAppContext {
  isAdmin: boolean,
  account: IAccount | null;
  setAccount: (account: IAccount | null) => void;
  search: string;
  setSearch: (search: string) => void;
  displayMode: DisplayMode;
  setDisplayMode: (displayMode: DisplayMode) => void;
  showDeleted: boolean;
  setShowDeleted: (showDeleted: boolean) => void;
  dialogs: {
    [key: string]: {
      visible: boolean;
      setVisible: (visible: boolean) => void;
    };
  };
  chart: {
    table: {
      value: ITable;
      setValue: (value: ITable) => void;
    };
  };
  getMe: () => void;
  document: any,
  setDocument: (value: any) => void;
  documents: IDocument[];
  docTotalCount: number;
  setDocTotalCount: (value: number) => void;
  documentsLoading: boolean;
  setDocuments: (documents: IDocument[]) => void;
  fetchDocuments: (id: string, page?: number, limit?: number, showDeleted?: boolean, callback?: (msg?: string) => void) => void;
  user: {
    value?: IAccount;
    setValue: (user?: IAccount) => void;
  };
  users: {
    value: IAccount[];
    setValue: (users: IAccount[]) => void;
    isLoading: boolean;
    pagination?: IPagination;
    fetchData: (filter?: IFilter) => void;
  };
  selectedUsers: {
    value: string[];
    setValue: (users: string[]) => void;
  };
  customerDoc: {
    value?: ICustomerDocument;
    setValue: (doc?: ICustomerDocument) => void;
  };
  customerDocs: {
    value: ICustomerDocument[];
    setValue: (docs: ICustomerDocument[]) => void;
    selected: string[];
    setSelected: (docs: string[]) => void;
    isLoading: boolean;
    pagination?: IPagination;
    fetchData: (customerId: string, filter?: IFilter) => void;
  };
  esgDoc: {
    value?: ICustomerDocument;
    setValue: (doc?: ICustomerDocument) => void;
  };
  esgDocs: {
    value: ICustomerDocument[];
    setValue: (docs: ICustomerDocument[]) => void;
    selected: string[];
    setSelected: (docs: string[]) => void;
    isLoading: boolean;
    pagination?: IPagination;
    fetchData: (customerId: string, filter?: IFilter) => void;
    esgDocAllowed: boolean;
    setEsgDocAllowed: (value: boolean) => void;
  };
  esgAreaDocs: {
    value: IEsgAreaDocument[];
    setValue: (docs: IEsgAreaDocument[]) => void;
    isLoading: boolean;
    pagination?: IPagination;
    fetchData: (customerId: string, filter?: IFilter) => void;
  };
}

// Export app context
export const AppContext = createContext<IAppContext>({} as IAppContext);

// Create context provider
const AppProvider: FC<IAppProviderProps> = ({ children }) => {
  // States
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.Module);
  const [showDeleted, setShowDeleted] = useState(false);
  const [account, setAccount] = useState<IAccount | null>(null);
  const [visibleLoginDialog, setVisibleLoginDialog] = useState<boolean>(false);
  const [visibleNewDocDialog, setVisibleNewDocDialog] = useState<boolean>(false);
  const [visibleUserDialog, setVisibleUserDialog] = useState<boolean>(false);
  const [tableValue, setTableValue] = useState<{ row: number; column: number }>({ row: 0, column: 0 });
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [docTotalCount, setDocTotalCount] = useState(0);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IAccount[]>([]);
  const [usersPagination, setUsersPagination] = useState<IPagination>();
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [customerDocs, setCustomerDocs] = useState<ICustomerDocument[]>([]);
  const [isCustomerDocsLoading, setIsCustomerDocsLoading] = useState<boolean>(false);
  const [customerDocsPagination, setCustomerDocsPagination] = useState<IPagination>();
  const [selectedCustomerDocs, setSelectedCustomerDocs] = useState<string[]>([]);
  const [customerDoc, setCustomerDoc] = useState<ICustomerDocument>();
  const [esgDocs, setEsgDocs] = useState<ICustomerDocument[]>([]);
  const [isEsgDocsLoading, setIsEsgDocsLoading] = useState<boolean>(false);
  const [esgDocsPagination, setEsgDocsPagination] = useState<IPagination>();
  const [selectedEsgDocs, setSelectedEsgDocs] = useState<string[]>([]);
  const [esgDoc, setEsgDoc] = useState<ICustomerDocument>();
  const [esgDocAllowed, setEsgDocAllowed] = useState<boolean>(false);
  const [esgAreaDocs, setEsgAreaDocs] = useState<IEsgAreaDocument[]>([]);
  const [isEsgAreaDocsLoading, setIsEsgAreaDocsLoading] = useState<boolean>(false);
  const [esgAreaDocsPagination, setEsgAreaDocsPagination] = useState<IPagination>();
  const [user, setUser] = useState<IAccount>();
  const [visibleCustomerDocDialog, setVisibleCustomerDocDialog] = useState<boolean>(false);
  const [visibleEsgDocDialog, setVisibleEsgDocDialog] = useState<boolean>(false);
  const [visibleEsgAreaDialog, setVisibleEsgAreaDialog] = useState<boolean>(false);
  const [document, setDocument] = useState<any>(null);
  const [search, setSearch] = useState("");

  const isAdmin = useMemo(() => account?.role === ROLE.ADMIN, [account])

  // Define value
  const value = {
    isAdmin,
    account,
    setAccount,
    displayMode,
    setDisplayMode,
    showDeleted,
    setShowDeleted,
    search,
    setSearch,
    getMe: async () => {
      apis.auth
        .me()
        .then((res) => {
          setAccount(res.user);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dialogs: {
      login: {
        visible: visibleLoginDialog,
        setVisible: setVisibleLoginDialog,
      },
      newDoc: {
        visible: visibleNewDocDialog,
        setVisible: setVisibleNewDocDialog,
      },
      user: {
        visible: visibleUserDialog,
        setVisible: setVisibleUserDialog,
      },
      customerDoc: {
        visible: visibleCustomerDocDialog,
        setVisible: setVisibleCustomerDocDialog,
      },
      esgDoc: {
        visible: visibleEsgDocDialog,
        setVisible: setVisibleEsgDocDialog,
      },
      esgArea: {
        visible: visibleEsgAreaDialog,
        setVisible: setVisibleEsgAreaDialog,
      }
    },
    chart: {
      table: {
        value: tableValue,
        setValue: setTableValue,
      },
    },
    document,
    setDocument,
    documents,
    setDocuments,
    docTotalCount,
    setDocTotalCount,
    documentsLoading,
    fetchDocuments: async (id: string, page?: number, limit?: number, showDeleted?: boolean, callback?: (msg?: string) => void) => {
      setDocumentsLoading(true);
      const fetch = account?.role === ROLE.ADMIN ? apis.pdf.getAllDocuments : apis.pdf.getDocuments;

      fetch(id as string, page, limit, showDeleted)
        .then((res) => {
          setDocuments(res.results);
          setDocTotalCount(res.totalCount);
        })
        .catch((error) => {
          callback && callback(error.msg);
          console.log(error);
        })
        .finally(() => {
          setDocumentsLoading(false);
        });
    },
    user: {
      value: user,
      setValue: setUser,
    },
    users: {
      value: users,
      setValue: setUsers,
      isLoading: isUsersLoading,
      pagination: usersPagination,
      fetchData: (filter) => {
        setIsUsersLoading(true);
        apis.user
          .getUsers(filter)
          .then((res) => {
            setUsers(res.users);
            setUsersPagination(res.pagination);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsUsersLoading(false);
          });
      },
    },
    selectedUsers: {
      value: selectedUsers,
      setValue: setSelectedUsers,
    },
    customerDoc: {
      value: customerDoc,
      setValue: setCustomerDoc,
    },
    customerDocs: {
      value: customerDocs,
      setValue: setCustomerDocs,
      selected: selectedCustomerDocs,
      setSelected: setSelectedCustomerDocs,
      isLoading: isCustomerDocsLoading,
      pagination: customerDocsPagination,
      fetchData: (customerId, filter) => {
        setIsCustomerDocsLoading(true);

        const fetch = account?.role === ROLE.ADMIN ? apis.pdf.getAllCustomerDocuments : apis.pdf.getCustomerDocuments;

        fetch(customerId, filter)
          .then((res) => {
            setCustomerDocs(res.customerDocs);
            setCustomerDocsPagination(res.pagination);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsCustomerDocsLoading(false);
          });
      },
    },
    esgDoc: {
      value: esgDoc,
      setValue: setEsgDoc,
    },
    esgDocs: {
      value: esgDocs,
      setValue: setEsgDocs,
      selected: selectedEsgDocs,
      setSelected: setSelectedEsgDocs,
      isLoading: isEsgDocsLoading,
      esgDocAllowed: esgDocAllowed,
      setEsgDocAllowed: setEsgDocAllowed,
      pagination: esgDocsPagination,
      fetchData: (customerId, filter) => {
        setIsEsgDocsLoading(true);

        apis.esg.getEsgDocuments(customerId, filter)
          .then((res) => {
            setEsgDocs(res.esgDocs);
            setEsgDocsPagination(res.pagination);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsEsgDocsLoading(false);
          });
      },
    },
    esgAreaDocs: {
      value: esgAreaDocs,
      setValue: setEsgAreaDocs,
      isLoading: isEsgAreaDocsLoading,
      pagination: esgAreaDocsPagination,
      fetchData: (docId, filter) => {
        setIsEsgAreaDocsLoading(true);

        const fetch = apis.esg.getCustomerAreas;

        fetch(docId, filter)
          .then((res) => {
            setEsgAreaDocs(res.data);
            setEsgAreaDocsPagination(res.pagintaion);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsEsgAreaDocsLoading(false);
          });
      }
    }
  };

  // Return app provider
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Export context provider
export default AppProvider;
