// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import ApexCharts, { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

// Client
import { client } from '../../../../App';

// Queries
import { GET_HISTORIES } from '../../../../queries/history';

// Interface
type ViewMode = 'Intraday' | '1 Monat' | '6 Monate' | '1 Jahr' | 'YTD' | 'Gesamt';

// BarChart option
const options: ApexOptions = {
  chart: {
    id: 'area-datetime',
    type: 'area',
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'bottom',
  },
  annotations: {
    yaxis: [
      {
        y: 30,
        borderColor: '#003F7A',
        label: {
          text: 'Support',
          style: {
            color: '#fff',
            background: '#00E396',
          },
        },
      },
    ],
    xaxis: [
      {
        borderColor: '#003F7A',
        label: {
          text: 'Rally',
          style: {
            color: '#fff',
            background: '#003F7A',
          },
        },
      },
    ],
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 6,
  },
  yaxis: {
    labels: {
      formatter: (value) => value.toFixed(0),
    },
  },
  tooltip: {
    x: {
      format: 'MMM dd yyyy',
    },
  },
  colors: ['#003F7A'],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

// BarChart view modes
const viewModes: ViewMode[] = ['Intraday', '1 Monat', '6 Monate', '1 Jahr', 'YTD', 'Gesamt'];

export const SolActivePanel: FC = () => {
  // States
  const [currentViewMode, setCurrentViewMode] = useState<ViewMode>('Gesamt');
  const [dataForToday, setDataForToday] = useState([]);
  const [dataForAll, setDataForAll] = useState([]);
  const [data, setData] = useState([]);

  // Data fetcher
  const fetchData = async (type: string) =>
    client.query({
      query: GET_HISTORIES,
      variables: { type: type },
    });

  // Change view mode handler
  const handleChangeViewMode = (viewMode: ViewMode) => {
    switch (viewMode) {
      case 'Intraday':
        ApexCharts.exec('area-datetime', 'zoomX', +new Date() - 24 * 60 * 60 * 1000, +new Date());
        break;
      case '1 Monat':
        ApexCharts.exec('area-datetime', 'zoomX', +new Date() - 30 * 24 * 60 * 60 * 1000, +new Date());
        break;
      case '6 Monate':
        ApexCharts.exec('area-datetime', 'zoomX', moment().subtract(6, 'months').valueOf().toString(), +new Date());
        break;
      case '1 Jahr':
        ApexCharts.exec('area-datetime', 'zoomX', moment().subtract(1, 'years').valueOf().toString(), +new Date());
        break;
      case 'YTD':
        ApexCharts.exec('area-datetime', 'zoomX', moment().startOf('year').valueOf().toString(), +new Date());
        break;
      case 'Gesamt':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          moment().subtract(5, 'years').valueOf().toString(),
          moment().valueOf().toString()
        );
        break;
      default:
    }

    const now = moment().valueOf().toString();
    const dates: Record<ViewMode, any> = {
      ['Intraday']: moment().subtract(1, 'days').valueOf().toString(),
      ['1 Monat']: moment().subtract(1, 'months').valueOf().toString(),
      ['6 Monate']: moment().subtract(6, 'months').valueOf().toString(),
      ['1 Jahr']: moment().subtract(1, 'years').valueOf().toString(),
      ['YTD']: moment().startOf('year').valueOf().toString(),
      ['Gesamt']: moment().valueOf().toString(),
    };

    if (viewMode === 'Intraday') {
      setData(dataForToday);
    } else if (viewMode === 'Gesamt') {
      setData(dataForAll);
    } else {
      const data = dataForAll.filter((d) => d[0] >= dates[viewMode] && d[0] <= now);
      setData(data);
    }

    setCurrentViewMode(viewMode);
  };

  // On mounted
  useEffect(() => {
    fetchData('all').then((result) => {
      setDataForAll(result.data.getHistory.histories);
      setData(result.data.getHistory.histories);
    });
    fetchData('today').then((result) => {
      setDataForToday(result.data.getHistory.histories);
    });
  }, []);

  // Return wicklung panel
  return (
    <Box>
      <Stack spacing={2} direction="row" justifyContent="center">
        {viewModes.map((viewMode, index) => (
          <Button
            key={index}
            color={viewMode === currentViewMode ? 'primary' : 'inherit'}
            onClick={() => handleChangeViewMode(viewMode)}
            variant={viewMode === currentViewMode ? 'contained' : 'text'}
          >
            {viewMode}
          </Button>
        ))}
      </Stack>
      <ReactApexChart
        options={options}
        series={[
          {
            name: 'Frankfurter Modern Value Index',
            data: data,
          },
        ]}
        type="area"
        height={350}
      />
      <Container maxWidth="md">
        <Typography>
          Der hier dargestellte Chart zeigt die Wertentwicklung des zugrundeliegenden Index und nicht die
          Wertentwicklung des Frankfurter UCITS-ETF - Modern Value. Der Chart des Fonds darf aus rechtlichen Gründen
          erst 1 Jahr nach Auflage (01.07.2022) gezeigt werden.
        </Typography>
      </Container>
    </Box>
  );
};
