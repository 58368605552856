// Resources
import { IStyles } from '../../../resource';

// Define Styles
const styles: IStyles = {
  dialog: (theme) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(30, 0),
    px: '0 !important',
    p: { xs: theme.spacing(42, 20, 30), md: theme.spacing(51, 46, 46) },
    position: 'relative',
    width: { xs: 'calc(100vw - 40px)', md: 'calc(100vw - 120px)', lg: 'calc(100vw - 426px)' },
  }),
  dialogPadding: (theme) => ({
    p: { xs: theme.spacing(0, 20), md: theme.spacing(0, 46) },
  }),
  scrollbar: (theme) => ({
    height: 'calc(100vh - 418px)',
    px: 16,
    m: theme.spacing(20, -16, 26),
  }),
};

// Export styles
export default styles;
