// Dependencies
import { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Pagination, Stack, Typography } from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';

// Components
import { DocActions } from '../../../components';

// Styles
import styles from './styles';

// Icons
import { IDocument, ITemplate } from '../../../resource';

// Apis
import apis from '../../../apis';
import { AppContext } from '../../../context';
import {BasicInfo} from './BasicInfo';
import {FirstPage} from './FirstPage';
import {createTemplate} from '../../../apis/template.apis';
import {useToastContext} from '../../../components/Toast/toastContext';
import {ROUTES} from '../../../contants';

// Export template detail page
export const TemplateDetailPage: FC<{ isNew?: boolean }> = ({ isNew }) => {
  // States
  const [template, setTemplate] = useState<ITemplate>();
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(9);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { account } = useContext(AppContext);
  const navigate = useNavigate();
  const { activeToast } = useToastContext();

  // Get template id from
  const { id } = useParams<{ id: string }>();

  // Get paginated documents
  const paginatedDocuments = useMemo<any[]>(() => {
    return [];
    // return documents?.results?.filter((_, index) => index >= (currentPage - 1) * perPage && index < currentPage * perPage);
  }, [documents, currentPage, perPage]);

  // Calc total page
  const totalPage = useMemo(() => {
    return Math.ceil(documents.length / perPage);
  }, [documents, perPage]);

  // Page change handler
  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const onSaveTemplate = () => {
    if (isNew) {
      apis.template.createTemplate(template)
        .then(() => {
          activeToast({
            type: 'success',
            text: "Templated created successfully!",
          });
          navigate(ROUTES.TEMPLATE.LIST);
        });
    }

    if (id) {
      apis.template.updateTemplate(id, template)
        .then((res) => {
          console.log(res);
        });
    }
  };

  const onUpdateTemplate = (value) => setTemplate({ ...template, ...value });

  // Fetch template
  const fetchTemplate = () => {
    if (id) {
      apis.template
        .getTemplate(id)
        .then((res) => {
          setTemplate(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Fetch documents
  const fetchDocuments = () => {
    apis.pdf
      .getDocuments(account?.id)
      .then((res) => {
        setDocuments(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // On mounted
  useEffect(() => {
    if (id && !isNew) {
      fetchTemplate();
      fetchDocuments();
    } else {
      setIsLoading(false);
    }
  }, [id, isNew]);

  // Return template detail page
  return (
    <Stack divider={<Divider sx={{ mt: 16, mb: 16 }} />}>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
        <Typography variant="h4">{isNew ? 'Create' : 'Edit'} Template</Typography>
        <Button onClick={onSaveTemplate}>Save Template</Button>
      </Stack>
      <Stack>
        {(isNew || template) && <BasicInfo template={template} onChange={onUpdateTemplate} />}
      </Stack>
      <Stack>
        {(isNew || template) && <FirstPage template={template} onChange={onUpdateTemplate} />}
      </Stack>
      <Box sx={styles.documents}>
        {isLoading ? (
          <CircularProgress />
        ) : paginatedDocuments.length > 0 ? (
          <>
            <Stack width="100%" spacing={20}>
              {paginatedDocuments.map((doc, index) => (
                <Box key={index} sx={styles.document}>
                  <Box>
                    <Typography variant="title">{doc.name}</Typography>
                    <Typography mt={12} variant="body2">
                      {moment(doc.createdAt).format('DD MMMM YYYY / hh:mm:ss')}
                    </Typography>
                  </Box>
                  <Box sx={styles.listAction}>
                    <DocActions doc={doc} />
                  </Box>
                </Box>
              ))}
            </Stack>
            <Stack mt={{ xs: 42, md: 82 }} alignItems="center">
              <Pagination page={currentPage} count={totalPage} siblingCount={0} onChange={handlePageChange} />
            </Stack>
          </>
        ) : (
          <Typography variant="title" align="center">
            No Document
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
