// Dependencies
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Pagination, Stack, Typography } from '@mui/material';

// Components
import { EsgDocDialog, IColumn, Table } from '../../../components';

// Resources
import { IAccount, ISortBy, ORDER } from '../../../resource';

// Context
import { AppContext } from '../../../context';

// Icons
import { SuccessIcon, CloseCircleIcon } from '../../../assets/icons';
import { UserSelector } from '../../../components/UserSelector';

import styles from './styles';

// Constants
const pageLimit = 15;

// Create ESG document page
export const EsgDocuments = () => {
  // States
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<ISortBy>({
    field: 'name',
    order: ORDER.ASC,
  });
  const [customer, setCustomer] = useState<IAccount | null>(null);

  // Context
  const { isAdmin, account, dialogs, esgDoc, esgDocs } = useContext(AppContext);

  // Calc page count
  const pageCnt = useMemo(() => {
    return Math.ceil((esgDocs.pagination?.total || 0) / pageLimit);
  }, [esgDocs.pagination]);

  // Fetch data
  const fetchData = () => {
    if (customer) {
      esgDocs.fetchData(customer.id, {
        options: {
          limit: pageLimit,
          skip: (pageNumber - 1) * pageLimit,
          sort: {
            [sort.field]: sort.order,
          },
        },
      });
    }
  };

  // Define columns
  const columns: IColumn[] = [
    {
      field: 'name',
      label: 'Name',
    },
    {
      field: 'url',
      label: 'URL',
    },
    {
      field: 'is_editable',
      label: 'Editable',
      sortable: false,
      render: ({ is_editable }) =>
        is_editable ? <SuccessIcon height={20} width={20} /> : <CloseCircleIcon height={20} width={20} />,
    },
    {
      field: 'is_active',
      label: 'Active',
      sortable: false,
      render: ({ is_active }) =>
        is_active ? <SuccessIcon height={20} width={20} /> : <CloseCircleIcon height={20} width={20} />,
    },
  ];

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSort(value);
  };

  // Selected change handler
  const handleChangeSelected = (selected: (string | number)[]) => {
    esgDocs.setSelected(selected as string[]);
  };

  // Page change handler
  const handlePageChange = (_, page: number) => {
    setPageNumber(page);
    esgDocs.setSelected([]);
  };

  // Close ESG doc dialog
  const handleCloseEsgDocDialog = () => {
    dialogs.esgDoc.setVisible(false);
  };

  // On mounted
  useEffect(() => {
    fetchData();
  }, []);

  // On page number, sort & account changed
  useEffect(() => {
    esgDocs.setEsgDocAllowed(!!customer);
    fetchData();
  }, [pageNumber, sort, customer?.id]);

  useEffect(() => {
    if (!isAdmin && account) {
      setCustomer(account);
    }
  }, [isAdmin, account]);

  // Return ESG documents page
  return (
    <>
      <Box display="flex" mb={8}>
        {isAdmin && <UserSelector value={customer} onChange={setCustomer} />}
      </Box>

      {customer ? (
        <Stack alignItems="center" spacing={{ xs: 36, md: 66 }}>
          <Table
            rowSelectable
            columns={columns}
            data={esgDocs.value}
            order={sort.order}
            orderBy={sort.field}
            isLoading={esgDocs.isLoading}
            selected={esgDocs.selected}
            contentSize={{
              width: {
                xs: 'calc(100vw - 40px)',
                sm: 'calc(100vw - 80px)',
                md: 'calc(100vw - 256px)',
                lg: 'calc(100vw - 376px)',
              },
            }}
            onSort={handleSort}
            onChangeSelected={handleChangeSelected}
          />
          {esgDocs.pagination && pageCnt > 1 && (
            <Pagination page={pageNumber} count={pageCnt} onChange={handlePageChange} />
          )}
        </Stack>
      ) : (
        <>
          {isAdmin && <Typography variant="h2" align="center" sx={styles.placeholder}>Please select customer</Typography>}
        </>
      )}

      {dialogs.esgDoc.visible && (
        <EsgDocDialog
          customer={customer}
          open={dialogs.esgDoc.visible}
          document={esgDoc.value}
          onClose={handleCloseEsgDocDialog}
        />
      )}
    </>
  );
};
