// Dependencies
import { FC, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography, CircularProgress, InputAdornment, TextField, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// Constants
import { ROUTES } from '../../../contants';

// Context
import { AppContext } from '../../../context';

// Apis
import apis from '../../../apis';

// Styles
import styles from './styles';

// Validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required Field!'),
});

// Export change password page
export const ChangePasswordPage: FC = () => {
  // States
  const { dialogs } = useContext(AppContext);

  // Token
  const { token } = useParams<{ token: string }>();

  // Get navigate from hook
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [resultMessage, setResultMessage] = useState<string>('');

  const handleSubmit = (values) => {
    setLoading(true);
    apis.auth
      .verifyToken('change-password', { token, password: values.password })
      .then((res) => {
        setLoading(false);
        setResultMessage(res?.msg || 'You are successfully confirmed!');
        handleGotoLogin();
      })
      .catch((err) => {
        setResultMessage(err.msg || 'Failed to verify token');
        setLoading(false);
      });
  };

  // Go to login handler
  const handleGotoLogin = () => {
    navigate(ROUTES.AUTH.LOGIN);
    dialogs.login.setVisible(true);
  };

  // Return confirmation page
  return (
    <Stack spacing={30} sx={styles.container}>
      {loading ? (
        <>
          <CircularProgress />
        </>
      ) : (
        <>
          {resultMessage && (
            <Typography variant="headline" align="center">
              {resultMessage}
            </Typography>
          )}
          <Formik
            initialValues={{
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <Box
                width={1}
                component={Form}
                onSubmit={handleSubmit}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <TextField
                  sx={styles.form}
                  fullWidth
                  type="password"
                  name="password"
                  value={values.password}
                  error={Boolean(errors.password && touched.password)}
                  helperText={Boolean(errors.password && touched.password) && errors.password}
                  placeholder="Choose a Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icons/lock.svg" alt={'img'} />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Button fullWidth type="submit" sx={{ maxWidth: 420 }}>
                  Submit
                </Button>
              </Box>
            )}
          </Formik>
          <Button fullWidth variant="varied-outlined" onClick={handleGotoLogin} sx={{ maxWidth: 420 }}>
            Go to Login
          </Button>
        </>
      )}
    </Stack>
  );
};
