// Dependencies
import { FC } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';

// Icons
import { DropboxIcon, GoogleDriveIcon, ICloudIcon, UploadIcon } from '../../../../assets/icons';

// Styles
import styles from './styles';

// Export upload panel
export const UploadPanel: FC = () => {
  // Drop handler
  const handleDrop = (acceptedFiles: File[]) => {
    console.log(acceptedFiles);
  };

  // Upload buttons
  const uploadButtons = [
    {
      icon: <UploadIcon />,
      label: 'Upload Files',
    },
    {
      icon: <GoogleDriveIcon />,
      label: 'Google Drive',
    },
    {
      icon: <ICloudIcon />,
      label: 'iCloud',
    },
    {
      icon: <DropboxIcon />,
      label: 'Dropbox',
    },
  ];

  // Return upload panel
  return (
    <Stack divider={<Divider sx={{ my: 20 }} />}>
      <Stack spacing={20}>
        {uploadButtons.map(({ icon, label }, index) => (
          <Button key={index} fullWidth variant="varied-outlined" startIcon={icon}>
            {label}
          </Button>
        ))}
      </Stack>
      <Stack spacing={20}>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <Box sx={styles.dropzone} {...getRootProps()}>
              <input {...getInputProps()} accept=".png, .jpg, .svg, .doc, .docx, .xls, .xlsxt" />
              <Typography variant="button" textTransform="none">
                {isDragActive ? 'Drop the files here ...' : 'Drag media here to upload'}
              </Typography>
            </Box>
          )}
        </Dropzone>
        <Typography variant="body2">You can upload images (.PNGs, .JPGs, .SVGs), Excel and Word files. </Typography>
      </Stack>
    </Stack>
  );
};
