// Dependencies
import React, { FC, useEffect, useState, useMemo } from 'react';
import {Box, Button, CircularProgress, Container, Stack, Typography} from '@mui/material';
import ApexCharts, { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

// Queries
import { client } from '../../../../../../App';
import { GET_QUERY } from '../../../../../../queries/history';

// Interface
type ViewMode = 'Intraday' | '1 Monat' | '6 Monate' | '1 Jahr' | 'YTD' | 'Gesamt';

// Chart view modes
const _viewModes: ViewMode[] = ['Intraday', '1 Monat', '6 Monate', '1 Jahr', 'YTD', 'Gesamt'];

const years = ['2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'];

type Props = {
  color: string;
  content?: {
    theme: string,
    chartType: string,
    isin?: string,
    title?: string,
    chartTitle?: string,
    chartText?: string,
    isPercent: boolean,
    additionalIsins?: any[],
  }
  isin?: string;
}

export const SolActivePanel: FC<Props> = ({ color = '#003F7A', content, isin }) => {
  // States
  const [currentViewMode, setCurrentViewMode] = useState<ViewMode>('Gesamt');
  const [dataForToday, setDataForToday] = useState<any[]>([]);
  const [dataForAll, setDataForAll] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [additionalData, setAdditionalData] = useState<any[]>([]);
  const [viewModes, setViewModes] = useState(_viewModes)
  const isOldTheme = color === '#003F7A';
  const [loading, setLoading] = useState(false);
  const [firstChartValue, setFirstChartValue] = useState();

  useEffect(() => {
    if (isin !== "LU2439874319") {
      setViewModes(viewModes.slice(1));
    }
  }, [isin]);

// Chart option
  const options: ApexOptions = {
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    animations: {
      enabled: false
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom"
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: color,
          label: {
            text: 'Support',
            style: {
              color: '#fff',
              background: color,
            },
          },
        },
      ],
      xaxis: [
        {
          borderColor: color,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tooltip: {
            enabled: false
          },
          label: {
            text: 'Rally',
            style: {
              color: '#fff',
              background: color,
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        formatter: (value) => (`${value ? value.toFixed(0) : 0}${content?.isPercent ? '%' : ''}`),
      }
    },
    tooltip: {
      x: {
        format: 'MMM dd yyyy',
      },
    },
    colors: [content?.theme || color],
    fill: isOldTheme ? {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    } : {
      type: ['solid', 'solid'],
      opacity: [0.2, 0]
    },
  };

  // Data fetcher
  const fetchData = async (mode: string, chartIsin: string | undefined, chartType: string | undefined, until = "") =>
    client.query({
      query: GET_QUERY,
      variables: { param: { type: chartType || 'SolActive', isin: chartIsin || isin, mode, until } },
    });

  // Change view mode handler
  const handleChangeViewMode = (viewMode: ViewMode) => {
    switch (viewMode) {
      case 'Intraday':
        ApexCharts.exec('area-datetime', 'zoomX', +new Date() - 24 * 60 * 60 * 1000, +new Date());
        break;
      case '1 Monat':
        ApexCharts.exec('area-datetime', 'zoomX', +new Date() - 30 * 24 * 60 * 60 * 1000, +new Date());
        break;
      case '6 Monate':
        ApexCharts.exec('area-datetime', 'zoomX', moment().subtract(6, 'months').valueOf().toString(), +new Date());
        break;
      case '1 Jahr':
        ApexCharts.exec('area-datetime', 'zoomX', moment().subtract(1, 'years').valueOf().toString(), +new Date());
        break;
      case 'YTD':
        ApexCharts.exec('area-datetime', 'zoomX', moment().startOf('year').valueOf().toString(), +new Date());
        break;
      case 'Gesamt':
        ApexCharts.exec(
          'area-datetime',
          'zoomX',
          moment().subtract(5, 'years').valueOf().toString(),
          moment().valueOf().toString()
        );
        break;
      default:
    }

    const now = moment().valueOf().toString();
    const dates: Record<ViewMode, any> = {
      ['Intraday']: moment().subtract(1, 'days').valueOf().toString(),
      ['1 Monat']: moment().subtract(1, 'months').valueOf().toString(),
      ['6 Monate']: moment().subtract(6, 'months').valueOf().toString(),
      ['1 Jahr']: moment().subtract(1, 'years').valueOf().toString(),
      ['YTD']: moment().startOf('year').valueOf().toString(),
      ['Gesamt']: moment().valueOf().toString(),
    };

    if (viewMode === 'Intraday') {
      setData(dataForToday);
    } else if (viewMode === 'Gesamt') {
      setData(dataForAll);
    } else {
      if (isin === 'LU2439874319') {
        const data = dataForAll.filter((d) => d[0] >= dates[viewMode] && d[0] <= now);
        setData(data);
        setCurrentViewMode(viewMode);
        return;
      }

      let data = dataForAll.filter((d) => d[0] >= +dates[viewMode] && d[0] <= +now);
      const adData = additionalData[0]?.data?.filter((d: any) => d[0] >= +dates[viewMode] && d[0] <= +now);

      if (viewMode === "1 Monat" && adData && adData.length && data[0][0] <= adData[0][0]) {
        data = data.filter((d) => d[0] >= adData[0][0]);
      }

      fetchData(viewMode, content?.isin, content?.chartType).then((result) => {
        setData((old) => [...old, ...result.data.getQuery.histories].sort((a, b) => +new Date(a[0]) - +new Date(b[0])));
      });
      content?.additionalIsins?.forEach(async (additionalIsin) => {
        const chartIsin = additionalIsin?.isin;
        if (chartIsin) {
          return new Promise((resolve, reject) => {
            fetchData(viewMode, chartIsin, additionalIsin?.chartType || 'SolActive')
            .then((result) => {
              const resData = result.data.getQuery?.histories || [[]];
              setAdditionalData((old) => {
                if (old.some((item) => item.id === additionalIsin.id)) {
                  return old.map((item) => item.id === additionalIsin.id ? {
                    id: additionalIsin.id,
                    name: additionalIsin.title,
                    data: [...(item.data || []), ...resData].sort((a, b) => +new Date(a[0]) - +new Date(b[0])),
                    color: '#545454',
                  } : item);
                } else {
                  return [...old, {
                    id: additionalIsin.id,
                    name: additionalIsin.title,
                    data: resData,
                    color: '#545454',
                  }];
                }
              });
            }).catch((e) => reject(e));
          });
        }
      });
      setData(data);
    }

    setCurrentViewMode(viewMode);
  };

  useEffect(() => {
    if (content?.isin || isin) {
      fetchData('today', content?.isin, content?.chartType).then((result) => {
        setDataForToday(result.data.getQuery.histories);
      });
    }
  }, [isin, content?.isin]);

  // On mounted
  useEffect(() => {
    if (isin === 'LU2439874319') {
      setLoading(true);
      fetchData('all', content?.isin, content?.chartType).then((result) => {
        setDataForAll((old) => [...old, ...result.data.getQuery.histories].sort((a, b) => +new Date(a[0]) - +new Date(b[0])));
        setData((old) => [...old, ...result.data.getQuery.histories].sort((a, b) => +new Date(a[0]) - +new Date(b[0])));
      }).finally(() => setLoading(false));
    } else {
      (async () => {
        setLoading(true);
        setDataForAll([]);
        setData([]);
        if (content?.isin || isin) {
          await Promise.all(years.map(async (year, index) => {
            return new Promise((resolve, reject) => {
              fetchData('all', content?.isin, content?.chartType, year).then((result) => {
                setDataForAll((old) => [...old, ...result.data.getQuery.histories].sort((a, b) => +new Date(a[0]) - +new Date(b[0])));
                setData((old) => [...old, ...result.data.getQuery.histories].sort((a, b) => +new Date(a[0]) - +new Date(b[0])));
                resolve(1);
              }).catch((e) => reject(e));
            });
          }));
        }
        setLoading(false);
      })();
    }
  }, [isin, content?.isin]);

  useEffect(() => {
    if (content?.additionalIsins?.length) {
      content?.additionalIsins.forEach(async (additionalIsin) => {
        const chartIsin = additionalIsin?.isin;
        if (chartIsin) {
          await Promise.all(years.map(async (year, index) => {
            return new Promise((resolve, reject) => {
              fetchData('all', chartIsin, additionalIsin?.chartType || 'SolActive', year)
              .then((result) => {
                const resData = result.data.getQuery?.histories || [[]];
                setAdditionalData((old) => {
                  if (old.some((item) => item.id === additionalIsin.id)) {
                    return old.map((item) => item.id === additionalIsin.id ? {
                      id: additionalIsin.id,
                      name: additionalIsin.title,
                      data: [...(item.data || []), ...resData].sort((a, b) => +new Date(a[0]) - +new Date(b[0])),
                      color: '#545454',
                    } : item);
                  } else {
                    return [...old, {
                      id: additionalIsin.id,
                      name: additionalIsin.title,
                      data: resData,
                      color: '#545454',
                    }];
                  }
                });
                resolve(1);
              }).catch((e) => reject(e));
            });
          }));
        }
      });
    }
  }, [content]);

  const chartData = useMemo(() => {
    if (data.length && content?.isPercent) {
      const firstValue = data[0][1];
      setFirstChartValue(data[0]);
      return data.map((item) => [item[0], (item[1] / firstValue - 1) * 100 ])
    }
    return data;
  }, [data, content?.isPercent]);

  const additionalChartData = useMemo(() => {
    if (firstChartValue && content?.isPercent) {
      return additionalData.map((lineData) => {
        const series = lineData.data;
        let lineSeries
        if (!series.length) {
          lineSeries = []
        } else {
          const index = series.findIndex((item: number[]) => item[0] > firstChartValue[0]);
          lineSeries = series.slice(index);
          if (series[index - 1]) {
            lineSeries.unshift([firstChartValue[0], series[index - 1][1]]);
          }
        }

        return {
          ...lineData,
          data: lineSeries
            .map((item: number[], index: number, _array: number[][]) => [item[0], ((item[1] / _array[0][1]) - 1) * 100 ])
            .sort((a: number[], b: number[]) => +new Date(a[0]) - +new Date(b[0]))
        };
      });
    }
    return additionalData;
  }, [additionalData, firstChartValue, content?.isPercent]);

  // Return wicklung panel
  return (
    <Box position="relative">
      <Stack spacing={2} direction="row" justifyContent="center">
        {viewModes.map((viewMode, index) => (
          <Button
            key={index}
            sx={{
              backgroundColor: viewMode === currentViewMode ? (content?.theme || color || "#003F7A") : 'inherit',
              color: viewMode === currentViewMode ? "white" : (content?.theme || color || "#003F7A"),
            }}
            onClick={() => handleChangeViewMode(viewMode)}
            variant={viewMode === currentViewMode ? 'contained' : 'text'}
          >
            {viewMode}
          </Button>
        ))}
      </Stack>
      <ReactApexChart
        options={options}
        series={[
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          {
            name: content?.chartTitle || 'Frankfurter Modern Value Index',
            data: chartData,
          },
          ...(additionalChartData?.length ? additionalChartData : [])
        ]}
        type="area"
        height={350}
      />
      <Container maxWidth="md">
        {content?.chartText !== undefined ? (
          <Typography>{content.chartText}</Typography>
          ) : (
          <Typography>
            Der hier dargestellte Chart zeigt die Wertentwicklung des zugrundeliegenden Index und nicht die Wertentwicklung
            des Frankfurter UCITS-ETF - Modern Value. Der Chart des Fonds darf aus rechtlichen Gründen erst 1 Jahr nach
            Auflage (01.07.2022) gezeigt werden.
          </Typography>
        )}
      </Container>
    </Box>
  );
};
