import {useParams} from 'react-router-dom';
import {Box, TextField, Typography} from '@mui/material';
import styles from '../styles';
import moment from 'moment/moment';
import {ITemplate} from '../../../../resource';
import {ColorPicker} from '../../../../components/ColorPicker';
import {ImageEditor} from '../../../../components/ImageEditor';
import React, {useContext} from 'react';
import {UserSelector} from '../../../../components/UserSelector';
import {AppContext} from '../../../../context';

type Props = {
  template?: ITemplate;
  onChange: (value: Partial<ITemplate>) => void;
}

export const BasicInfo = ({
  template,
  onChange,
}: Props) => {
  const { isAdmin } = useContext(AppContext);
  return (
    <>
      <Box sx={{ mb: 8 }}>
        <Typography variant="h5" fontWeight={500}>Basic Information</Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', gap: 46 }}>
        <Box sx={styles.template}>
          <ImageEditor
            value={template?.thumbnail_url}
            onChange={(value) => onChange({ thumbnail_url: value })}
          />
        </Box>
        <Box flex={1}>
          <TextField placeholder="Title" sx={styles.titleInput} value={template?.name} onChange={(e) => onChange({ name: e.target.value })} />
          <TextField
            multiline
            placeholder="Description"
            sx={styles.descInput}
            value={template?.description}
            onChange={(e) => onChange({ description: e.target.value })}
          />

          <Typography variant="body2" sx={{ opacity: 0.5, mb: 16 }}>
            {moment(template?.createdAt).format('DD MMMM YYYY / hh:mm:ss')}
          </Typography>

          <ColorPicker color={template?.color} onChange={(value) => onChange({ color: value })} />

          {isAdmin && (
            <Box sx={{ mt: 8 }}>
              <UserSelector multiple value={template?.userIds || []} onChange={(value) => onChange({ userIds: value })} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
};

