import axios, { AxiosRequestConfig, Method } from 'axios';

import { ACCESS_TOKEN_KEY, API_SERVER } from '../contants';

const http = axios.create({ baseURL: API_SERVER });

const request = (method: Method, url: string, options: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

  return http
    .request({
      ...options,
      method,
      url,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const response = err?.response;
      if (response?.status === 401) {
        // eslint-disable-next-line no-throw-literal
        throw {
          msg: 'Unauthorized',
        };
      }

      const data = response?.data;
      // eslint-disable-next-line no-throw-literal
      throw {
        msg: data?.msg || 'Network Error!',
      };
    });
};

const Http = {
  async publicGet(url: string, config: any = {}) {
    return await http.get(url, config);
  },
  get(url: string, params: any = {}, headers: any = {}) {
    return request('GET', url, { params, headers });
  },
  post(url: string, body: any = {}, headers: any = {}) {
    return request('POST', url, { data: body, headers });
  },
  put(url: string, body: any = {}, headers: any = {}) {
    return request('PUT', url, { data: body, headers });
  },
  patch(url: string, body: any = {}, headers: any = {}) {
    return request('PATCH', url, { data: body, headers });
  },
  delete(url: string, body: any = {}, headers: any = {}) {
    return request('DELETE', url, { data: body, headers });
  },
};

export default Http;
