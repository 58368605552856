// Dependencies
import { FC, ReactNode, useContext, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import { Dialog } from '../../Dialog';

// Apis
import apis from '../../../apis';

// Icons
import { BuildingsIcon, EmailIcon, LinkIcon, ProfileIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Context
import { useToastContext } from '../../Toast/toastContext';

// Interfaces
import { IAccount, ORDER } from '../../../resource';
import { AppContext } from '../../../context';

interface IField {
  name: string;
  label: string;
  icon: ReactNode;
  size?: 1 | 2;
  type?: 'text' | 'password';
}

interface IUserDialogProps {
  open: boolean;
  user?: IAccount;
  onClose: () => void;
}

// Constants
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  companyUrl: '',
};

const fields: IField[] = [
  {
    name: 'firstName',
    label: 'First Name',
    icon: <ProfileIcon />,
    size: 1,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    icon: <ProfileIcon />,
    size: 1,
  },
  {
    name: 'email',
    label: 'Email',
    icon: <EmailIcon />,
  },
  {
    name: 'companyName',
    label: 'Company Name',
    icon: <BuildingsIcon />,
  },
  {
    name: 'companyUrl',
    label: 'Company URL',
    icon: <LinkIcon />,
  },
];

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Field!').required('Required Field!'),
  companyUrl: Yup.string().url('Invalid Field!'),
});

// Export user dialog
export const UserDialog: FC<IUserDialogProps> = ({ open, user, onClose }) => {
  // Get toast from hook
  const { activeToast } = useToastContext();

  // Context
  const { users } = useContext(AppContext);

  // Fetch data
  const fetchData = () => {
    users.fetchData({
      options: {
        limit: 15,
        skip: 0,
        sort: {
          firstName: ORDER.ASC,
        },
      },
    });
  };

  // Create form form hook
  const { values, errors, touched, setValues, setSubmitting, isSubmitting, handleChange, handleBlur, submitForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        if (user) {
          apis.user
            .updateUser(user.id, values)
            .then((res) => {
              onClose();
              fetchData();
              activeToast({
                type: 'success',
                text: res.msg,
              });
            })
            .catch((err) => {
              activeToast({
                type: 'error',
                text: err.msg,
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          apis.user
            .createUser(values as unknown as IAccount)
            .then((res) => {
              onClose();
              fetchData();
              activeToast({
                type: 'success',
                text: res.msg,
              });
            })
            .catch((err) => {
              activeToast({
                type: 'error',
                text: err.msg,
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      },
    });

  // Discard handler
  const handleDiscard = () => {
    onClose();
  };

  // On user change
  useEffect(() => {
    if (user) {
      const { firstName = '', lastName = '', email = '', companyName = '', companyUrl = '' } = user;
      setValues({
        firstName,
        lastName,
        email,
        companyName,
        companyUrl,
      });
    }
  }, [user]);

  // Return user dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.form}>
        <Grid container columns={2} columnSpacing={8} rowSpacing={34}>
          {fields.map(({ name, label, icon, type = 'text', size = 2 }, index) => (
            <Grid key={index} item xs={2} sm={size}>
              <TextField
                fullWidth
                name={name}
                placeholder={label}
                value={values[name]}
                type={type}
                disabled={isSubmitting}
                error={Boolean(errors[name] && touched[name])}
                helperText={Boolean(errors[name] && touched[name]) && (errors[name] as string)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          ))}
        </Grid>
        <Stack spacing={8} mt={48}>
          <Button
            fullWidth
            variant="varied-contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress /> : <ProfileIcon />}
            onClick={submitForm}
          >
            {user ? 'Edit' : 'Create New'} User
          </Button>
          <Button variant="text" disabled={isSubmitting} onClick={handleDiscard}>
            Discard
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
