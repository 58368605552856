// Resources
import { IStyles } from '../../../../resource';

// Define styles
const styles: IStyles = {
  widgetWrapper: (theme) => ({
    marginBottom: 30,
  }),
  widgetContainer: (theme) => ({
    border: `1px solid ${theme.palette.gray.light}`,
  }),
  tabBar: (theme, color) => ({
    background: color || '#ccd5df',
  }),
  contentHeader: (theme, color) => ({
    height: 48,
    background: color || '#ccd5df',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16
  }),
  newTabInput: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    '& input': {
      border: 0,
      padding: '0 10px',
      width: '100px',
      background: 'transparent',
      outline: 'none',
      '&:focus': {
        outline: 'none',
      }
    }
  }),
  draggableTab: (theme, { active, color }) => ({
    padding: '0 20px',
    height: '100%',
    background: color,
    color: active ? 'white' : theme.palette.primary.main,
    borderBottom: active ? `2px solid ${theme.palette.primary.main}` : '0',
  }),
  editTab: (theme, { color }) => ({
    padding: '0 10px',
    height: '100%',
    background: color,
    color: theme.palette.primary.main,
  }),
  tabInput: () => ({
    borderBottom: '0',
    height: '100%',
    gap: '8px',
  }),
  editTabInput: (theme) => ({
    height: '100%',
    '& input': {
      border: 0,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      color: 'white',
      padding: '15px 10px',
      width: '100px',
      background: 'transparent',
      outline: 'none',
      '&:focus': {
        outline: 'none',
      }
    }
  }),
  tabContent: (theme) => ({
    padding: theme.spacing(8),
    minHeight: 200,
  }),
  newTabIcon: () => ({
    border: 0,
    width: 24,
    height: 24,
    boxShadow: 'none',
    background: 'transparent',
    marginRight: '4px',
  }),
  tabControlIcon: (theme, isActive) => ({
    border: 0,
    width: 24,
    height: 24,
    boxShadow: 'none',
    background: 'transparent',
    '&:first-child': {
      marginLeft: '8px',
    },
    'svg': {
      fontSize: '16px'
    }
  }),
  addNewTab: () => ({
    borderRadius: '0 !important'
  }),
  colorRadio: (theme, color) => ({
    color: color,
    '&.Mui-checked': {
      color: color,
    },
  }),
  switch: (theme) => ({
    marginLeft: 'auto'
  }),
};

// Export styles
export default styles;
