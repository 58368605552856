// Dependencies
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { Box, Button, Divider, IconButton, OutlinedInput, Popover, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Icons
import {
  DeleteIcon,
  DocFilledIcon,
  ArrowLeftIcon,
  RedoIcon,
  SaveIcon,
  TemplateFilledIcon,
  UndoIcon,
  MenuIcon,
} from '../../../assets/icons';

// Styles
import styles from './styles';

// Interfaces
interface IHeaderProps {
  onMenuClick: () => void;
}

// Export header
const Header: FC<IHeaderProps> = ({ onMenuClick }) => {
  // States
  const [docName, setDocName] = useState<string>('Untitled design.pdf');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // Get navigate from hook
  const navigate = useNavigate();

  // Click handler
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close handler
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Focus handler
  const handleFocus = () => {
    setDocName(docName.replace('.pdf', ''));
  };

  // Blur handler
  const handleBlur = () => {
    setDocName(docName + '.pdf');
  };

  // Change handler
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDocName(event.currentTarget.value);
  };

  // Back handler
  const handleBack = () => {
    navigate(-1);
  };

  // Return header
  return (
    <>
      <Box sx={styles.header}>
        {/* @ts-ignore */}
        <IconButton variant="text" size="medium" sx={styles.menuIcon} onClick={onMenuClick}>
          {<MenuIcon />}
        </IconButton>
        <Stack direction="row" spacing={{ xs: 4, md: 8 }} sx={styles.toolbar}>
          <Button size="small" variant="text" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
            Back
          </Button>
          <Divider orientation="vertical" flexItem />
          <Stack spacing={{ xs: 8, md: 12 }} direction="row">
            <Button size="small" variant="text" startIcon={<UndoIcon />}>
              Undo
            </Button>
            <Button size="small" variant="text" endIcon={<RedoIcon />}>
              Redo
            </Button>
          </Stack>
        </Stack>
        <OutlinedInput
          value={docName}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          sx={(theme) => styles.name(theme, { width: docName.length > 30 ? 324 + (docName.length - 30) * 6.5 : 324 })}
        />
        <Stack spacing={{ xs: 8, md: 16 }} direction="row" sx={styles.action}>
          <IconButton size="medium">
            <DeleteIcon />
          </IconButton>
          {/* @ts-ignore */}
          <IconButton size="medium" variant={anchorEl ? 'contained' : 'outlined'} onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </Stack>
      </Box>
      {anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={styles.popover}
          onClose={handleClose}
        >
          <Stack width={234} spacing={8} alignItems="flex-end">
            <Button variant="varied-outlined" startIcon={<DocFilledIcon />}>
              Save as new draft
            </Button>
            <Button variant="varied-outlined" startIcon={<TemplateFilledIcon />}>
              Save as new template
            </Button>
          </Stack>
        </Popover>
      )}
      {}
    </>
  );
};

// Export header
export default Header;
