import { FC } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export const BranchenChart: FC = () => {
  const chartData = {
    series: [28, 40, 8, 20, 4],
    options: {
      labels: ['Kommunikation', 'Konsumgüter', 'Finanzwesen', 'Technologie', 'Sonstige'],
      colors: ['#0461B8', '#165B9D', '#275681', '#395066', '#4A4A4A'],
      chart: {
        width: 400,
        type: 'donut',
      },
      legend: {
        show: true,
        position: 'right',
        containerMargin: {
          top: 0,
        },
        fontSize: '16px',
        fontFamily: 'Fira Sans',
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            legend: {
              fontSize: '11px',
              position: 'bottom',
              width: '100%',
            },
          },
        },
      ],
    },
  };

  return (
    <div className="pie-chart-wrapper">
      <ReactApexChart options={chartData.options as ApexOptions} series={chartData.series} type="donut" />
    </div>
  );
};
