// Dependencies
import { FC } from 'react';
import { Box, ButtonBase, Divider, Grid, InputBase, Slider, Stack, ToggleButton, Typography } from '@mui/material';

// Icons
import {
  Line1Icon,
  Line2Icon,
  Line3Icon,
  Line4Icon,
  Line5Icon,
  Line6Icon,
  Line7Icon,
  Line8Icon,
  Line9Icon,
  Line10Icon,
  Line11Icon,
  Line12Icon,
  Line13Icon,
  Line14Icon,
  Line15Icon,
  RectangleIcon,
  EllipseIcon,
  PolygonIcon,
  FlipHorizontalIcon,
  FlipVerticalIcon,
  RotateIcon,
  FilledIcon,
  OutlinedIcon,
  ScaleIcon,
} from '../../../../assets/icons';

// Styles
import styles from './styles';
import { ColorPicker } from '../../ColorPicker';

// Constants
const lines = [
  {
    name: 'line1',
    icon: <Line1Icon />,
  },
  {
    name: 'line2',
    icon: <Line2Icon />,
  },
  {
    name: 'line3',
    icon: <Line3Icon />,
  },
  {
    name: 'line4',
    icon: <Line4Icon />,
  },
  {
    name: 'line5',
    icon: <Line5Icon />,
  },
  {
    name: 'line6',
    icon: <Line6Icon />,
  },
  {
    name: 'line7',
    icon: <Line7Icon />,
  },
  {
    name: 'line8',
    icon: <Line8Icon />,
  },
  {
    name: 'line9',
    icon: <Line9Icon />,
  },
  {
    name: 'line10',
    icon: <Line10Icon />,
  },
  {
    name: 'line11',
    icon: <Line11Icon />,
  },
  {
    name: 'line12',
    icon: <Line12Icon />,
  },
  {
    name: 'line13',
    icon: <Line13Icon />,
  },
  {
    name: 'line14',
    icon: <Line14Icon />,
  },
  {
    name: 'line15',
    icon: <Line15Icon />,
  },
];

const shapes = [
  {
    name: 'rectangle',
    icon: <RectangleIcon />,
  },
  {
    name: 'ellipsis',
    icon: <EllipseIcon />,
  },
  {
    name: 'polygon',
    icon: <PolygonIcon />,
  },
];

// Export element panel
export const ElementPanel: FC = () => {
  // Return element panel
  return (
    <Stack divider={<Divider sx={{ mt: 20, mb: 23 }} />}>
      <Box>
        <Typography mb={29} variant="title">
          Lines
        </Typography>
        <Grid container columns={3}>
          {lines.map(({ icon }, index) => (
            <Grid key={index} item xs={1}>
              <ButtonBase sx={styles.line}>{icon}</ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <Typography mb={29} variant="title">
          Shapes
        </Typography>
        <Grid container columns={3}>
          {shapes.map(({ icon }, index) => (
            <Grid item xs={1}>
              <ButtonBase key={index} sx={styles.shape}>
                {icon}
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <Typography mb={29} variant="title">
          Transform
        </Typography>
        <Stack
          mb={20}
          direction="row"
          divider={<Divider orientation="vertical" sx={{ mx: 10, height: { xs: 28, md: 34 } }} />}
          sx={styles.transform}
        >
          <Stack direction="row" spacing={12}>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography variant="label2" align="center" color="secondary" sx={styles.label}>
                W
              </Typography>
              <InputBase sx={styles.input} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography variant="label2" align="center" color="secondary" sx={styles.label}>
                H
              </Typography>
              <InputBase sx={styles.input} />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={8}>
            <Box sx={styles.label}>
              <RotateIcon />
            </Box>
            <InputBase endAdornment="°" sx={styles.input} />
          </Stack>
          <Stack direction="row" spacing={4}>
            <ButtonBase sx={styles.flip}>
              <FlipHorizontalIcon />
            </ButtonBase>
            <ButtonBase sx={styles.flip}>
              <FlipVerticalIcon />
            </ButtonBase>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={8}>
          {/* @ts-ignore */}
          <ToggleButton variant="outlined">
            <FilledIcon />
          </ToggleButton>
          {/* @ts-ignore */}
          <ToggleButton variant="outlined">
            <OutlinedIcon />
          </ToggleButton>
          <Stack direction="row" spacing={8} sx={styles.slider}>
            <Box sx={styles.label}>
              <ScaleIcon />
            </Box>
            <Slider color="secondary" />
            <InputBase sx={styles.input} />
          </Stack>
        </Stack>
      </Box>
      <ColorPicker />
    </Stack>
  );
};
