// Dependencies
import { FC } from 'react';
import { ButtonBase, Divider, Stack } from '@mui/material';

// Styles
import styles from './styles';

// Constants
const colors = [
  ['#000000', '#404040', '#808080', '#C1C1C1', '#FFFFFF'],
  ['#8ECAE6', '#219EBC', '#023047', '#FFB703', '#003C50'],
  ['#D90429', '#EF233C', '#EDF2F4', '#8D99AE', '#2B2D42'],
  ['#D6D6D6', '#FFEE32', '#FFD100', '#202020', '#333533'],
  ['#003049', '#D62828', '#F77F00', '#FCBF49', '#EAE2B7'],
  ['#344E41', '#3A5A40', '#588157', '#A3B18A', '#DAD7CD'],
  ['#F72585', '#7209B7', '#3A0CA3', '#4361EE', '#4CC9F0'],
];

// Export color picker
export const ColorPicker: FC = () => {
  // Return color picker
  return (
    <Stack direction="row" divider={<Divider orientation="vertical" sx={{ height: 196 }} />} sx={styles.container}>
      {colors.map((group, gIndex) => (
        <Stack key={gIndex} height={196} justifyContent="space-between">
          {group.map((color, cIndex) => (
            <ButtonBase key={cIndex} sx={(theme) => styles.color(theme, { color })} />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
