// Interface
import { IStyles } from '../../resource';

// Styles
const styles: IStyles = {
  layout: () => ({}),
  aside: () => ({
    display: 'flex',
  }),
  content: (theme) => ({
    flex: 1,
    height: {
      xs: 'calc(100vh - 176px)',
      sm: 'calc(100vh - 200px)',
      md: 'calc(100vh - 206px)',
      lg: 'calc(100vh - 270px)',
    },
    p: { xs: theme.spacing(30, 20), sm: theme.spacing(42, 40), md: theme.spacing(64, 80), lg: theme.spacing(96, 140) },
  }),
};

// Export styles
export default styles;
