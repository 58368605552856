// Dependencies
import { FC, useContext, useEffect } from 'react';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import { Dialog } from '../../Dialog';

// Apis
import apis from '../../../apis';

// Icons
import { DocFilledIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Context
import { AppContext } from '../../../context';
import { useToastContext } from '../../Toast/toastContext';

// Interfaces
import { ICustomerDocument, ORDER } from '../../../resource';

interface IField {
  name: string;
  label: string;
  type?: 'text' | 'checkbox';
  size?: 1 | 2;
}

interface ICustomerDocDialogProps {
  open: boolean;
  document?: ICustomerDocument;
  onClose: () => void;
}

const initialValues = {
  isAdmin: false,
  name: '',
  url: '',
  is_active: true,
  is_editable: false,
  customer: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required Field!'),
  url: Yup.string().url('Invalid Field!').required('Required Field!'),
  customer: Yup.string().when('isAdmin', (value, schema) => value ? schema.required('Required Field!') : schema)
});

// Export customer doc dialog
export const CustomerDocDialog: FC<ICustomerDocDialogProps> = ({ open, document, onClose }) => {
  // Get toast from hook
  const { activeToast } = useToastContext();

  // Context
  const { isAdmin, account, customerDocs, users } = useContext(AppContext);

  const fields: IField[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'url',
      label: 'Url',
    },
    {
      name: 'is_active',
      label: 'Active',
      type: 'checkbox',
      size: 1,
    },
    {
      name: 'is_editable',
      label: 'Editable',
      type: 'checkbox',
      size: 1,
    },
  ];

  // Create form form hook
  const { values, errors, touched, setFieldValue, setValues, setSubmitting, isSubmitting, handleChange, handleBlur, submitForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        if (account) {
          const { customer, isAdmin, ...params } = values;

          if (document) {
            apis.pdf
              .updateCustomerDocument(!isAdmin ? account.id : customer, document._id, params)
              .then((res) => {
                onClose();
                customerDocs.setSelected([]);
                customerDocs.fetchData(account.id, { options: { sort: { name: ORDER.ASC } } });
                activeToast({
                  type: 'success',
                  text: res.msg,
                });
              })
              .catch((err) => {
                activeToast({
                  type: 'error',
                  text: err.msg,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            apis.pdf
              .createCustomerDocument(!isAdmin ? account.id : values.customer, params)
              .then((res) => {
                onClose();
                customerDocs.fetchData(account.id, { options: { sort: { name: ORDER.ASC } } });
                activeToast({
                  type: 'success',
                  text: res.msg,
                });
              })
              .catch((err) => {
                activeToast({
                  type: 'error',
                  text: err.msg,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }
      },
    });

  // Discard handler
  const handleDiscard = () => {
    onClose();
  };

  // On user change
  useEffect(() => {
    if (document) {
      const { name = '', url = '', is_active, is_editable } = document;
      setValues({
        isAdmin: isAdmin,
        name,
        url,
        is_active,
        is_editable,
        customer: '',
      });
    }
  }, [document]);

  useEffect(() => {
    setFieldValue('isAdmin', isAdmin);
    if (isAdmin) {
      users.fetchData();
    }
  }, [isAdmin]);

  // Return customer doc dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.form}>
        <Grid container columns={2} columnSpacing={8} rowSpacing={34}>
          {fields.map(({ name, label, type = 'text', size = 2 }, index) => (
            <Grid key={index} item xs={size}>
              {type === 'text' ? (
                <TextField
                  fullWidth
                  name={name}
                  placeholder={label}
                  value={values[name]}
                  disabled={isSubmitting}
                  error={Boolean(errors[name] && touched[name])}
                  helperText={Boolean(errors[name] && touched[name]) && (errors[name] as string)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              ) : (
                <FormControlLabel
                  name={name}
                  label={label}
                  checked={values[name]}
                  disabled={isSubmitting}
                  control={<Checkbox />}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              )}
            </Grid>
          ))}
          {isAdmin && (
            <Grid item xs={2}>
              <Autocomplete
                id="combo-box-demo"
                disablePortal
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                options={users.value || []}
                value={users.value?.find((user) => user.id === values.customer)}
                sx={styles.userSelect}
                onChange={(_, value) => {
                  setFieldValue('customer', value?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="customer"
                    label="Customer"
                    error={Boolean(errors.customer && touched.customer)}
                    helperText={Boolean(errors.customer && touched.customer) && (errors.customer as string)}
                    onBlur={handleBlur}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Stack spacing={8} mt={30}>
          <Button
            fullWidth
            variant="varied-contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={28} color="inherit" /> : <DocFilledIcon />}
            onClick={submitForm}
          >
            {document ? 'Edit' : 'Create New'} Document
          </Button>
          <Button variant="text" disabled={isSubmitting} onClick={handleDiscard}>
            Discard
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};
