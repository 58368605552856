// Interfaces
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    height: { xs: 'calc(100vh - 158px)', md: 'calc(100vh - 110px)' },
    top: { xs: theme.spacing(158), md: theme.spacing(110) },
    left: { xs: theme.spacing(0), md: theme.spacing(96) },
    '.MuiBackdrop-root': {
      top: { xs: theme.spacing(158), md: theme.spacing(110) },
      left: { xs: theme.spacing(0), md: theme.spacing(96) },
    },
  }),
  container: () => ({
    width: 'calc(100% - 40px)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    ':focus': {
      outline: 'none',
    },
  }),
  close: () => ({
    position: 'absolute',
    top: 16,
    right: 16,
  }),
};

// Export styles
export default styles;
