// Export apis
import * as auth from './auth.apis';
import * as pdf from './pdf.apis';
import * as template from './template.apis';
import * as user from './user.apis';
import * as esg from './esg.apis';
import * as portfolio from './portfolio.apis';
import * as cdn from './cdn.apis';

export default {
  auth,
  pdf,
  template,
  user,
  esg,
  portfolio,
  cdn,
};
