// Dependencies
import { FC } from 'react';
import { List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

const data3 = [
  {
    a: 'KIID',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/KIID_FUETF_MV_LU2439874319_20220531.pdf',
  },
  {
    a: 'Verkaufsprospekt einschließlich Verwaltungsreglement',
    b: 'https://www.shareholdervalue.de/hubfs/Fondsdokumente/LU2439874319/VKP_Frankfurter UCITS-ETF.pdf',
  },
];

// Export document panel
export const DocumentPanel: FC = () => {
  return (
    <Stack spacing={30}>
      <List className="pdf_table">
        {data3.map(({ a, b }, index) => (
          <ListItem className="pdf_table_row" key={index}>
            <ListItemText className="title">
              <Typography>{a}</Typography>
            </ListItemText>
            <ListItemText className="pdf">
              <a href={b} target="_blank">
                <img src="https://mainfirst.tools.factsheetslive.com/bundles/mainfirsttool/images/lc_pdf_icon_DE.png" alt="lc_pdf_icon" />
              </a>
            </ListItemText>
          </ListItem>
        ))}
      </List>
      {/*<Table>*/}
      {/*  <TableBody>*/}
      {/*    {data2.map(({ a, b }, index) => (*/}
      {/*      <TableRow key={index}>*/}
      {/*        <TableCell>{a}</TableCell>*/}
      {/*        <TableCell>{b}</TableCell>*/}
      {/*      </TableRow>*/}
      {/*    ))}*/}
      {/*  </TableBody>*/}
      {/*</Table>*/}
    </Stack>
  );
};
