// Dependencies
import React, {FC, useRef, useState} from 'react';
import {
  Box,
  Button, CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid, IconButton,
  TextField, Tooltip,
} from '@mui/material';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';

// Component
import { Dialog } from '../../Dialog'

// Styles
import styles from './styles';
import {API_SERVER} from '../../../contants';
import {businessTableData} from '../../../pages';
import apis from '../../../apis';
import {Upload} from '@mui/icons-material';

// Interfaces
interface IEsgImagesModal {
  open: boolean;
  onClose: () => void;
  onSave: (image: string) => void;
}

export const EsgImagesModal: FC<IEsgImagesModal> = ({
  open,
  onClose,
  onSave,
}) => {
  const fileSelector = useRef<any>();
  const [loading, setLoading] = useState(false);

  const handleOpenBrowser = () => {
    fileSelector.current.click();
  };

  const handleChangeImage = async (e: any) => {
    if (e.target.files[0]) {
      setLoading(true);
      apis.pdf.uploadLogoImage(e.target.files[0])
      .then((res) => {
        if (res.success) {
          onSave(res.data);
          onClose();
        }
      })
      .catch(e => {
        console.log(e);
      }).
      finally(() => {
        setLoading(false);
      });
    }
  };

  const handleChoose = (image) => {
    onSave(image);
    onClose();
  };

  // Return ArrayEdit Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>Images</DialogTitle>
      <DialogContent sx={{ maxHeight: '400px', maxWidth: '1000px' }}>
        <Grid pt={20} container columns={10} spacing={[10, 20]} sx={{ alignItems: "center" }}>
          {businessTableData.map((item) => (
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton sx={styles.esgImage} onClick={() => handleChoose(item[0])}>
                <img src={`${API_SERVER}/${item[0]}`} alt={item[0]} />
              </IconButton>
            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" mt={24}>
          <Button onClick={handleOpenBrowser} size="small">
            {loading
              ? <CircularProgress size={20} sx={{ color: '#fff', mr: 4  }} />
              : <Upload sx={{ mr: 2 }} />
            }
            <span>Upload new image</span>
          </Button>
        </Box>
        <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleChangeImage} />
      </DialogContent>
    </Dialog>
  );
};
