// Dependencies
import React, { FC, ReactNode, useMemo } from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import { ResponsiveStyleValue, SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import TableHead from './Head';

// Styles
import styles from './styles';

// Interfaces
import { ISortBy, ORDER } from '../../resource';

export interface IColumn {
  field: string;
  label: string;
  sortable?: boolean;
  width?: number | string;
  render?: (row: any) => ReactNode;
}

interface IRowAction {
  icon: string;
  label: string;
  onClick: (id) => void;
}

export interface ITableProps {
  sx?: SxProps<Theme>;
  data: any[];
  order?: ORDER;
  orderBy?: string;
  columns: IColumn[];
  selectedId?: number | string;
  rowActions?: IRowAction[];
  onSort: (sortBy: ISortBy) => void;
  onRowClick?: (id: number) => void;
  paginated?: boolean;
  rowSelectable?: boolean;
  columnEditable?: boolean;
  stickyHeader?: boolean;
  contentSize?: {
    height?: number | string;
    width?: ResponsiveStyleValue<number | string>;
  };
  isList?: boolean;
  isLoading?: boolean;
  selected?: (string | number)[];
  onChangeSelected?: (value: (string | number)[]) => void;
}

// Export Table component
export const Table: FC<ITableProps> = ({
  data,
  columns,
  rowActions,
  selectedId,
  order,
  orderBy,
  onSort,
  rowSelectable = false,
  stickyHeader = false,
  contentSize,
  onRowClick,
  isList,
  isLoading,
  selected = [],
  onChangeSelected,
}) => {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map(({ id, _id }, index) => id || _id || index);
      onChangeSelected && onChangeSelected(newSelected);
      return;
    }
    onChangeSelected && onChangeSelected([]);
  };

  const handleClickRow = (id: number) => {
    onRowClick && onRowClick(id);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string | number) => {
    event.stopPropagation();
    const isIncluded = selected?.includes(id);

    if (isIncluded) {
      onChangeSelected && onChangeSelected(selected.filter((s) => s !== id));
    } else {
      onChangeSelected && onChangeSelected([...selected, id]);
    }
  };

  const isSelected = (id: string | number) => {
    if (selectedId) {
      return selectedId === id;
    } else {
      return selected?.includes(id);
    }
  };

  // Get column count
  const columnCount = useMemo(() => {
    let count = columns.length;
    if (rowSelectable) {
      count++;
    }
    if (rowActions) {
      count++;
    }

    return count;
  }, [columns, rowActions, rowSelectable]);

  // Return DataTable component
  return (
    <Box component={PerfectScrollbar} sx={{ width: contentSize?.width || '100%' }}>
      <MuiTable stickyHeader={stickyHeader}>
        <TableHead
          order={order}
          orderBy={orderBy}
          isLoading={isLoading || data.length === 0}
          numSelected={selected.length}
          rowCount={data.length}
          columns={columns}
          rowSelectable={rowSelectable}
          onSort={onSort}
          onSelectAllClick={handleSelectAllClick}
        />
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell
                colSpan={columnCount}
                sx={{
                  textAlign: 'center',
                  height: contentSize?.height ? `calc((${contentSize.height}) - ${!isList ? 67 : 59}px)` : 300,
                }}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : data.length > 0 ? (
            <>
              {data.map((row, index) => {
                // @ts-ignore
                const isSelectedRow = isSelected(row.id || row._id || index);

                // Return Table component
                return (
                  <TableRow
                    hover
                    key={index}
                    // @ts-ignore
                    role="checkbox"
                    tabIndex={-1}
                    selected={isSelectedRow}
                    onClick={() => handleClickRow(row.id)}
                    sx={{ cursor: onRowClick ? 'pointer' : 'auto' }}
                  >
                    {rowSelectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelectedRow}
                          sx={styles.checkbox}
                          onClick={(event) => handleClick(event, row.id || row._id || index)}
                        />
                      </TableCell>
                    )}
                    {columns.map(({ field, render, width }) => (
                      <TableCell key={field} sx={{ maxWidth: width ? width : 'auto' }}>{render ? render(row) : row[field]}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </>
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnCount}
                sx={{
                  backgroundColor: 'transparent !important',
                  textAlign: 'center',
                  height: contentSize?.height ? `calc(${contentSize.height} - ${!isList ? 67 : 59}px)` : 300,
                }}
              >
                <Typography variant="label1" align="center">
                  There is no data!
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </Box>
  );
};
