// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    width: 780,
    height: { xs: 380, md: 464 },
    backgroundColor: theme.palette.gray.light,
    borderRadius: theme.spacing(30, 0, 30, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  }),
  icon: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.success.light,
    p: theme.spacing(22),
    borderRadius: 62,
    mb: theme.spacing(21),
    svg: {
      width: 80,
      height: 80,
    },
  }),
};

// Export styles
export default styles;
