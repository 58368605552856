// Dependencies
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, IconButton, OutlinedInput, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { IColumn, Table } from '../../../components';

import { PlusIcon, PencilIcon, DeleteIcon } from '../../../assets/icons';
import { IAccount, ISortBy, ORDER } from '../../../resource';
import { AppContext } from '../../../context';
import { EsgAreaDialog } from '../../../components/Dialogs/EsgArea';
import apis from '../../../apis';
import styles from './styles';
import { useToastContext } from '../../../components/Toast/toastContext';
import { Check, Close } from '@mui/icons-material';
import { UserSelector } from '../../../components/UserSelector';

const pageLimit = 15;

// Export template panel
export const EsgAreas: FC = () => {
  const { isAdmin, account, dialogs, getMe, esgAreaDocs } = useContext(AppContext);

  // Get toast from hook
  const { activeToast } = useToastContext();

  // States
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedDoc, setSelectedDoc] = useState<any>(null);
  const [sort, setSort] = useState<ISortBy>({
    field: 'name',
    order: ORDER.ASC,
  });
  const [date, setDate] = useState('');
  const [dateActive, setDateActive] = useState(false);
  const [customer, setCustomer] = useState<IAccount | null>(null);

  // Calc page count
  const pageCnt = useMemo(() => {
    return Math.ceil((esgAreaDocs.pagination?.total || 0) / pageLimit);
  }, [esgAreaDocs.pagination]);

  // Define columns
  const columns: IColumn[] = [
    {
      field: 'icon',
      label: 'Logo',
      sortable: false,
      render: ({ logo_url }) => (
        <Box>
          <Avatar src={logo_url} sx={{ h: 20, w: 20 }} />
        </Box>
      ),
    },
    {
      field: 'exclusionCriteria',
      label: 'Ausschlusskriterien',
    },
    {
      field: 'noSales',
      label: 'Kein Umsatz im Geschäftsfeld',
      render: ({ noSales }) => <>{noSales} %</>
    },
    {
      field: 'sales_value_less_5',
      label: 'Umsatz <5%',
      render: ({ sales_value_less_5 }) => <>{sales_value_less_5} %</>
    },
    {
      field: 'sales_value_more_5',
      label: 'Umsatz >5%',
      render: ({ sales_value_more_5 }) => <>{sales_value_more_5} %</>
    },
    {
      field: 'edit',
      label: 'Edit',
      sortable: false,
      render: (doc) => (
        <Box
          sx={styles.actionButton}
          onClick={() => handleOpenUpdateModal(doc)}
        >
          <PencilIcon height={20} width={20} />
        </Box>
      ),
    },
    {
      field: 'delete',
      label: 'Delete',
      sortable: false,
      render: ({ _id }) => (
        <Box
          sx={styles.actionButton}
          onClick={() => handleDelete(_id)}
        >
          <DeleteIcon height={20} width={20} />
        </Box>
      ),
    },
  ];

  const handleOpenUpdateModal = (doc: any) => {
    setSelectedDoc(doc);
    dialogs.esgArea.setVisible(true);
  };

  const handleDelete = (id: string) => {
    if (customer?.id) {
      apis.esg.deleteCustomerArea(customer.id, id)
        .then((res) => {
          activeToast({
            type: 'success',
            text: res.msg,
          });

          fetchData();
        })
        .catch((err) => {
          activeToast({
            type: 'error',
            text: err.msg,
          });
        })
    }
  };

  // Change date handler
  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  // Submit updated date
  const handleUpdateDate = () => {
    if (customer && date) {
      apis.esg.updateEsgAreaDate(customer?.id, date)
        .then(res => {
          activeToast({
            type: 'success',
            text: res.msg,
          });
          getMe();
          setDateActive(false);
        })
        .catch(err => {
          activeToast({
            type: 'error',
            text: err.msg,
          });
          setDateActive(false);
        })
    }
  };

  // Fetch data
  const fetchData = () => {
    if (customer) {
      esgAreaDocs.fetchData(customer.id, {
        options: {
          limit: pageLimit,
          skip: (pageNumber - 1) * pageLimit,
          sort: {
            [sort.field]: sort.order,
          },
        },
      });
    }
  };

  // Sort handler
  const handleSort = (value: ISortBy) => {
    setSort(value);
  };

  // Selected change handler
  const handleChangeSelected = (selected: (string | number)[]) => {
    setSelectedDoc(selected);
  };

  // Create handler
  const handleCreate = () => {
    dialogs.esgArea.setVisible(true);
  };

  // Close handler
  const handleClose = () => {
    setSelectedDoc(null);
    dialogs.esgArea.setVisible(false);
  };

  // Edit date handler
  const handleActiveDate = () => {
    setDateActive(true);
  };

  // Cancel update date
  const handleCancelUpdateDate = () => {
    setDateActive(false);
    const date = customer?.esgAreaDate || new Date().toString() ;
    setDate(moment(date).format('YYYY-MM-DD'));
  };

  // change customer
  const handleChangeCustomer = (value: IAccount | null) => {
    setCustomer(value);
  };

  // On mounted
  useEffect(() => {
    fetchData();
  }, []);

  // On page number, sort & account changed
  useEffect(() => {
    fetchData();
  }, [pageNumber, sort, customer?.id]);

  useEffect(() => {
    if (customer) {
      const date = customer.esgAreaDate || new Date().toString() ;
      setDate(moment(date).format('YYYY-MM-DD'));
    }
  }, [customer]);

  useEffect(() => {
    if (!isAdmin && account) {
      setCustomer(account);
    }
  }, [isAdmin, account]);

  // Return template panel
  return (
    <>
      <Stack>
        <Box display="flex" alignItems="center" mb={16}>
          {isAdmin && <UserSelector value={customer} onChange={handleChangeCustomer} />}
          <Button disabled={!customer} sx={{ marginLeft: 'auto' }} size="small" startIcon={<PlusIcon />} variant="varied-outlined" onClick={handleCreate}>
            Add New
          </Button>
        </Box>

        {customer ? (
          <>
            <Box>
              <Table
                columns={columns}
                data={esgAreaDocs.value}
                order={sort.order}
                orderBy={sort.field}
                isLoading={esgAreaDocs.isLoading}
                contentSize={{
                  width: {
                    xs: 'calc(100vw - 40px)',
                    sm: 'calc(100vw - 80px)',
                    md: 'calc(100vw - 256px)',
                    lg: 'calc(100vw - 376px)',
                  },
                }}
                onSort={handleSort}
                onChangeSelected={handleChangeSelected}
              />
            </Box>
            <Box mt={24}>
              {!dateActive ? (
                <Typography variant="body1" sx={styles.areaDate} onClick={handleActiveDate}>
                  Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: <b>{customer?.esgAreaDate ? moment(customer?.esgAreaDate).format('DD.MM.YYYY') : 'None'}</b>
                </Typography>
              ) : (
                <Box>
                  <OutlinedInput type="date" fullWidth value={date} onChange={handleChangeDate} />
                  <Stack direction="row" justifyContent="flex-end" mt={8} display={dateActive ? "flex" : "none"}>
                    <IconButton sx={{ mr: 8 }} size="small" onClick={handleUpdateDate}>
                      <Check />
                    </IconButton>
                    <IconButton size="small" onClick={handleCancelUpdateDate}>
                      <Close />
                    </IconButton>
                  </Stack>
                </Box>
              )}
            </Box>
          </>
        ) : (
          <>
            {isAdmin && <Typography variant="h2" align="center" sx={styles.placeholder}>Please select customer</Typography>}
          </>
        )}
      </Stack>
      {dialogs.esgArea.visible && (
        <EsgAreaDialog
          customer={customer}
          open={dialogs.esgArea.visible}
          document={selectedDoc}
          onClose={handleClose}
        />
      )}
    </>
  );
};
