// Dependencies
import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useDrag } from 'react-dnd';

// Styles
import styles from './styles';

// Interfaces
interface IItemProps {
  name: string;
  type: string;
}

// Export Item component
export const Item: FC<IItemProps> = ({ name, type }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <Typography variant="h6" ref={drag} style={{ opacity }} sx={styles.item}>
      {name}
    </Typography>
  );
};
