// Dependencies
import { FC, useRef } from 'react';
import { Avatar, Button, Divider, Stack, Typography } from '@mui/material';

// Components
import { Dialog } from '../../Dialog';

// Icons
import { DeleteIcon, PencilIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Interfaces
interface IUploadAvatarDialogProps {
  avatar: string | any;
  open: boolean;
  onChange: (value: any) => void;
  onClose: () => void;
}

// Export upload avatar dialog
export const UploadAvatarDialog: FC<IUploadAvatarDialogProps> = ({ avatar, open, onChange, onClose }) => {
  // File selector
  const fileSelector = useRef<any>();

  // Open explorer to update image
  const handleOpenBrowser = () => {
    fileSelector.current.click();
  };

  // Update profile image
  const handleUpdateProfileImage = async (e: any) => {
    onChange(e.target.files[0]);
  };

  // Return upload avatar dialog
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <Typography mb={24} variant="headline">
        CIXON Account
      </Typography>
      <Typography mb={13} variant="title">
        Profile picture
      </Typography>
      <Typography mb={15} variant="body2">
        A picture helps people recognize you and lets you know when you’re signed in to your account
      </Typography>
      <Divider />
      <Stack mt={32} px={8} spacing={32} alignItems="center">
        <Avatar src={typeof avatar === 'string' ? avatar : URL.createObjectURL(avatar)} sx={styles.avatar} />
        <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleUpdateProfileImage} />
        <Stack width="100%" direction="row" spacing={68} justifyContent="space-between">
          <Button startIcon={<PencilIcon />} variant="varied-outlined" onClick={handleOpenBrowser}>
            Change
          </Button>
          <Button startIcon={<DeleteIcon />} variant="varied-outlined" onClick={() => onChange('')}>
            Remove
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
