// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: theme.spacing(98, 20),
    maxWidth: { xs: 560, md: 890 },
    width: 1,
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  form: () => ({
    width: 1,
    maxWidth: 420,
  }),
  logoUploader: () => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer'
  }),
  logo: () => ({
    width: 100,
    height: 100,
  }),
};

// Export styles
export default styles;
