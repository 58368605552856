// Resources
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  actionButton: () => ({
    cursor: 'pointer'
  }),
  areaDate: () => ({
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }),
  placeholder: (theme) => ({
    marginTop: theme.spacing(20),
    color: theme.palette.gray.light,
  })
};

// Export styles
export default styles;
