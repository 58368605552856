// Dependencies
import { alpha } from '@mui/material';

// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  header: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2,
    p: { xs: theme.spacing(16, 20, 64), md: theme.spacing(16, 20) },
    boxShadow: theme.shadows[1],
  }),
  toolbar: (theme) => ({
    position: 'absolute',
    left: 20,
    bottom: 16,
    borderRadius: { xs: 18, md: 23 },
    p: { xs: theme.spacing(3), md: theme.spacing(5) },
    border: `1px solid ${theme.palette.secondary.main}`,
    '.MuiButton-root': {
      color: alpha(theme.palette.primary.main, 0.5),
      '.MuiButton-startIcon, .MuiButton-endIcon': {
        color: theme.palette.secondary.main,
      },
      ':hover': {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.gray.light, 0.5),
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          color: theme.palette.primary.main,
        },
      },
    },
    '.MuiDivider-root': {
      color: alpha('#070707', 0.2),
    },
  }),
  name: (theme, params) => ({
    height: { xs: 36, md: 46 },
    width: { xs: 'calc(100vw - 140px)', sm: params?.width },
    maxWidth: 720,
    border: 'none',
    borderRadius: 23,
    outline: 'none',
    color: alpha(theme.palette.primary.main, 0.5),
    '&.Mui-focused': {
      color: theme.palette.primary.main,
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
      },
    },
    ':hover': {
      color: theme.palette.primary.main,
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '.MuiOutlinedInput-input': {
      textAlign: 'center',
      ...theme.typography.button,
      textTransform: 'none',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  }),
  action: () => ({
    position: 'absolute',
    right: 20,
    bottom: 16,
  }),
  popover: (theme) => ({
    '.MuiPaper-root': {
      p: theme.spacing(20),
      m: theme.spacing(-12, 20, 20),
      boxShadow: theme.shadows[0],
      backgroundColor: 'transparent',
    },
  }),
  menuIcon: () => ({
    position: 'absolute',
    left: 20,
    display: { xs: 'inline-flex', md: 'none' },
  }),
};

// Export styles
export default styles;
