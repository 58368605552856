// Dependencies
import { IStyles } from '../../../../../resource';

// Styles
const styles: IStyles = {
  container: (theme) => ({
    p: theme.spacing(20, 0),
    borderTop: '1px solid #a7a8ab',
    borderBottom: '1px solid #a7a8ab',
    display: 'flex',
    justifyContent: 'space-between',
    img: {
      width: '100%',
      height: '100%',
    },
  }),
  image1: () => ({
    width: 150,
    height: 200,
  }),
  image2: () => ({
    width: 230,
    height: 230,
  }),
  imageBar: (theme, color) => ({
    background: color,
    height: '40px',
    marginBottom: '10px'
  }),
  imageWrapper: (theme, color) => ({
    background: color,
    padding: '25px',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid red',
    }
  }),
  fondInput: (theme, color)=> ({
    '.MuiInputBase-root': {
      lineHeight: '42px',
      color,
      fontWeight: 600,
      fontSize: '48px',
      '&:before': {
        borderBottomColor: 'transparent',
      },
    }
  }),
};

// Export styles
export default styles;
