// Dependencies
import React, { FC } from 'react';
import { alpha, Checkbox, TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel } from '@mui/material';

// Icons
import { ArrowDownIcon, ArrowUpIcon } from '../../../assets/icons';

// Styles
import styles from './styles';

// Interfaces
import { IColumn } from '../index';
import { ISortBy, ORDER } from '../../../resource';

interface ITableHeadProps {
  order?: ORDER;
  orderBy?: string;
  numSelected: number;
  rowCount: number;
  rowSelectable: boolean;
  columns: IColumn[];
  isLoading?: boolean;
  onSort: (sortBy: ISortBy) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// Create TableHead component
const TableHead: FC<ITableHeadProps> = ({
  order,
  orderBy,
  columns,
  numSelected,
  rowSelectable,
  rowCount,
  isLoading,
  onSort,
  onSelectAllClick,
}) => {
  // Sort handler
  const handleSort = (field?: string) => {
    if (field) {
      onSort({
        field,
        order: order === ORDER.DESC ? ORDER.ASC : ORDER.DESC,
      });
    }
  };

  // Return TableHead component
  return (
    <>
      <MuiTableHead>
        <TableRow>
          {rowSelectable && (
            <TableCell padding="checkbox">
              <Checkbox
                disabled={isLoading}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                sx={styles.checkbox}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columns.map(({ field, label, sortable = true, width }, index) => (
            <TableCell
              key={index}
              sx={(theme) => ({
                width: width ? width : 'auto',
                whiteSpace: 'noWrap',
                color: `${
                  orderBy === field ? theme.palette.gray.dark : alpha(theme.palette.gray.dark, 0.5)
                } !important`,
              })}
            >
              {label}
              {sortable && (
                <TableSortLabel
                  active={orderBy === field}
                  IconComponent={() =>
                    orderBy === field ? order === ORDER.ASC ? <ArrowDownIcon /> : <ArrowUpIcon /> : <ArrowDownIcon />
                  }
                  sx={styles.sortLabel}
                  onClick={() => handleSort(field)}
                />
              )}
            </TableCell>
          ))}
        </TableRow>
      </MuiTableHead>
    </>
  );
};

export default TableHead;
