import React, {ChangeEvent, useState} from 'react';
import {Box, FormControlLabel, IconButton, Switch, Typography} from '@mui/material';
import styles from './styles';
import {CellConfigDialog} from '../../../../../../components/Dialogs/CellConfig';
import {Settings} from '@mui/icons-material';

type Props = {
  id?: number;
  content?: any;
  preview: boolean;
  onChange: (content: any) => void;
};

export const TextRow = ({ preview, content, onChange }: Props) => {
  const [openConfigDlg, setOpenConfigDlg] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  console.log('content>>>', content);
  const handleToggleDynamicTitle = () => {
    onChange(content?.isDynamic ? '' : {
      isDynamic: true,
      fields: [],
    });
  };

  const handleChangeConfig = (config) => {
    onChange(config);
    setOpenConfigDlg(false);
  };

  const handleOpenConfig = () => {
    setOpenConfigDlg(!openConfigDlg);
  };

  return (
    <Box sx={(theme) => styles.textRow(theme, preview)} >
      {preview ? (
        <Typography variant="body2">
          {content}
        </Typography>
      ) : (
        <>
          {
            content?.isDynamic ? (
              <>
                <Box sx={styles.dataConfigInputWrapper}>
                  {content.fields?.map((field, index) => (
                    <Box
                      key={`${index}th`}
                      sx={(theme) => styles.configField(
                        theme,
                        { type: field.type }
                      )}
                    >
                      <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
                        {field.type === 'text' ? field.text : `${field.table || '(table)'}.${field.field || '(field)'}`}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <CellConfigDialog
                  isin={content.isin}
                  config={content}
                  open={openConfigDlg}
                  onClose={() => setOpenConfigDlg(false)}
                  onConfirm={handleChangeConfig}
                />
                <IconButton sx={{ mr: 4, width: 32, height: 32 }} onClick={handleOpenConfig}>
                  <Settings />
                </IconButton>
              </>
              ) : (
              <input value={content} onChange={handleChange} />
            )
          }

          <FormControlLabel
            label="Is dynamic?"
            sx={styles.switch}
            control={
              <Switch
                checked={content.title?.isDynamic}
                color="secondary"
                onChange={handleToggleDynamicTitle}
              />
            }
          />
        </>
      )}
    </Box>
  )
};
