// Interfaces
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  dialog: (theme) => ({
    position: 'relative',
    p: theme.spacing(20, 20, 20, 20),
    backgroundColor: '#FFF',
    borderRadius: theme.spacing(30, 0),
    width: 600,
  }),
  footer: (theme) => ({
    marginTop: theme.spacing(20),
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(10),
  }),
};

// Export styles
export default styles;
