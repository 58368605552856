// Dependencies
import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';

// Styles
import styles from './styles';

// Interfaces
interface IEditableTextarea {
  children?: ReactNode;
}

// Export Editable Textarea
export const EditableTextarea: FC<IEditableTextarea> = ({ children }) => {
  // Return Editable Textarea
  return (
    <Typography sx={styles.textarea} contentEditable>
      {children ? children : 'Textarea'}
    </Typography>
  );
};
