// Dependencies
/* tslint:disable */
import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

// Components
import { Dialog } from '../../Dialog';

// Styles
import styles from './styles';
import ReactDOM from 'react-dom/client';
import { CDN_GENERATOR_PATH } from '../../../contants';

interface IWidgetPreviewDialog {
  open: boolean;
  selectedWidget: any | null;
  onClose: () => void;
}
async function loadCixonClient(widgetId: string) {
  const response = await fetch(CDN_GENERATOR_PATH);
  const code = await response.text();
  const module = eval(code);
  const App = module.default;

  const cdnWrapper = document.getElementById('cdn-wrapper');
  if (cdnWrapper) {
    cdnWrapper.innerHTML = `<div class="cdn-root" widgetId="${widgetId}" page="factsheet"></div>`;
  }

  // Render the React components inside the <div> element
  // @ts-ignore
  const root = ReactDOM.createRoot(elements[0]);
  root.render(
    <>
      <App widgetId={widgetId} page="factsheet" />
    </>
  );
}

// Export ESG Geschäftsfelder doc dialog
export const WidgetPreviewDialog: FC<IWidgetPreviewDialog> = ({ open, selectedWidget, onClose }) => {
  const { _id: widgetId } = selectedWidget;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedWidget) {
      const cdnWrapper = document.getElementById('cdn-wrapper');
      if (cdnWrapper) {
        cdnWrapper.innerHTML += `<div class="cdn-root" widgetId="${widgetId}" page="factsheet"></div>`;
      }

      setLoading(true);
      loadCixonClient(widgetId).then(() => {
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [selectedWidget]);

  // Return customer doc dialog
    return (
      <>
        <Dialog open={open} onClose={onClose} sx={styles.dialog}>
          <Box id="cdn-wrapper" sx={styles.cdnWrapper}>
          </Box>
        </Dialog>
      </>
    );
};
