import http from './http';

export const getTemplates = async () => {
  return await http.get('/templates');
};

export const getTemplate = async (id: string) => {
  return await http.get(`/templates/${id}`);
};

export const updateTemplate = async(id: string, data: any) => {
  return await http.put(`/templates/${id}`, { data });
};

export const createTemplate = async(data: any) => {
  return await http.post(`/templates`, { data });
};
