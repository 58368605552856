// Dependencies
import React, {ChangeEvent, FC} from 'react';
import {Box, Button, Grid, Stack, Typography, OutlinedInput, IconButton, FormControlLabel, Switch} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { Chart } from '../../../../../PDFViewer/components';

type BlocksProps = {
  id: number,
  content: any,
  preview: boolean,
  onChange: (id: number, contentData: any) => void;
}

// Export template panel
export const Blocks: FC<BlocksProps> = ({
  id,
  content,
  preview,
  onChange,
}) => {
  // Change data handler
  const handleChangeData = (e, index) => {
    if (Array.isArray(content)) {
      onChange(id, content.map((p, i) => index === i ? { ...p, [e.target.name]: e.target.value } : p));
    } else {
      onChange(id, { ...content, data: content.data.map((p, i) => index === i ? {...p, [e.target.name]: e.target.value} : p)});
    }
  };

  // Add data handler
  const handleAddData = () => {
    if (Array.isArray(content)) {
      onChange(id, [...content, { label: '', value: 0 }]);
    } else {
      onChange(id, { ...content, data: [...content.data, { label: '', value: 0 }] });
    }
  };

  // Change remove item
  const handleRemoveLine = (index: number) => {
    if (Array.isArray(content)) {
      onChange(id, content.slice(0, index).concat(content.slice(index + 1)));
    } else {
      onChange(id, { ...content, data: content.data.slice(0, index).concat(content.data.slice(index + 1))});
    }
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(content)) {
      onChange(id, { data: content, title: e.target.value });
    } else {
      onChange(id, { ...content, title: e.target.value });
    }
  };

  const handleChangeSubTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (Array.isArray(content)) {
      onChange(id, { data: content, subtitle: e.target.value });
    } else {
      onChange(id, { ...content, subtitle: e.target.value });
    }
  };

  // Return template panel
  return (
    <Stack>
      <Box>
        {preview ? (
          <>
            {preview}
          </>
        ) : (
          <>
            <Box sx={{ mb: 16 }}>
              <Typography variant="h5">Blocks</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={16} mb={16}>
              <Typography sx={{ width: '70px' }} variant="subtitle1">Title</Typography>
              <OutlinedInput sx={{ width: '300px' }} name="title" type="string" value={content.title || ""} onChange={handleChangeTitle} />
            </Box>
            <Box display="flex" alignItems="center" gap="16px" mb={16}>
              <Typography sx={{ width: '70px' }} variant="subtitle1">Subtitle</Typography>
              <OutlinedInput sx={{ width: '300px' }} name="subtitle" type="string" value={content.subtitle || ""} onChange={handleChangeSubTitle} />
            </Box>
            <Grid container spacing={30}>
              {content && (Array.isArray(content) ? content : content.data).map((item, index) => (
                <React.Fragment key={`${item.id}_${index}`}>
                  <Grid item xs={12} sm={5.5}>
                    <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>Label:</Typography>
                    <OutlinedInput name="label" type="string" fullWidth value={item.label || ""} onChange={(e) => handleChangeData(e, index)} />
                  </Grid>
                  <Grid item xs={12} sm={5.5}>
                    <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>Value:</Typography>
                    <OutlinedInput name="value" type="number" fullWidth value={item.value || ""} onChange={(e) => handleChangeData(e, index)} />
                  </Grid>
                  <Grid item xs={12} sm={1} display="flex">
                    <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveLine(index)}>
                      <Remove />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item container xs={12} sm={6} md={4} spacing={10}>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth startIcon={<Add />} onClick={handleAddData}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Stack>
  );
};
