// Dependencies
import { alpha, darken } from '@mui/material';

// Resources
import { IStyles } from '../../../../resource';

// Define styles
const styles: IStyles = {
  line: (theme) => ({
    height: 32,
    width: '100%',
    borderRadius: theme.spacing(16),
    color: theme.palette.secondary.main,
    ':hover': {
      backgroundColor: darken(theme.palette.gray.light, 0.1),
    },
  }),
  shape: (theme) => ({
    height: 100,
    width: '100%',
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(16),
    ':hover': {
      backgroundColor: darken(theme.palette.gray.light, 0.1),
    },
  }),
  transform: (theme) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    p: { xs: theme.spacing(4, 6), md: theme.spacing(6) },
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(23),
    backgroundColor: theme.palette.common.white,
  }),
  label: (theme) => ({
    width: { xs: 20, md: 26 },
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  }),
  input: (theme) => ({
    width: 36,
    ...theme.typography.button,
    color: alpha(theme.palette.primary.main, 0.5),
    borderBottom: `1px solid ${alpha(theme.palette.gray.main, 0.2)}`,
    '.MuiInputBase-input': {
      height: { xs: 18, md: 21 },
      textAlign: 'center',
      p: theme.spacing(0, 0, 2),
    },
  }),
  flip: (theme) => ({
    width: { xs: 28, md: 34 },
    height: { xs: 28, md: 34 },
    color: theme.palette.secondary.main,
    borderRadius: theme.spacing(17),
    ':hover': {
      backgroundColor: alpha(theme.palette.gray.light, 0.5),
    },
  }),
  slider: (theme) => ({
    height: 24,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    p: { xs: theme.spacing(5, 16), md: theme.spacing(10, 16) },
    borderRadius: theme.spacing(46),
    border: `1px solid ${theme.palette.secondary.main}`,
    '.MuiSlider-root': {
      height: '1px',
      width: 121,
      '.MuiSlider-rail': {
        opacity: 1,
        backgroundColor: alpha(theme.palette.gray.main, 0.2),
      },
      '.MuiSlider-thumb': {
        width: 14,
        height: 14,
        backgroundColor: theme.palette.gray.light,
        border: `2px solid ${theme.palette.secondary.main}`,
        ':hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  }),
};

// Export styles
export default styles;
