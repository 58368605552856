// Dependencies
import { ChangeEvent, FC, useState } from 'react';
import { Button } from '@mui/material';

// Styles
import styles from './styles';

// Interfaces
export interface IEditableImageProps {
  source?: string;
}

// Export Editable Image
export const EditableImage: FC<IEditableImageProps> = ({ source }) => {
  // States
  const [src, setSrc] = useState(source);

  // Upload image handler
  const handleUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  // Return Editable Image
  return (
    <>
      {src ? (
        <img src={src} alt="image" style={styles.image} />
      ) : (
        <label htmlFor="upload-file">
          <input accept="image/*" id="upload-file" type="file" style={styles.input} onChange={handleUploadImage} />
          <Button variant="outlined" component="span" sx={styles.uploadButton}>
            Upload
          </Button>
        </label>
      )}
    </>
  );
};
