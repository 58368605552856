// Dependencies
import React, { FC, useState } from 'react';
import { useDrop } from 'react-dnd';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';

// Styles
import styles from './styles';

// Components
import { EDITABLE_ITEM, Editable, Item, IEditableProps } from '../../components';
import { generateCustomDocument } from '../../apis/pdf.apis';

// Constants
const components = [
  {
    name: 'Image',
    type: EDITABLE_ITEM.IMAGE,
  },
  {
    name: 'Label',
    type: EDITABLE_ITEM.LABEL,
  },
  {
    name: 'Divider',
    type: EDITABLE_ITEM.DIVIDER,
  },
  {
    name: 'Text Area',
    type: EDITABLE_ITEM.TEXTAREA,
  },
];

// Export Generator page
export const GeneratorPage: FC = () => {
  // States
  const [editables, setEditables] = useState<IEditableProps[]>([]);

  // Drop handler
  const handleDrop = (item: IEditableProps) => {
    setEditables([...editables, item]);
  };

  const [, drop] = useDrop({
    accept: [EDITABLE_ITEM.IMAGE, EDITABLE_ITEM.LABEL, EDITABLE_ITEM.DIVIDER, EDITABLE_ITEM.TEXTAREA],
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // Editable change handler
  const handleChangeEditable = (newEditable: IEditableProps, id: number) => {
    const newEditables = editables.map((editable, index) => {
      if (id === index) {
        return newEditable;
      } else {
        return editable;
      }
    });

    setEditables(newEditables);
  };

  // Generate Document
  const handleGenerate = async () => {
    await generateCustomDocument(
      editables.map((item) => {
        let x = 0;
        if (item?.draggableProps?.x && typeof item?.draggableProps?.x === 'string') {
          x = +item?.draggableProps?.x?.replace('%', '') / 100;
        }

        return {
          type: item.type,
          content: item.content || '',
          pos: {
            x: x * 210,
            y: +(item?.draggableProps?.y || 0),
          },
        };
      })
    );
  };

  // Return Generator page
  return (
    <Box sx={styles.container}>
      <Button variant="contained" sx={styles.generateButton} onClick={handleGenerate}>
        Generate
      </Button>
      <Grid container columns={4} columnSpacing={20} height="100%">
        <Grid item xs={1}>
          <Card sx={styles.card}>
            {components.map(({ name, type }, index) => (
              <Item key={index} name={name} type={type} />
            ))}
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={styles.card}>
            <CardContent ref={drop} sx={styles.cardContent}>
              {editables.map((editable, index) => (
                <Editable
                  key={index}
                  {...editable}
                  onEditableChange={(editable) => handleChangeEditable(editable, index)}
                />
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
