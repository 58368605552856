// Dependencies
import { FC, MouseEvent, useContext, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  Dialog,
  Drawer,
  IconButton,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Components
import { ChartPanel, ElementPanel, TemplatePanel, TextPanel, UploadPanel } from '../Panel';

// Global constants
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../contants';

// Icons
import { CloseIcon, HelpIcon, LogoutIcon, SettingsIcon } from '../../../assets/icons';

// Context
import { AppContext } from '../../../context';
// import styles = module

// Styles
import styles from '../Sidebar/styles';

// Interfaces
interface ISidebarProps {
  open: boolean;
  onClose: () => void;
}

// Constants
enum Panel {
  Text,
  Element,
  Chart,
  Upload,
  Template,
}

const items = [
  {
    label: 'Text',
    icon: 'text',
    panel: Panel.Text,
  },
  {
    label: 'Element',
    icon: 'element',
    panel: Panel.Element,
  },
  {
    label: 'Chart',
    icon: 'element',
    panel: Panel.Chart,
  },
  {
    label: 'Upload',
    icon: 'upload',
    panel: Panel.Upload,
  },
  {
    label: 'Template',
    icon: 'template',
    panel: Panel.Template,
  },
];

// Create sidebar
const Sidebar: FC<ISidebarProps> = ({ open, onClose }) => {
  // States
  const [hoverId, setHoverId] = useState<number>();
  const [activePanel, setActivePanel] = useState<number | null>(null);

  // Context
  const { setAccount } = useContext(AppContext);

  // Theme
  const theme = useTheme();

  // Check window size
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  const isExpandedPanel = useMemo(() => activePanel !== null && isTablet, [isTablet, activePanel]);
  const isOpenPanel = useMemo(() => activePanel !== null && !isTablet, [isTablet, activePanel]);

  // Anchor
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  // Hover handler
  const handleHover = (id: number) => () => {
    setHoverId(id);
  };

  // Blur handler
  const handleBlur = () => {
    setHoverId(undefined);
  };

  // Open popover handler
  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Close profile handler
  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  // Click handler
  const handleOpenPanel = (panel: number) => () => {
    if (panel === activePanel) {
      setActivePanel(null);
    } else {
      setActivePanel(panel);
    }
  };

  // Close profile handler
  const handleClosePanel = () => {
    setActivePanel(null);
  };

  // Log out handler
  const handleLogout = () => {
    setAccount(null);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  };

  // Get source
  const getIcon = (id: number, icon: string) => {
    if (id === activePanel) {
      return icon + '-active';
    } else if (id === hoverId) {
      return icon + '-hover';
    } else {
      return icon;
    }
  };

  const profile = [
    {
      label: 'Help',
      icon: <HelpIcon />,
    },
    {
      label: 'Setting',
      icon: <SettingsIcon />,
    },
    {
      label: 'Log Out',
      icon: <LogoutIcon />,
      handler: handleLogout,
    },
  ];

  const EditPanel = () => (
    <Box sx={styles.panel}>
      <PerfectScrollbar style={{ height: 'calc(100vh - 167px)', paddingBottom: 5 }}>
        {activePanel === Panel.Text && <TextPanel />}
        {activePanel === Panel.Element && <ElementPanel />}
        {activePanel === Panel.Chart && <ChartPanel />}
        {activePanel === Panel.Upload && <UploadPanel />}
        {activePanel === Panel.Template && <TemplatePanel />}
      </PerfectScrollbar>
      <IconButton size="small" onClick={handleClosePanel}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  // Return sidebar
  return (
    <>
      <Drawer
        open={open}
        variant={isTablet ? 'permanent' : 'temporary'}
        sx={(theme) => styles.drawer(theme, { expanded: isExpandedPanel })}
        onClose={onClose}
      >
        <Box sx={styles.sidebar}>
          {items.map(({ label, icon, panel }, index) => (
            <Box
              key={index}
              sx={styles.item}
              onClick={handleOpenPanel(panel)}
              onMouseEnter={handleHover(index)}
              onMouseLeave={handleBlur}
            >
              <Box sx={styles.icon}>
                <img src={'/icons/' + getIcon(index, icon) + '.svg'} alt={icon} />
              </Box>
              <Typography variant="label1" textAlign="center">
                {label}
              </Typography>
            </Box>
          ))}
          <Avatar sx={styles.profile} onClick={handleOpen} />
        </Box>
        {isExpandedPanel && <EditPanel />}
      </Drawer>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handleCloseProfile}
          sx={styles.popover}
        >
          <Box sx={styles.card}>
            <Stack direction="row" alignItems="center" spacing={16} sx={styles.cardHeader}>
              <Avatar />
              <Stack spacing={14}>
                <Typography variant="title">Jane Doe</Typography>
                <Typography
                  variant="subtitle"
                  color="secondary"
                  component={Link}
                  to={ROUTES.PROFILE}
                  onClick={handleCloseProfile}
                >
                  Edit Profile
                </Typography>
              </Stack>
            </Stack>
            <Box sx={styles.divider} />
            <Stack>
              {profile.map(({ label, icon }, index) => (
                <ButtonBase key={index} sx={styles.menuitem}>
                  {icon}
                  {label}
                </ButtonBase>
              ))}
            </Stack>
          </Box>
        </Popover>
      )}
      {isOpenPanel && (
        <Dialog open={isOpenPanel} onClose={handleClosePanel} sx={styles.dialog}>
          <EditPanel />
        </Dialog>
      )}
    </>
  );
};

// Export sidebar
export default Sidebar;
