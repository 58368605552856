// Dependencies
import { FC, useContext, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Fab,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ArrowUpward } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Yup from 'yup';

// Global constants
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../contants';

// Context
import { AppContext } from '../../../context';
import { useToastContext } from '../../../components/Toast/toastContext';

// Apis
import apis from '../../../apis';

// Styles
import styles from './styles';

// Validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Field!').required('Required Field!'),
  password: Yup.string().required('Required Field!'),
});

// Create header component
const Header: FC = () => {
  // States
  const [visibleForgotPassword, setVisibleForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { activeToast } = useToastContext();

  // Context
  const { setAccount, dialogs } = useContext(AppContext);

  // Get navigate from hook
  const navigate = useNavigate();

  // Login handler
  const handleLogin = () => {
    navigate(ROUTES.AUTH.LOGIN);
    dialogs.login.setVisible(true);
  };

  // Sign up handler
  const handleSignUp = () => {
    navigate(ROUTES.AUTH.SIGN_UP);
    dialogs.login.setVisible(false);
  };

  // Close handler
  const handleClose = () => {
    dialogs.login.setVisible(false);
  };

  // Go to login handler
  const handleGotoLogin = () => {
    setVisibleForgotPassword(false);
  };

  // Go to forgot password handler
  const handleGotoForgotPassword = () => {
    setVisibleForgotPassword(true);
  };

  // Submit handler
  const handleSubmit = ({ email, password }: any) => {
    setIsLoading(true);
    apis.auth
      .login(email, password)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        const { user, token } = res;
        setAccount(user);
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
      })
      .catch((err) => {
        setIsLoading(false);
        activeToast({
          type: 'error',
          text: err.msg || 'Failed to login',
        });
        console.log(err);
      });
  };

  // Forgot password handler
  const handleForgotPassword = ({ email }: any) => {
    console.log('test');
    apis.auth
      .forgotPassword(email)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Return header component
  return (
    <>
      <Box sx={styles.header}>
        <Box sx={styles.brand} component={Link} to={ROUTES.AUTH.LOGIN}>
          <Box component="img" src="/logo.png" alt="logo" sx={styles.logo} />
          <Typography variant="title" sx={{ display: { xs: 'none', md: 'block' } }}>
            CIXON Finance Software
          </Typography>
        </Box>
        <Stack direction="row" spacing={32}>
          <Button variant="outlined" sx={styles.button} onClick={handleSignUp}>
            Sign Up
          </Button>
          <Button sx={styles.button} onClick={handleLogin}>
            Login
          </Button>
        </Stack>
      </Box>
      <Dialog maxWidth="lg" fullWidth open={dialogs.login.visible} sx={styles.dialog}>
        <Box component={PerfectScrollbar} sx={styles.dialogContent}>
          {!visibleForgotPassword ? (
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                <Box component={Form} onSubmit={handleSubmit} sx={styles.form}>
                  <Stack width={1} spacing={34}>
                    <TextField
                      fullWidth
                      name="email"
                      value={values.email}
                      error={Boolean(errors.email && touched.email)}
                      helperText={Boolean(errors.email && touched.email) && errors.email}
                      placeholder="Email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src="/icons/mail.svg" alt="mail" />
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <TextField
                      fullWidth
                      type="password"
                      name="password"
                      value={values.password}
                      error={Boolean(errors.password && touched.password)}
                      helperText={Boolean(errors.password && touched.password) && errors.password}
                      placeholder="Password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src="/icons/lock.svg" alt="lock" />
                          </InputAdornment>
                        ),
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Stack>
                  <Stack width={1} mt={48} spacing={16}>
                    <Button fullWidth type="submit" startIcon={<img src="/icons/login-line.svg" alt="login" />}>
                      {isLoading ? <CircularProgress color={'inherit'} size={30} /> : 'Login'}
                    </Button>
                    <Typography
                      textAlign="center"
                      variant="caption"
                      color="secondary"
                      component={Link}
                      to="#"
                      onClick={handleGotoForgotPassword}
                    >
                      Forgot your password?
                    </Typography>
                  </Stack>
                </Box>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={handleForgotPassword}
            >
              {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
                <Box component={Form} onSubmit={handleSubmit} sx={styles.form}>
                  <TextField
                    fullWidth
                    name="email"
                    value={values.email}
                    error={Boolean(errors.email && touched.email)}
                    helperText={Boolean(errors.email && touched.email) && errors.email}
                    placeholder="Email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src="/icons/mail.svg" alt="mail" />
                        </InputAdornment>
                      ),
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Stack mt={24} spacing={16}>
                    <Button fullWidth type="submit">
                      Submit
                    </Button>
                    <Typography
                      textAlign="center"
                      variant="caption"
                      color="secondary"
                      component={Link}
                      to="#"
                      onClick={handleGotoLogin}
                    >
                      Go to login
                    </Typography>
                  </Stack>
                </Box>
              )}
            </Formik>
          )}
          <Fab sx={styles.close} size="small" onClick={handleClose}>
            <ArrowUpward />
          </Fab>
        </Box>
      </Dialog>
    </>
  );
};

// Export header component
export default Header;
