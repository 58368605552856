// Create styles
const styles = {
  backgroundColor:'#E6EAED',
  borderBottomRightRadius:25,
  borderTopLeftRadius:25,
  color:'#003c50',
  height:90,
  width:'auto',
  messageContainer:{
    width:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  messageContent:{
    display:'flex',
    flexDirection:'column'
  },
  iconContainer:{
    width:50,
    alignItems:'center',
    display:'flex',
    justifyContent:'center',
    height:50,
    borderRadius:50,
  },
  heading:{marginLeft:15, fontSize:18, color:'#003c50'},
  description:{marginLeft:15,  marginTop:5, fontSize:16},
  link:{marginLeft:15, marginTop:5, fontSize:16},
  cancel:{cursor:'pointer', marginRight:15}
};

// Export styles
export default styles;
