// Dependencies
import { alpha, darken } from '@mui/material';

// Resources
import { IStyles } from '../../../../resource';

// Define styles
const styles: IStyles = {
  cell: (theme, params) => ({
    width: 16,
    height: 16,
    backgroundColor: params?.active ? alpha(theme.palette.secondary.main, 0.3) : theme.palette.common.white,
    border: `1px solid ${params?.active ? theme.palette.secondary.main : darken(theme.palette.gray.light, 0.1)}`,
    ':hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.3),
    },
  }),
  input: (theme) => ({
    width: 36,
    ...theme.typography.button,
    color: alpha(theme.palette.primary.main, 0.5),
    borderBottom: `1px solid ${alpha(theme.palette.gray.main, 0.2)}`,
    input: {
      height: 21,
      textAlign: 'center',
      p: theme.spacing(0, 0, 2),
    },
  }),
  chart: (theme) => ({
    width: 87,
    height: 87,
    borderRadius: theme.spacing(16),
    ':hover': {
      backgroundColor: darken(theme.palette.gray.light, 0.1),
    },
  }),
};

// Export styles
export default styles;
