// Dependencies
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Divider,
} from '@mui/material';
import moment from 'moment';

// Component
import { Dialog } from '../../../../components/Dialog'

// Styles
import styles from '../../styles';

// Interfaces
interface ITextEditModal {
  title: string;
  open: boolean;
  data: string;
  type?: 'text' | 'date' | 'intro';
  loading: boolean;
  onClose: () => void;
  onSave: (updatedText: string | string[]) => void;
}

// Export TextEdit Modal
export const TextEditModal: FC<ITextEditModal> = ({
  title,
  open,
  data,
  type = 'text',
  loading,
  onClose,
  onSave,
}) => {
  // States
  const [value, setValue] = useState<string | string[]>('');

  // Input handler
  const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  // Save handler
  const handleSave = () => {
    onSave(value);
  };

  // Change intro
  const handleChangeIntro = (index, e: any) => {
    let intro;
    if (typeof value === 'string') {
      intro = [value, ''];
    } else if (Array.isArray(value)) {
      intro = [...value];
    }
    intro[index] = e.target.value;
    setValue(intro);
  };

  // On mounted
  useEffect(() => {
    setValue(type === 'date' ? moment(data).format('YYYY-MM-DD') : data);
  }, [data]);

  // Return TextEdit Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
      <DialogContent>
        {type === 'text' && (
          <Box sx={styles.textEditorWrapper}>
            <textarea
              value={value}
              onChange={handleInput}
            />
          </Box>
        )}
        {type === 'date' && (
          <OutlinedInput type="date" fullWidth value={value} onChange={handleInput} />
        )}
        {type === 'intro' && (
          <Box sx={styles.introEditor}>
            <textarea value={value && value[0]} onChange={(e) => handleChangeIntro(0, e)} />
            <Divider sx={{ margin: 10 }} orientation="vertical" flexItem />
            <textarea value={value && value[1]} onChange={(e) => handleChangeIntro(1, e)} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
};
