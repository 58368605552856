// Dependencies
import { FC, useContext, useEffect, useState } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Components
import { AuthLayout, MainLayout, WorkLayout } from '../layout';

// Constants
import { AUTH_ROUTES, MAIN_ROUTES, ROUTES, WORK_ROUTES } from '../contants';

// Context
import { AppContext } from '../context';

// Apis
import apis from '../apis';

// Create app router
const AppRouter: FC = () => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Context
  const { account, setAccount } = useContext(AppContext);

  // On mounted
  useEffect(() => {
    apis.auth
      .me()
      .then((res) => {
        setAccount(res.user);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Return app router
  return isLoading ? (
    <Stack spacing={20} sx={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <CircularProgress />
      <Typography variant="title">Cixon Finance Docs</Typography>
    </Stack>
  ) : (
    <BrowserRouter>
      <Routes>
        {!account &&
          AUTH_ROUTES.map(({ path, component: C }) => (
            <Route
              key={path}
              path={path}
              element={
                <AuthLayout>
                  <C />
                </AuthLayout>
              }
            />
          ))}
        {account &&
          MAIN_ROUTES.map(
            ({ path, role, component: C }) =>
              role &&
              account.role &&
              role.includes(account.role) && (
                <Route
                  key={path}
                  path={path}
                  element={
                    <MainLayout>
                      <C />
                    </MainLayout>
                  }
                />
              )
          )}
        {account &&
          WORK_ROUTES.map(({ path, component: C }) => (
            <Route
              key={path}
              path={path}
              element={
                <WorkLayout>
                  <C />
                </WorkLayout>
              }
            />
          ))}
        <Route path="*" element={<Navigate to={account ? ROUTES.DASHBOARD : ROUTES.AUTH.LOGIN} />} />
      </Routes>
    </BrowserRouter>
  );
};

// Export app router
export default AppRouter;
