// Dependencies
import { alpha } from '@mui/material';

// Interfaces
import { IStyles } from '../../resource';

// Define styles
const styles: IStyles = {
  checkbox: (theme) => ({
    color: alpha(theme.palette.secondary.main, 0.5),
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.primary.main,
    },
  }),
};

// Export styles
export default styles;
