// Resources
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  container: (theme) => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: 200,
    px: { xs: theme.spacing(20), sm: theme.spacing(80), md: theme.spacing(140) },
    backgroundImage: 'url(/images/login-bg.png)',
    backgroundPosition: theme.spacing(-320, -841),
    backgroundColor: theme.palette.gray.light,
    backgroundBlendMode: 'overlay',
  }),
};

// Export styles
export default styles;
