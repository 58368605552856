// Dependencies
import React, {ChangeEvent, FC} from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  OutlinedInput,
  IconButton,
  Switch,
  FormControlLabel, Select, MenuItem
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { Chart } from '../../../../../PDFViewer/components';
import styles from '../HistoryChart/styles';

type PieChartContentProps = {
  id: number,
  content: any,
  preview: boolean,
  onChange: (id: number, contentData: any) => void;
}

// Export template panel
export const PieChartContent: FC<PieChartContentProps> = ({
  id,
  content,
  preview,
  onChange,
}) => {
  // Change data handler
  const handleChangeData = (e, index) => {
    onChange(id, { ...content, chartData: content.chartData.map((p, i) => index === i ? { ...p, [e.target.name]: e.target.value } : p) });
  };

  // Add data handler
  const handleAddData = () => {
    onChange(id, { ...content, chartData: [...content.chartData, { label: '', value: 0 }] });
  };

  // Change remove item
  const handleRemoveLine = (index: number) => {
    onChange(id, { ...content, chartData: content.chartData.slice(0, index).concat(content.chartData.slice(index + 1)) });
  };

  const handleShowBlocks = () => {
    onChange(id, { ...content, showBlocks: !content.showBlocks });
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(id, { ...content, title: e.target.value });
  };

  const handleChangeSubTitle = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(id, { ...content, subtitle: e.target.value });
  };

  const handleChangePdfOption = (e) => {
    onChange(id, { ...content, pdfField: e.target.value });
  };

  // Return template panel
  return (
    <Stack>
      <Box>
        {preview ? (
          <>
            {preview}
            <Chart data={content.chartData.map((item) => ({ ...item, value: +item.value }))} type="donut" />
          </>
        ) : (
          <>
            <Typography variant="h5" mb={16}>Pie chart</Typography>
            <Box display="flex" alignItems="center" gap={16} mb={16}>
              <Typography sx={{ width: '70px' }} variant="subtitle1">Title</Typography>
              <OutlinedInput sx={{ width: '300px' }} name="title" type="string" value={content.title || ""} onChange={handleChangeTitle} />
              <FormControlLabel
                label="Show Blocks"
                sx={{ ml: 'auto' }}
                control={
                  <Switch
                    checked={content.showBlocks}
                    color="secondary"
                    onChange={handleShowBlocks}
                  />
                }
              />
            </Box>
            <Box display="flex" alignItems="center" gap="16px" mb={16}>
              <Typography sx={{ width: '70px' }} variant="subtitle1">Subtitle</Typography>
              <OutlinedInput sx={{ width: '300px' }} name="subtitle" type="string" value={content.subtitle || ""} onChange={handleChangeSubTitle} />
            </Box>
            <Box display="flex" alignItems="center" gap={16} mb={16}>
              <Typography variant="subtitle1">PDF field to sync:</Typography>
              <Select sx={styles.chartTypeSelect} value={content?.pdfField} onChange={handleChangePdfOption}>
                <MenuItem value="branchen">Branchen</MenuItem>
                <MenuItem value="regionen">Regionen</MenuItem>
                <MenuItem value="marktkapitalisierung">Marktkapitalisierung</MenuItem>
              </Select>
            </Box>
            <Grid container spacing={30}>
              {content && content.chartData.map((item, index) => (
                <React.Fragment key={`${item.id}_${index}`}>
                  <Grid item xs={12} sm={5.5}>
                    <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>Label:</Typography>
                    <OutlinedInput name="label" type="string" fullWidth value={item.label || ""} onChange={(e) => handleChangeData(e, index)} />
                  </Grid>
                  <Grid item xs={12} sm={5.5}>
                    <Typography variant="caption" sx={{ pl: 6, pb: 6 }}>Value:</Typography>
                    <OutlinedInput name="value" type="number" fullWidth value={item.value || ""} onChange={(e) => handleChangeData(e, index)} />
                  </Grid>
                  <Grid item xs={12} sm={1} display="flex">
                    <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveLine(index)}>
                      <Remove />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item container xs={12} sm={6} md={4} spacing={10}>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth startIcon={<Add />} onClick={handleAddData}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Stack>
  );
};
