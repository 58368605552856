import React, {createContext, ReactNode, useContext, useState} from 'react';
import {AlertColor} from '@mui/material';

//interface
interface IToast {
  isToastShow: boolean,
  type: AlertColor | undefined, //error, warning, info, success
  text: string,
  link:string,
  description: string,
  activeToast:(data:any)=>void
  closeToast:()=>void
}
interface IToastProviderProps {
  children: ReactNode;
}

const ToastContext = createContext<IToast>({
  isToastShow: false,
  type: 'success',
  text: '',
  description: 'Cixon',
  link:'',
  activeToast:(toast:any)=>{console.log(toast)},
  closeToast:()=>{console.log("onclose")}
});

export const ToastProvider = ({children}:IToastProviderProps) => {
  const [isToastShow, setIsToastShow] = useState<boolean>(false);
  const [type, setType] = useState<AlertColor>('success');
  const [text, setText] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const activeToast = (toast:any) => {
    setIsToastShow(true);
    setType(toast.type);
    setText(toast.text);
    setLink(toast.link);
    setDescription(
      toast.description !== undefined ? toast.description : '',
    );
  };

  const closeToast = () => {
    setIsToastShow(false);
  };

  const value  = {
    isToastShow,
    activeToast,
    closeToast,
    type,
    text,
    link,
    description,
  };

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export const useToastContext = () => {
  return useContext(ToastContext);
};
