// Dependencies
import React, {FC, useState} from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Table as MuiTable,
  TableHead as MuiTableHead, Box, IconButton,
} from '@mui/material';

// Component
import { Dialog } from '../../../../components/Dialog'

// Styles
import styles from '../../styles';
import tableStyles from '../../../ManageWidgets/components/Widget/components/Table/styles';
import {BorderHorizontal, BorderVertical} from '@mui/icons-material';

// Interfaces
interface IDataTableEditModalProps {
  title: string;
  open: boolean;
  data: { labels: string[]; values: string[][] };
  loading: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export DataTableEditModal
export const DataTableEditModal: FC<IDataTableEditModalProps> = ({
  title,
  open,
  data,
  loading,
  onClose,
  onSave,
}) => {
  const [tableData, setTableData] = useState<any>(data);

  const submitForm = () => {
    onSave({
      labels: tableData.labels,
      values: tableData.values,
    });
  };

  const handleChangeCell = (rowIndex, colIndex, e) => {
    setTableData({
      labels: tableData.labels,
      values: tableData.values.map((row, rIndex) => rIndex === rowIndex
        ? row.map((column, cIndex) =>
          cIndex === colIndex
            ? e.target.value
            : column
        )
        : row
      )
    })
  };

  const handleChangeLabel = (columnIndex, e) => {
    setTableData({
      labels: tableData.labels.map((cell, index) => index === columnIndex ? e.target.value : cell),
      values: tableData.values,
    })
  };

  const handleAddColumn = () => {
    setTableData({
      labels: [...tableData.labels, ""],
      values: tableData.values.map((row) => [...row, ""]),
    });
  };

  const handleAddRow = () => {
    setTableData({
      labels: tableData.labels,
      values: [...tableData.values, tableData.labels.map(() => "")],
    });
  };

  const handleRemoveColumn = (colIndex: number) => {
    setTableData({
      labels: tableData.labels.filter((_, index) => index !== colIndex),
      values: tableData.values.map((row) => row.filter((_, index) => index !== colIndex)),
    });
  };

  const handleRemoveRow = (rowIndex: number) => {
    setTableData({
      labels: tableData.labels,
      values: tableData.values.filter((_, index) => index !== rowIndex),
    });
  };

  // Return ArrayEdit Modal
  return (
    <Dialog open={open} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
      <DialogContent sx={{ maxWidth: `calc(100vw - 330px)` }}>
        <Box display="flex" gap={10}>
          <Button onClick={handleAddColumn}>Add Column</Button>
          <Button onClick={handleAddRow}>Add Row</Button>
        </Box>
        <MuiTable sx={{ mt: 10 }}>
          <MuiTableHead sx={tableStyles.tableHeader}>
            <MuiTableRow sx={tableStyles.tableRow}>
              {tableData.labels.map((label, index) => (
                <MuiTableCell sx={tableStyles.tableCell}>
                  <Box display="flex" alignItems="center" gap={8}>
                    <input
                      placeholder="Label"
                      value={label}
                      onChange={(e) => handleChangeLabel(index, e)}
                    />
                    <IconButton sx={{ height: 12, width: 12 }} onClick={() => handleRemoveColumn(index)}>
                      <BorderVertical />
                    </IconButton>
                  </Box>
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {tableData.values.map((row, rowIndex) => (
              <MuiTableRow sx={tableStyles.tableRow}>
                {row.map((value, columnIndex) => (
                  <MuiTableCell sx={tableStyles.tableCell}>
                    <Box display="flex" alignItems="center" gap={8}>
                      <input
                        placeholder="Text"
                        value={value}
                        onChange={(e) => handleChangeCell(rowIndex, columnIndex, e)}
                      />
                      {columnIndex === 0 && (
                        <IconButton sx={{ height: 12, width: 12 }} onClick={() => handleRemoveRow(rowIndex)}>
                          <BorderHorizontal />
                        </IconButton>
                      )}
                    </Box>
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            ))}
          </MuiTableBody>
        </MuiTable>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={submitForm}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>
    </Dialog>
  );
};
