// Resources
import { IStyles } from '../../../resource';

// Define styles
const styles: IStyles = {
  container: (theme) => ({
    p: theme.spacing(11),
    justifyContent: 'space-between',
    borderRadius: theme.spacing(24),
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
  }),
  color: (theme, params) => ({
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: params?.color,
    ':hover': {
      transform: 'scale(1.1)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 32,
      height: 32,
    },
  }),
};

// Export styles
export default styles;
