// Dependencies
import React, { FC, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Dialog as MuiDialog, TextField
} from '@mui/material';
import { Add, Close, Edit } from '@mui/icons-material';

// Component
import { Dialog } from '../../../../components/Dialog'

// Styles
import styles from '../../styles';

import apis from '../../../../apis';
import { API_SERVER } from '../../../../contants';

// Interfaces
interface logo { _id?: string ,name: string; path: any, link: string }

interface IArrayEditModalProps {
  title: string;
  open: boolean;
  data: logo[];
  loading: boolean;
  labelEditable?: boolean;
  onClose: () => void;
  onSave: (updatedData: object) => void;
}

// Export ArrayEdit Modal
export const LogosEditModal: FC<IArrayEditModalProps> = ({
  title,
  open,
  data,
  loading,
  labelEditable,
  onClose,
  onSave,
}) => {
  const fileSelector = useRef<any>();
  const [logos, setLogos] = useState<logo[]>(data || []);
  const [editModal, setEditModal] = useState(0);

  const handleOpenBrowser = () => {
    fileSelector.current.click();
  };

  const handleUploadLogoImage = async (e: any) => {
    // onChange(e.target.files[0]);
    const tempLogos = [...logos];
    tempLogos.push({ path: e.target.files[0], name: '', link: '' });
    setLogos(tempLogos);

    apis.pdf.uploadLogoImage(e.target.files[0])
      .then((res) => {
        setLogos([...tempLogos.filter((_, index) => index !== tempLogos.length - 1), { path: res.data, name: '', link: '' }]);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleSave = () => {
    onSave(logos);
  };

  const handleRemove = () => {
    setLogos(logos.filter((_, i) => i !== (editModal - 1)));
    setEditModal(0);
  };

  const handleOpenEdit = (index) => {
    setEditModal(index + 1);
  };

  const handleCloseEditModal = () => {
    setEditModal(0);
  };

  const handleUpdateLogo = () => {
    setEditModal(0);
  };

  const handleChangeLink = (e) => {
    setLogos(logos.map((logo, index) => index === (editModal - 1) ? { ...logo, link: e.target.value } : logo));
  };

  // Return Modal
  return (
    <>
      <Dialog open={open} onClose={onClose} sx={styles.dialog}>
        <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
        <DialogContent>
          <Grid pt={20} container columns={4} spacing={[10, 20]}>

            {
              logos.map(({ path }, index) => (
                <Grid item xs={1} key={index} sx={{ position: 'relative' }}>
                  <Avatar sx={{ width: 100, height: 100 }} src={typeof path === 'string' ? (path.includes('https') ? path :`${API_SERVER}/public/${path}`) : URL.createObjectURL(path)} />
                  <IconButton sx={styles.removeLogo} onClick={() => handleOpenEdit(index)}>
                    <Edit sx={{ width: 30, height: 30 }} />
                  </IconButton>
                </Grid>
              ))}

            <Grid item xs={1}>
              <input style={{ display: 'none' }} type="file" ref={fileSelector} onChange={handleUploadLogoImage} />
              <Button onClick={handleOpenBrowser} sx={{ width: 100, height: 100, borderRadius: 100, fontSize: 32 }} variant="outlined">
                <Add />
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
        </DialogActions>
      </Dialog>

      {!!editModal &&
        <MuiDialog open={!!editModal} onClose={handleCloseEditModal}>
          <DialogTitle sx={{ textTransform: 'uppercase' }}>{title.replace(/_/g, ' ')}</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mt: 16, width: 400 }}
              variant="outlined"
              fullWidth
              label="Link"
              value={logos[editModal - 1]?.link}
              onChange={handleChangeLink}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRemove}>Remove</Button>
            <Button onClick={handleUpdateLogo}>Okay</Button>
          </DialogActions>
        </MuiDialog>
      }
    </>
  );
};
