// Interfaces
import { IStyles } from '../../../resource';

import shadows from '../../../theme/shadows';

// Define styles
const styles: IStyles = {
  header: (theme) => ({
    p: { xs: theme.spacing(20, 20), sm: theme.spacing(20, 40), md: theme.spacing(32, 80), lg: theme.spacing(32, 140) },
    boxShadow: theme.shadows[1],
    position: 'relative',
    zIndex: 1,
    height: { xs: 118, md: 46 },
    justifyContent: 'space-between',
  }),
  title: (theme, params) => ({
    flex: 1,
    input: {
      fontWeight: 900,
      fontSize: '32px',
      padding: `8px ${params?.active ? '20px' : '0'}`,
      border: `2px solid ${params?.active ? theme.palette.secondary.main : 'transparent'}`,
      borderRadius: params?.active ? '50px' : 0,
      background: 'transparent',
      outline: 'none',
      cursor: params?.active ? 'text' : 'pointer',
      width: '100%',
      boxSizing: 'border-box',
    },

    '&:hover': {
      input: {
        background: params?.active ? 'transparent' : 'lightgrey',
      }
    }
  }),
  logo: () => ({
    img: {
      width: 48,
      height: 48,
    },
  }),
  newButton: (theme) => ({
    position: 'relative',
    ml: { xs: theme.spacing(0), md: theme.spacing(-58), lg: theme.spacing(-92) },
    mr: { xs: theme.spacing(20), md: theme.spacing(12), lg: theme.spacing(46) },
    ':hover': {
      '.MuiButton-root': {
        visibility: 'visible',
        width: { xs: 90, md: 126 },
      },
    },
    '.MuiButton-root': {
      visibility: 'hidden',
      position: 'absolute',
      left: 0,
      width: 46,
      minWidth: 46,
      transitionProperty: 'width',
      transitionDuration: '300ms',
      p: theme.spacing(0, 0, 0, 30),
      overflow: 'hidden',
    },
  }),
  searchInput: (theme) => ({
    height: { xs: 36, md: 46 },
    width: { xs: 70, md: 90 },
    borderRadius: { xs: 18, md: 23 },
    pl: { xs: theme.spacing(22), md: theme.spacing(32) },
    boxShadow: shadows[1],
    transitionProperty: 'width',
    transitionDuration: '300ms',
    ':hover': {
      width: { xs: 'auto', md: 420 },
      '.MuiOutlinedInput-input': {
        display: 'block',
      },
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    },
    '.MuiOutlinedInput-input': {
      flex: 1,
      width: 'unset',
      display: 'none',
      color: theme.palette.secondary.main,
    },
    '.MuiInputAdornment-root': {
      mr: { xs: theme.spacing(8), md: theme.spacing(32) },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
  }),
  toggleButton: () => ({
    width: { xs: 70, md: 90 },
    svg: {
      height: { xs: 20, md: 28 },
    },
  }),
  drawer: () => ({
    // top: 160,
    '.MuiBackdrop-root': {
      // top: 160,
    },
    '.MuiPaper-root': {
      // top: 160,
      boxShadow: 'none',
    },
  }),
};

// Export styles
export default styles;
