// Dependencies
import { FC, ReactNode } from 'react';
import { Box, IconButton, Modal, Theme } from '@mui/material';

// Icons
import { CloseIcon } from '../../assets/icons';

// Resources
import { ISxProps } from '../../resource';

// Styles
import styles from './styles';

// Interfaces
interface IDialogProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  sx: ISxProps<Theme>;
}

// Export dialog
export const Dialog: FC<IDialogProps> = ({ open, onClose, children, sx }) => {
  // Return dialog
  return (
    <Modal open={open} onClose={onClose} sx={styles.dialog}>
      <Box sx={styles.container}>
        <Box sx={sx}>
          <IconButton size="small" sx={styles.close} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
