// Dependencies
import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Stack, Typography, OutlinedInput, IconButton } from '@mui/material';
import { Add, Check, Close, Remove } from '@mui/icons-material';

import styles from './styles';
import apis from '../../apis';
import { AppContext } from '../../context';
import { useToastContext } from '../../components/Toast/toastContext';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { IAccount } from '../../resource';
import { UserSelector } from '../../components/UserSelector';
import { PlusIcon } from '../../assets/icons';

type IPortfolio = {
  label: string;
  value: number;
}

// Export template panel
export const Portfolio: FC = () => {
  const { isAdmin, account } = useContext(AppContext);
  const [data, setData] = useState<IPortfolio[]>([]);
  const [date, setDate] = useState('');
  const [originalDate, setOriginalDate] = useState('');
  const [dateActive, setDateActive] = useState(false);
  const [customer, setCustomer] = useState<IAccount | null>(null);

  const { type } = useParams<{type: string}>();

  // Get toast from hook
  const { activeToast } = useToastContext();

  // Change data handler
  const handleChangeData = (e, index) => {
    setData(data.map((p, i) => index === i ? { ...p, [e.target.name]: e.target.value } : p));
  };

  // Add data handler
  const handleAddData = () => {
    setData([...data, { label: '', value: 0 }]);
  };

  // Update practice handler
  const handleUpdateData = () => {
    if (customer && type) {
      apis.portfolio.updatePortfolio(customer?.id, type, { [type]: data, [`${type}Date`]: date })
        .then((res) => {
          activeToast({
            type: 'success',
            text: res.msg,
          });
          setDateActive(false);
        })
        .catch((err) => {
          activeToast({
            type: 'error',
            text: err.msg,
          });
        })
    }
  };

  // Edit date handler
  const handleActiveDate = () => {
    setDateActive(true);
  };

  // Cancel update date
  const handleCancelUpdateDate = () => {
    setDateActive(false);
    setDate(originalDate ? moment(originalDate).format('YYYY-MM-DD') : '');
  };

  // Change date handler
  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  // Change remove item
  const handleRemoveLine = (index: number) => {
    setData(data.slice(0, index).concat(data.slice(index + 1)));
  };

  const handleChangeCustomer = (value: IAccount | null) => {
    setCustomer(value);
  };

  useEffect(() => {
    if (customer && type) {
      apis.portfolio.getPortfolio(customer.id, type)
        .then((res) => {
          if (res.data[type]) {
            setData(res.data[type]);
          }
          setDate(res.data[`${type}Date`] ? moment(res.data[`${type}Date`]).format('YYYY-MM-DD') : new Date().toString());
          setOriginalDate(res.data[`${type}Date`]);
        })
        .catch(err => {
          setData([]);
          setDate('');
          activeToast({
            type: 'error',
            text: err.msg,
          });
        })
    }
  }, [customer, type]);

  useEffect(() => {
    if (!isAdmin && account) {
      setCustomer(account);
    }
  }, [isAdmin, account]);

  // Return template panel
  return (
    <Stack>
      {isAdmin && (
        <Box display="flex" alignItems="center" mb={16}>
          <UserSelector value={customer} onChange={handleChangeCustomer} />
        </Box>
      )}
      {customer ? (
        <>
          <Box>
            <Grid container spacing={30}>
              {data && data.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid key={index} item xs={12} sm={5.5}>
                    <Typography sx={{ pl: 6, pb: 6 }}>Label:</Typography>
                    <OutlinedInput name="label" type="string" fullWidth value={item.label} onChange={(e) => handleChangeData(e, index)} />
                  </Grid>
                  <Grid key={index} item xs={12} sm={5.5}>
                    <Typography sx={{ pl: 6, pb: 6 }}>Value:</Typography>
                    <OutlinedInput name="value" type="number" fullWidth value={item.value} onChange={(e) => handleChangeData(e, index)} />
                  </Grid>
                  <Grid key={index} item xs={12} sm={1} display="flex">
                    <IconButton size="medium" sx={{ mt: 'auto' }} onClick={() => handleRemoveLine(index)}>
                      <Remove />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item container xs={12} sm={6} md={4} spacing={10}>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth startIcon={<Add />} onClick={handleAddData}>
                    Add
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button color="success" fullWidth startIcon={<Check />} onClick={handleUpdateData}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box mt={24}>
            {!dateActive ? (
              <Typography variant="body1" sx={styles.areaDate} onClick={handleActiveDate}>
                Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: <b>{originalDate ? moment(originalDate).format('DD.MM.YYYY') : 'None'}</b>
              </Typography>
            ) : (
              <Box>
                <OutlinedInput type="date" fullWidth value={date} onChange={handleChangeDate} />
                <Stack direction="row" justifyContent="flex-end" mt={8} display={dateActive ? "flex" : "none"}>
                  <IconButton sx={{ mr: 8 }} size="small" onClick={handleUpdateData}>
                    <Check />
                  </IconButton>
                  <IconButton size="small" onClick={handleCancelUpdateDate}>
                    <Close />
                  </IconButton>
                </Stack>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          {isAdmin && <Typography variant="h2" align="center" sx={styles.placeholder}>Please select customer</Typography>}
        </>
      )}
    </Stack>
  );
};
