import { FC, useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import moment from 'moment';

import { client } from '../../../../App';
import { GET_PORTRAIT } from '../../../../queries/portrait';
import { commafy } from '../../../../utils/commafy';

// Mock data
const data1 = [
  { a: 'Kurs per', b: '--.--.---', key: 'datum', type: 'date' },
  { a: 'Währung', b: 'XXX', key: 'waehrung' },
  { a: 'Ausgabe- / Rücknahmepreis', b: 'XXX', key: 'preis', type: 'float' },
  { a: 'Fondsvolumen', b: 'XXX', key: 'fondsvermoegen', type: 'integer' },
  { a: 'Ausschüttung', b: '-' },
  { a: 'Laufende Kosten', b: 'max. 0,52% p.a.' },
  { a: 'ISIN', b: 'XXX', key: 'isin' },
  { a: 'WKN', b: 'FRA3TF' },
  { a: 'Bloomberg', b: 'FRUMVEI' },
  { a: 'Reuters', b: '' },
  { a: 'Fondsart', b: 'UCITS' },
  { a: 'Nachhaltigkeit', b: 'Artikel 8 Fonds nach EU-Offenlegungsverordnung' },
  { a: 'Anlageschwerpunkt', b: 'Global' },
  { a: 'Auflagedatum', b: '30.06.2022' },
  { a: 'Anlageberater', b: 'Shareholder Value Management AG' },
  { a: 'Verwaltungsgesellschaft', b: 'Axxion S.A.' },
  { a: 'Verwahrstelle', b: 'Hauck Aufhäuser Lampe Privatbank AG' },
];

export const PortraitPanel: FC = () => {
  const [portraitData, setPortraitData] = useState(data1);
  const [date, setDate] = useState('');

  const fetchData = async () =>
    client.query({
      query: GET_PORTRAIT,
    });

  useEffect(() => {
    fetchData().then((result) => {
      setPortraitData(
        data1.map((field) => {
          if (field.key && result.data.getPortrait[field.key]) {
            const fieldValue = result.data.getPortrait[field.key];
            let value;
            if (field.type === 'date') {
              value = moment(new Date(+fieldValue).toString()).format('DD.MM.YYYY');
            } else if (field.type === 'float') {
              value = `${fieldValue.replaceAll('.', ',')} ${result.data.getPortrait.waehrung}`;
            } else if (field.type === 'integer') {
              value = `${commafy(Math.floor(fieldValue))} ${result.data.getPortrait.waehrung}`;
            } else {
              value = fieldValue;
            }
            return { ...field, b: value };
          } else {
            return field;
          }
        })
      );
      setDate(moment(new Date(+result.data.getPortrait.datum).toString()).format('DD.MM.YYYY'));
    });
  }, []);
  return (
    <>
      <List className="scroll-mobile current_positions_list">
        {portraitData.map(({ a, b }, index) => (
          <ListItem key={index}>
            <ListItemText className="title">{a}</ListItemText>
            <ListItemText className="title">{b}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Typography mt={5}>Stand der Daten: {date}</Typography>
      {/* <Grid container direction={{ xs: 'column-reverse', md: 'row' }} spacing={20} columns={3} m={2} mt={0}> */}
      {/*<Grid item xs={3} md={2}>*/}

      {/* <Table>
          <TableBody>
            {data1.map(({ a, b }, index) => (
              <TableRow key={index}>
                <TableCell width="35%">{a}</TableCell>
                <TableCell>{b}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> */}

      {/*</Grid>*/}
      {/*<Grid item xs={3} md={1}>*/}
      {/*  <List sx={{ padding: 50, backgroundColor: '#f8ea46' }}>*/}
      {/*    {data2.map(({ a, b }, index) => (*/}
      {/*      <ListItem key={index}>*/}
      {/*        <ListItemText>{a}</ListItemText>*/}
      {/*        <ListItemText sx={{ textAlign: 'right' }}>{b}</ListItemText>*/}
      {/*      </ListItem>*/}
      {/*    ))}*/}
      {/*  </List>*/}
      {/*  <Typography mt={5} mb={15} fontSize={12} textAlign="right">*/}
      {/*    Stand der Daten: 21.06.2022*/}
      {/*  </Typography>*/}
      {/*  <Table>*/}
      {/*    <TableBody>*/}
      {/*      {data3.map((value, index) => (*/}
      {/*        <TableRow key={index}>*/}
      {/*          <TableCell>{value}</TableCell>*/}
      {/*        </TableRow>*/}
      {/*      ))}*/}
      {/*    </TableBody>*/}
      {/*  </Table>*/}
      {/*</Grid>*/}
      {/* </Grid> */}
    </>
  );
};
