// Resources
import { IStyles } from '../../../../../../resource';

// Define styles
const styles: IStyles = {
  textRow: (theme, preview) => ({
    display: 'flex',
    alignItems: 'center',
    borderBottom: `${preview ? '1' : '0'}px solid #dddddd`,
    padding: preview ? '10px 0' : 0,
    whiteSpace: 'nowrap',
    gap: '12px',
    pr: '32px',
    '& input': {
      p: '16px 10px',
      fontSize: '14px',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 1,
      '&:focus': {
        outline: `1px solid ${theme.palette.secondary.main}`,
      },
    }
  }),
  configField: (theme, { type }) => ({
    color: theme.palette.common.white,
    width: 'fit-content',
    backgroundColor: type === 'text' ? theme.palette.grey['200'] : theme.palette.success['light'],
    padding: theme.spacing(4, 8),
    borderRadius: theme.spacing(8),
    cursor: 'pointer',
    border: '1px solid transparent',
  }),
  dataConfigInputWrapper: (theme) => ({
    flex: 1,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '32px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    width: '600px',
    padding: '0 8px',
    gap: theme.spacing(4),
    overflow: 'auto',
    '::-webkit-scrollbar': {
      width: '2px',
      height: '2px',
    },

    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#e5e5e5',
      borderRadius: '6px',
    },

    '::-webkit-scrollbar-button': {
      display: 'none',
    },
  }),
};

// Export styles
export default styles;
