// Create styles
const styles = {
  dialog: (theme) => ({
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    p: theme.spacing(60, 20, 20, 40),
    maxWidth: { xs: 560, md: 600 },
    width: 1,
    borderRadius: theme.spacing(30, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }),
  action: (theme) => ({
    width: '100%',
    display: 'flex',
    justifyContent:'flex-end',
    gap: '8px',
    marginTop: '16px'
  })
};

// Export styles
export default styles;
