// Interfaces
import { IStyles } from '../../../resource';

const styles: IStyles = {
  header: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    p: { xs: theme.spacing(12, 20), sm: theme.spacing(12, 80), md: theme.spacing(12, 140) },
    backgroundColor: theme.palette.gray.light,
    borderBottomRightRadius: 30,
    boxShadow: theme.shadows[1],
    zIndex: 1350,
  }),
  brand: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }),
  logo: (theme) => ({
    width: 48,
    height: 48,
    mr: theme.spacing(16),
  }),
  button: (theme) => ({
    borderRadius: theme.spacing(10, 0),
  }),
  dialog: (theme) => ({
    '.MuiPaper-root': {
      width: { xs: 'calc(100% - 20px)', sm: 'calc(100% - 64px)' },
      margin: { xs: theme.spacing(32, 10, 20), sm: theme.spacing(32) },
      borderBottomRightRadius: 50,
      position: 'absolute',
      top: 0,
      height: 684,
    },
  }),
  dialogContent: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    px: theme.spacing(20),
  }),
  form: () => ({
    width: '100%',
    maxWidth: 420,
    height: 400,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }),
  close: () => ({
    position: 'absolute',
    bottom: 36,
    right: 36,
  }),
};

// Export styles
export default styles;
