// Dependencies
import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, Stack, Typography, OutlinedInput, IconButton } from '@mui/material';
import { Add, Check, Close } from '@mui/icons-material';

import styles from './styles';
import apis from '../../../apis';
import { AppContext } from '../../../context';
import { useToastContext } from '../../../components/Toast/toastContext';
import moment from 'moment';
import { IAccount } from '../../../resource';
import { UserSelector } from '../../../components/UserSelector';

const DEFAULT_PRACTICES = [0, 0, 0, 0, 0];

// Export template panel
export const EsgPractices: FC = () => {
  const { isAdmin, account } = useContext(AppContext);
  const [practices, setPractices] = useState<number[]>(DEFAULT_PRACTICES);
  const [practiceDate, setPracticeDate] = useState('');
  const [originalDate, setOriginalDate] = useState('');
  const [dateActive, setDateActive] = useState(false);
  const [customer, setCustomer] = useState<IAccount | null>(null);

  // Get toast from hook
  const { activeToast } = useToastContext();

  // Change practices handler
  const handleChangePractices = (e, index) => {
    setPractices(practices.map((p, i) => index === i ? e.target.value : p));
  };

  // Add practice handler
  const handleAddPractice = () => {
    setPractices([...practices, 0]);
  };

  // Update practice handler
  const handleUpdatePractices = () => {
    if (customer) {
      apis.esg.updateEsgPractices(customer?.id, { practices, practiceDate })
        .then((res) => {
          activeToast({
            type: 'success',
            text: res.msg,
          });
        })
        .catch((err) => {
          activeToast({
            type: 'error',
            text: err.msg,
          });
        })
    }
  };

  // Edit date handler
  const handleActiveDate = () => {
    setDateActive(true);
  };

  // Cancel update date
  const handleCancelUpdateDate = () => {
    setDateActive(false);
    setPracticeDate(originalDate ? moment(originalDate).format('YYYY-MM-DD') : '');
  };

  // Change date handler
  const handleChangeDate = (e) => {
    setPracticeDate(e.target.value);
  };

  useEffect(() => {
    if (customer) {
      apis.esg.getEsgData(customer.id)
        .then((res) => {
          if (res.practices && res.practices.length) {
            setPractices(res.practices);
          } else {
            setPractices(DEFAULT_PRACTICES);
          }
          setPracticeDate(res.practiceDate ? moment(res.practiceDate).format('YYYY-MM-DD') : new Date().toString());
          setOriginalDate(res.practiceDate);
        })
        .catch(err => {
          activeToast({
            type: 'error',
            text: err.msg,
          });
        })
    }
  }, [customer]);

  useEffect(() => {
    if (!isAdmin && account) {
      setCustomer(account);
    }
  }, [isAdmin, account]);

  // Return template panel
  return (
    <Stack>
      <Box display="flex" mb={24}>
        {isAdmin && <UserSelector value={customer} onChange={setCustomer} />}
      </Box>

      {customer ? (
        <>
          <Box>
            <Grid container spacing={20}>
              {practices && practices.map((practice, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <OutlinedInput type="number" fullWidth value={practice} onChange={(e) => handleChangePractices(e, index)} />
                </Grid>
              ))}
              <Grid item container xs={12} sm={6} md={4} spacing={10}>
                <Grid item xs={12} sm={6}>
                  <Button fullWidth startIcon={<Add />} onClick={handleAddPractice}>
                    Add
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button color="success" fullWidth startIcon={<Check />} onClick={handleUpdatePractices}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box mt={24}>
            {!dateActive ? (
              <Typography variant="body1" sx={styles.areaDate} onClick={handleActiveDate}>
                Quelle: Eigene Berechnungen Shareholder Value Management AG. Stand der Daten: <b>{originalDate ? moment(originalDate).format('DD.MM.YYYY') : 'None'}</b>
              </Typography>
            ) : (
              <Box>
                <OutlinedInput type="date" fullWidth value={practiceDate} onChange={handleChangeDate} />
                <Stack direction="row" justifyContent="flex-end" mt={8} display={dateActive ? "flex" : "none"}>
                  <IconButton sx={{ mr: 8 }} size="small" onClick={handleUpdatePractices}>
                    <Check />
                  </IconButton>
                  <IconButton size="small" onClick={handleCancelUpdateDate}>
                    <Close />
                  </IconButton>
                </Stack>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          {isAdmin && <Typography variant="h2" align="center" sx={styles.placeholder}>Please select customer</Typography>}
        </>
      )}
    </Stack>
  );
};
