// Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Export access page
export const AccessPage: FC = () => {
  // Return access page
  return (
    <Typography variant="display" align="center">
      Not completed yet!
    </Typography>
  );
};
