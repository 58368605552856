// Define palette
const palette = {
  primary: {
    main: '#003C50',
  },
  secondary: {
    main: '#BF0A76',
    light: '#ECB6D6',
  },
  info: {
    main: '#67378D',
  },
  success: {
    main: '#02C58D',
    light: '#E5F9F3',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  gray: {
    main: '#707070',
    light: '#E5EBED',
    dark: '#808080',
  },
};

// Export palette
export default palette;
