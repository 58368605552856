// Dependencies
import { FC } from 'react';
import { Box, Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

// Styles
import styles from './styles';

// Validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required Field!'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Confirm password does not matched!')
    .required('Required Field!'),
});

// Export forgot password page
export const ForgotPasswordPage: FC = () => {
  // Submit handler
  const handleSubmit = (values: any) => {
    console.log(values);
  };

  // Return forgot password page
  return (
    <Box sx={styles.container}>
      <Stack spacing={18} sx={styles.content}>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
            <Box component={Form} onSubmit={handleSubmit}>
              <Stack spacing={34}>
                <TextField
                  fullWidth
                  name="password"
                  value={values.password}
                  error={Boolean(errors.password && touched.password)}
                  helperText={Boolean(errors.password && touched.password) && errors.password}
                  placeholder="Choose a Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icons/lock.svg" alt="lock" />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  name="confirmPassword"
                  value={values.confirmPassword}
                  error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                  helperText={Boolean(errors.confirmPassword && touched.confirmPassword) && errors.confirmPassword}
                  placeholder="Repeat Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/icons/lock.svg" alt="lock" />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Stack>
              <Stack mt={48} spacing={16}>
                <Button fullWidth type="submit">
                  Submit
                </Button>
              </Stack>
            </Box>
          )}
        </Formik>
      </Stack>
    </Box>
  );
};
