// Todo: should this come from db?
export const CixonTables = [
  {
    name: 'cixon_chart',
    value: 'cixon_chart',
    fields: [
      'fondnr',
      'isin',
      'datum',
      'waehrung',
      'preis'
    ],
  },
  {
    name: 'cixon_stammdaten_extension',
    value: 'cixon_stammdaten_extension',
    fields: [
      'fondnr',
      'isin',
      'datum',
      'fondsvermoegen',
      'askvermoegen',
      'ausgabepreis',
      'ruecknahmepreis',
    ],
  },
  {
    name: 'cixon_wertentwicklung_vergangen',
    value: 'cixon_wertentwicklung_vergangen',
    fields: [
      'datum',
      'm1',
      'j1',
      'j3',
      'j5',
      'seitauflage',
      'geschaeftsjahr',
      'laufendesjahr',
    ],
  },
  {
    name: 'cixon_wertentwicklung_rollierend',
    value: 'cixon_wertentwicklung_rollierend',
     fields: [
      'datum',
      'k1',
      'k2',
      'k3',
      'k4',
      'k5',
    ],
  },
  {
    name: 'cixon_volatilitaet',
    value: 'cixon_volatilitaet',
    fields: [
      'fondnr',
      'isin',
      'datum',
      'j1',
      'j3',
      'j5',
      'auflage',
      'gesch_jahr',
    ],
  },
  {
    name: 'cixon_portfolio',
    value: 'cixon_portfolio',
    fields: [
      'isin',
      'anteliamfv',
      'emittent',
    ],
  },
];
